import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import DashBoard from "./dashboard.js";
import InventoryItems from "./inventoryItems.js";
import RequestForm from "./RequestForm";
import AllRequest from "./AllRequest";
import AllStores from "./AllStores.js";
import AllInventoryEmployees from "./AllInventoryEmployees.js";
import AddStore from "./AddStore.js";
import AddInventoryEmployees from "./AddInventoryEmployees.js";
import ViewStore from "./ViewStore.js";
import EditStore from "./EditStore.js";
import ViewInventoryEmployee from "./ViewInventoryEmployee.js";
import EditInventoryEmployee from "./EditInventoryEmployee.js";
import AddVendor from "./Vendors/AddVendor.js";
import AllVendors from "./Vendors/AllVendors.js";
import EditVendor from "./Vendors/EditVendor.js";
import ViewVendor from "./Vendors/ViewVendor.js";
import AllPurchasedOrder from "./AllPurchasedOrders/AllPurchasedOrder.js";
import AllItems from "./Items/AllItems.js";
import AddNewItem from "./Items/AddNewItem.js";
import ViewItem from "./Items/ViewItem.js";
import EditItem from "./Items/EditItem.js";
import AllUnitsList from "./Units/AllUnitsList.js";
import AddNewUnit from "./Units/AddNewUnit.js";
import EditUnit from "./Units/EditUnit.js";
import ViewUnit from "./Units/ViewUnit.js";
import EditPurchaseOrder from "./AllPurchasedOrders/EditPurchaseOrder.js";
import AddCafeEmployee from "./CafeEmployees/AddCafeEmployee.js";
import AllCafeEmployeesList from "./CafeEmployees/AllCafeEmployeesList.js";
import EditCafeEmployee from "./CafeEmployees/EditCafeEmployee.js";
import ViewCafeEmployee from "./CafeEmployees/ViewCafeEmployee.js";
import CafeStockList from "./CafeStock/CafeStockList.js";
import StoreStockList from "./InventoryStock/StoreStockList.js";
import SoldProductReport from "./SoldProductReport/SoldProductReport.js";
import AllCafeRequests from "./CafeRequests/AllCafeRequests.js";
import ViewCafeRequest from "./CafeRequests/ViewCafeRequest.js";
import StoreSentRequest from "./StoreSentRequest/StoreSentRequest.js";
import StoreReceivedRequest from "./StoreReceivedReq/StoreReceivedRequest.js";
import ViewStoreSenderReq from "./StoreSentRequest/ViewStoreSenderReq.js";
import ViewStoreReceiverReq from "./StoreReceivedReq/ViewStoreReceiverReq.js";

const Inventory = (props) => {
  const login = useSelector((state) => state.login);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      admin_permissions.forEach((item) => {
        if (item.module === "Inventory") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };
  useEffect(() => {
    editPermissions();
  }, []);
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <DashBoard
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allstores"
          element={
            <AllStores
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/addstore"
          element={
            <AddStore
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allstores/viewStore/:store_id"
          element={
            <ViewStore
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allstores/editStore/:store_id"
          element={
            <EditStore
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/inventoryemployees"
          element={
            <AllInventoryEmployees
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/addemployees"
          element={
            <AddInventoryEmployees
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/inventoryemployees/viewEmployee/:inventory_login_id"
          element={
            <ViewInventoryEmployee
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/inventoryemployees/editEmployee/:inventory_login_id"
          element={
            <EditInventoryEmployee
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allvendor"
          element={
            <AllVendors
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/addvendor"
          element={
            <AddVendor
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allvendor/editvendor/:vendor_lists_id"
          element={
            <EditVendor
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allvendor/viewvendor/:vendor_lists_id"
          element={
            <ViewVendor
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allpurchasedorders"
          element={
            <AllPurchasedOrder
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allpurchasedorders/edit-purchased-order/:purchase_orders_id"
          element={
            <EditPurchaseOrder
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allitems"
          element={
            <AllItems
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/additems"
          element={
            <AddNewItem
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allitems/viewitems/:inventory_items_id"
          element={
            <ViewItem
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allitems/edititems/:inventory_items_id"
          element={
            <EditItem
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/items"
          element={
            <InventoryItems
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allunits"
          element={
            <AllUnitsList
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/addunits"
          element={
            <AddNewUnit
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allunits/edit-units/:name"
          element={
            <EditUnit
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allunits/view-units/:name"
          element={
            <ViewUnit
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/cafe_employees"
          element={
            <AllCafeEmployeesList
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/addCafeEmployees"
          element={
            <AddCafeEmployee
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/cafe_employees/editEmployee/:cafe_inventory_login_id"
          element={
            <EditCafeEmployee
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/cafe_employees/viewEmployee/:cafe_inventory_login_id"
          element={
            <ViewCafeEmployee
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/cafe_stock"
          element={
            <CafeStockList
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/store_stock"
          element={
            <StoreStockList
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/all_cafe_requests"
          element={
            <AllCafeRequests
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/all_cafe_requests/view-cafe-request/:cafe_request_id"
          element={
            <ViewCafeRequest
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/soldproductreport"
          element={
            <SoldProductReport
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/store_sent_request"
          element={
            <StoreSentRequest
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/store_received_request"
          element={
            <StoreReceivedRequest
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/store_sent_request/view_store_request/:store_request_id"
          element={
            <ViewStoreSenderReq
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/store_received_request/view_store_request/:store_request_id"
          element={
            <ViewStoreReceiverReq
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/request"
          element={
            <RequestForm
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
        <Route
          path="/allRequest"
          element={
            <AllRequest
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default Inventory;
