import React, { useState } from "react";
import Select from "react-select";

const PincodeSelector = ({ selectedPincode, setSelectedPincode ,disableSelection}) => {
  // Options for pincode selection
  const allPincodes = [
    { value: "Agra", label: "282001" },
    { value: "Ahmedabad", label: "380001" },
    { value: "Ajmer", label: "305001" },
    { value: "Akola", label: "444001" },
    { value: "Aligarh", label: "202001" },
    { value: "Allahabad", label: "211001" },
    { value: "Alwar", label: "301001" },
    { value: "Ambala", label: "133001" },
    { value: "Amravati", label: "444601" },
    { value: "Amritsar", label: "143001" },
    { value: "Anantapur", label: "515001" },
    { value: "Arrah", label: "802301" },
    { value: "Asansol", label: "713301" },
    { value: "Aurangabad", label: "431001" },
    { value: "Avadi", label: "600054" },
    { value: "Bagaha", label: "845101" },
    { value: "Bahraich", label: "271801" },
    { value: "Bally", label: "711201" },
    { value: "Bangalore", label: "560001" },
    { value: "Baranagar", label: "700036" },
    { value: "Barasat", label: "700124" },
    { value: "Bardhaman", label: "713101" },
    { value: "Bareilly", label: "243001" },
    { value: "Bathinda", label: "151001" },
    { value: "Begusarai", label: "851101" },
    { value: "Belgaum", label: "590001" },
    { value: "Bellary", label: "583101" },
    { value: "Berhampore", label: "742101" },
    { value: "Bettiah", label: "845438" },
    { value: "Bhagalpur", label: "812001" },
    { value: "Bharatpur", label: "321001" },
    { value: "Bhatpara", label: "700124" },
    { value: "Bhavnagar", label: "364001" },
    { value: "Bhilai", label: "490001" },
    { value: "Bhilwara", label: "311001" },
    { value: "Bhind", label: "477001" },
    { value: "Bhiwandi", label: "421302" },
    { value: "Bhiwani", label: "127021" },
    { value: "Bhopal", label: "462001" },
    { value: "Bhubaneswar", label: "751001" },
    { value: "Bhusawal", label: "425201" },
    { value: "Bidar", label: "585401" },
    { value: "Bihar Sharif", label: "803101" },
    { value: "Bijapur", label: "586101" },
    { value: "Bikaner", label: "334001" },
    { value: "Bilaspur", label: "495001" },
    { value: "Bokaro", label: "827001" },
    { value: "Brahmapur", label: "760001" },
    { value: "Bulandshahr", label: "203001" },
    { value: "Burhanpur", label: "450331" },
    { value: "Buxar", label: "802101" },
    { value: "Cachar", label: "788001" },
    { value: "Chandigarh", label: "160017" },
    { value: "Chandrapur", label: "442401" },
    { value: "Chapra", label: "841301" },
    { value: "Chennai", label: "600001" },
    { value: "Chinsurah", label: "712101" },
    { value: "Chittoor", label: "517001" },
    { value: "Coimbatore", label: "641001" },
    { value: "Cuddalore", label: "607001" },
    { value: "Cuttack", label: "753001" },
    { value: "Danapur", label: "801503" },
    { value: "Darbhanga", label: "846004" },
    { value: "Davanagere", label: "577001" },
    { value: "Dehradun", label: "248001" },
    { value: "Deoghar", label: "814112" },
    { value: "Dewas", label: "455001" },
    { value: "Dhanbad", label: "826001" },
    { value: "Dhar", label: "454001" },
    { value: "Dhule", label: "424001" },
    { value: "Dibrugarh", label: "786001" },
    { value: "Dimapur", label: "797112" },
    { value: "Dindigul", label: "624001" },
    { value: "Dispur", label: "781006" },
    { value: "Dum Dum", label: "700074" },
    { value: "Durg", label: "491001" },
    { value: "Durgapur", label: "713204" },
    { value: "Eluru", label: "534001" },
    { value: "English Bazar", label: "733101" },
    { value: "Erode", label: "638001" },
    { value: "Etawah", label: "206001" },
    { value: "Faizabad", label: "224001" },
    { value: "Faridabad", label: "121001" },
    { value: "Farrukhabad", label: "209625" },
    { value: "Fatehpur", label: "212601" },
    { value: "Firozabad", label: "283203" },
    { value: "Gandhidham", label: "370201" },
    { value: "Gandhinagar", label: "382010" },
    { value: "Gaya", label: "823001" },
    { value: "Ghaziabad", label: "201001" },
    { value: "Giridih", label: "815301" },
    { value: "Gorakhpur", label: "273001" },
    { value: "Gudivada", label: "521301" },
    { value: "Gulbarga", label: "585101" },
    { value: "Guna", label: "473001" },
    { value: "Guntakal", label: "515801" },
    { value: "Guntur", label: "522001" },
    { value: "Gurgaon", label: "122001" },
    { value: "Guwahati", label: "781001" },
    { value: "Gwalior", label: "474001" },
    { value: "Hajipur", label: "844101" },
    { value: "Haldia", label: "721602" },
    { value: "Haldwani", label: "263139" },
    { value: "Hapur", label: "245101" },
    { value: "Hardoi", label: "241001" },
    { value: "Haridwar", label: "249401" },
    { value: "Hazaribagh", label: "825301" },
    { value: "Hindupur", label: "515201" },
    { value: "Hisar", label: "125001" },
    { value: "Hospet", label: "583201" },
    { value: "Howrah", label: "711101" },
    { value: "Hubli-Dharwad", label: "580020" },
    { value: "Hugli-Chinsurah", label: "712101" },
    { value: "Hyderabad", label: "500001" },
    { value: "Ichalkaranji", label: "416115" },
    { value: "Imphal", label: "795001" },
    { value: "Indore", label: "452001" },
    { value: "Jabalpur", label: "482001" },
    { value: "Jaipur", label: "302001" },
    { value: "Jalandhar", label: "144001" },
    { value: "Jalgaon", label: "425001" },
    { value: "Jalna", label: "431203" },
    { value: "Jamalpur", label: "811214" },
    { value: "Jammu", label: "180001" },
    { value: "Jamnagar", label: "361001" },
    { value: "Jamshedpur", label: "831001" },
    { value: "Jaunpur", label: "222001" },
    { value: "Jehanabad", label: "804408" },
    { value: "Jhansi", label: "284001" },
    { value: "Jodhpur", label: "342001" },
    { value: "Junagadh", label: "362001" },
    { value: "Kadapa", label: "516001" },
    { value: "Kakinada", label: "533001" },
    { value: "Kalyan-Dombivali", label: "421301" },
    { value: "Kamarhati", label: "700058" },
    { value: "Kanpur", label: "208001" },
    { value: "Karimnagar", label: "505001" },
    { value: "Karnal", label: "132001" },
    { value: "Katihar", label: "854105" },
    { value: "Khammam", label: "507001" },
    { value: "Khandwa", label: "450001" },
    { value: "Kharagpur", label: "721301" },
    { value: "Khora", label: "201309" },
    { value: "Kirari Suleman Nagar", label: "110086" },
    { value: "Kishanganj", label: "855107" },
    { value: "Kochi", label: "682001" },
    { value: "Kolhapur", label: "416001" },
    { value: "Kolkata", label: "700001" },
    { value: "Kollam", label: "691001" },
    { value: "Korba", label: "495677" },
    { value: "Kota", label: "324001" },
    { value: "Kottayam", label: "686001" },
    { value: "Kozhikode", label: "673001" },
    { value: "Kulti", label: "713343" },
    { value: "Kumbakonam", label: "612001" },
    { value: "Kurnool", label: "518001" },
    { value: "Lakhimpur", label: "262701" },
    { value: "Latur", label: "413512" },
    { value: "Loni", label: "201102" },
    { value: "Lucknow", label: "226001" },
    { value: "Ludhiana", label: "141001" },
    { value: "Machilipatnam", label: "521001" },
    { value: "Madanapalle", label: "517325" },
    { value: "Madhyamgram", label: "700129" },
    { value: "Madurai", label: "625001" },
    { value: "Mahbubnagar", label: "509001" },
    { value: "Maheshtala", label: "700141" },
    { value: "Malda", label: "732101" },
    { value: "Malegaon", label: "423203" },
    { value: "Mangalore", label: "575001" },
    { value: "Mango", label: "831012" },
    { value: "Mathura", label: "281001" },
    { value: "Mau", label: "275101" },
    { value: "Medinipur", label: "721101" },
    { value: "Meerut", label: "250001" },
    { value: "Mehsana", label: "384001" },
    { value: "Mira-Bhayandar", label: "401107" },
    { value: "Mirzapur", label: "231001" },
    { value: "Moradabad", label: "244001" },
    { value: "Morbi", label: "363641" },
    { value: "Morena", label: "476001" },
    { value: "Motihari", label: "845401" },
    { value: "Munger", label: "811201" },
    { value: "Murwara (Katni)", label: "483501" },
    { value: "Muzaffarnagar", label: "251001" },
    { value: "Muzaffarpur", label: "842001" },
    { value: "Mysore", label: "570001" },
    { value: "Nadiad", label: "387001" },
    { value: "Nagaon", label: "782001" },
    { value: "Nagercoil", label: "629001" },
    { value: "Nagpur", label: "440001" },
    { value: "Naihati", label: "743165" },
    { value: "Nanded", label: "431601" },
    { value: "Nandyal", label: "518501" },
    { value: "Nangloi Jat", label: "110041" },
    { value: "Nashik", label: "422001" },
    { value: "Navi Mumbai", label: "400701" },
    { value: "Nellore", label: "524001" },
    { value: "New Delhi", label: "110001" },
    { value: "Nizamabad", label: "503001" },
    { value: "Noida", label: "201301" },
    { value: "North Dumdum", label: "700074" },
    { value: "Ongole", label: "523001" },
    { value: "Orai", label: "285001" },
    { value: "Osmanabad", label: "413501" },
    { value: "Pali", label: "306401" },
    { value: "Pallavaram", label: "600043" },
    { value: "Panchkula", label: "134109" },
    { value: "Panihati", label: "700114" },
    { value: "Panipat", label: "132103" },
    { value: "Panvel", label: "410206" },
    { value: "Parbhani", label: "431401" },
    { value: "Patiala", label: "147001" },
    { value: "Patna", label: "800001" },
    { value: "Phagwara", label: "144401" },
    { value: "Pimpri-Chinchwad", label: "411018" },
    { value: "Pondicherry", label: "605001" },
    { value: "Ponnani", label: "679577" },
    { value: "Port Blair", label: "744101" },
    { value: "Proddatur", label: "516360" },
    { value: "Pudukkottai", label: "622001" },
    { value: "Pune", label: "411001" },
    { value: "Purnia", label: "854301" },
    { value: "Raebareli", label: "229001" },
    { value: "Raichur", label: "584101" },
    { value: "Raiganj", label: "733134" },
    { value: "Raipur", label: "492001" },
    { value: "Rajahmundry", label: "533101" },
    { value: "Rajkot", label: "360001" },
    { value: "Rajpur Sonarpur", label: "700149" },
    { value: "Ramagundam", label: "505208" },
    { value: "Rampur", label: "244901" },
    { value: "Ranchi", label: "834001" },
    { value: "Ratlam", label: "457001" },
    { value: "Raurkela", label: "769001" },
    { value: "Rewa", label: "486001" },
    { value: "Rewari", label: "123401" },
    { value: "Rohtak", label: "124001" },
    { value: "Rourkela", label: "769001" },
    { value: "Sagar", label: "470001" },
    { value: "Saharanpur", label: "247001" },
    { value: "Saharsa", label: "852201" },
    { value: "Salem", label: "636001" },
    { value: "Sambalpur", label: "768001" },
    { value: "Sambhal", label: "244302" },
    { value: "Sangli-Miraj & Kupwad", label: "416416" },
    { value: "Satara", label: "415001" },
    { value: "Satna", label: "485001" },
    { value: "Secunderabad", label: "500003" },
    { value: "Serampore", label: "712201" },
    { value: "Shahjahanpur", label: "242001" },
    { value: "Shimla", label: "171001" },
    { value: "Shimoga", label: "577201" },
    { value: "Shivpuri", label: "473551" },
    { value: "Sikar", label: "332001" },
    { value: "Silchar", label: "788001" },
    { value: "Siliguri", label: "734001" },
    { value: "Singrauli", label: "486889" },
    { value: "Sirsa", label: "125055" },
    { value: "Siwan", label: "841226" },
    { value: "Solapur", label: "413001" },
    { value: "Sonipat", label: "131001" },
    { value: "South Dumdum", label: "700074" },
    { value: "Sri Ganganagar", label: "335001" },
    { value: "Srinagar", label: "190001" },
    { value: "Sultan Pur Majra", label: "110086" },
    { value: "Surat", label: "395001" },
    { value: "Surendranagar Dudhrej", label: "363002" },
    { value: "Suryapet", label: "508213" },
    { value: "Tadepalligudem", label: "534101" },
    { value: "Tadipatri", label: "515411" },
    { value: "Tambaram", label: "600045" },
    { value: "Tenali", label: "522201" },
    { value: "Thane", label: "400601" },
    { value: "Thanjavur", label: "613001" },
    { value: "Thiruvananthapuram", label: "695001" },
    { value: "Thoothukudi", label: "628001" },
    { value: "Thrissur", label: "680001" },
    { value: "Tiruchirappalli", label: "620001" },
    { value: "Tirunelveli", label: "627001" },
    { value: "Tirupati", label: "517501" },
    { value: "Tiruppur", label: "641601" },
    { value: "Tiruvottiyur", label: "600019" },
    { value: "Tumkur", label: "572101" },
    { value: "Udaipur", label: "313001" },
    { value: "Udupi", label: "576101" },
    { value: "Ujjain", label: "456001" },
    { value: "Ulhasnagar", label: "421002" },
    { value: "Uluberia", label: "711316" },
    { value: "Unnao", label: "209801" },
    { value: "Vadodara", label: "390001" },
    { value: "Vallabh Vidyanagar", label: "388120" },
    { value: "Vapi", label: "396191" },
    { value: "Varanasi", label: "221001" },
    { value: "Vasai-Virar", label: "401202" },
    { value: "Vellore", label: "632001" },
    { value: "Veraval", label: "362265" },
    { value: "Vijayawada", label: "520001" },
    { value: "Visakhapatnam", label: "530001" },
    { value: "Vizianagaram", label: "535001" },
    { value: "Warangal", label: "506002" },
    { value: "Wardha", label: "442001" },
    { value: "Yamunanagar", label: "135001" },
    { value: "Yavatmal", label: "445001" },
    { value: "Zirakpur", label: "140603" },
  ];

  const handlePincodeChange = (selectedOption) => {
    setSelectedPincode(selectedOption);
  };

  return (
    <div>
      <Select
        value={selectedPincode}
        onChange={handlePincodeChange}
        options={allPincodes}
        isMulti
        isDisabled={disableSelection}
      />
    </div>
  );
};

export default PincodeSelector;
