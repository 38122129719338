import React from "react";
import { Row, Card, Col, Container } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";

const Loader = (props) => {
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Row>
        <Col lg={9} sm={6} xs={12} className="dash-head">
          Loading
        </Col>
        <React.Fragment>
          <div className="page-content">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <Card.Title className="h4 mb-2 ">
                      Please Hold on...{" "}
                    </Card.Title>
                    <div style={{ height: "60vh", display: "flex" }}>
                      <div
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <TailSpin
                          color="orange"
                          height={50}
                          width={50}
                          className="mx-auto my-auto"
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      </Row>
    </Container>
  );
};

export default Loader;
