import React from "react";
import SoldProductReportAddonTable from "./SoldProductReportAddonTable";

const SoldProductReportTable = ({ productReportdata, addOnList }) => {
  return (
    <div className="border border-1 border-secondary p-2 rounded my-4">
      <div className="d-flex justify-content-between align-items-center mb-5">
        {/* <div><b>{productReportdata.date}</b></div> */}
        <h5>{productReportdata.product_name}</h5>
        <span>
          {" "}
          <b>Quantity:</b>
          {productReportdata.quantity}
        </span>
      </div>
      <div>
        {addOnList.map((addOnData, i) => {
          return <SoldProductReportAddonTable key={i} addOnData={addOnData} />;
        })}
      </div>
    </div>
  );
};

export default SoldProductReportTable;
