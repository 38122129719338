import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Async from "react-select/async";
// import {
//   getGroupList,
//   DeleteGroup,
//   addNewGroup,
// } from "../../store/actionCreators/Groups/GroupsAction";
import { addNewGroupCoupon, updateGroupCoupon } from "../../../store/actionCreators/Coupons/CouponsAction";
import axios from "axios";
import { useMemo } from "react";

const EditGroup = ({customerOption, show, close , groupOptions}) => {
  const [groupData, setGroupData] = useState({
    old_group_name:"",
    new_group_name: "",
    customer_no: "",
    customer_id: "",
  });
  const dispatch = useDispatch();
  const { isLoading, setIsLoading } = useState(false);
  const [selectedCustomers, setCustomers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);


  console.log(selectedCustomers);
  const groups = useSelector((state) => state.groups);
  //   const group = useSelector((state) => state.group);
  console.log(groups.data, "group");

  const closeModal = () => {
    close();
  };
  
  
  const loadGroupData = async (group) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_groups/1?search=${group}`
      );
      // console.log(res);
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.customer_group_name} `,
          value: c.customer_group_name,
        }));
        
        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };


  const loadCustomers = async (customer) => {
    // console.log(customer);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_customers/1?search=${customer}`
      );
      // console.log(res);
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
            c.mobile_no
          })`,
          value: c.mobile_no,
          customer_id: c.customer_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  let { customerNoString, customerIdString } = useMemo(() => {
    let customerNoString = "",
      customerIdString = "";
    for (let i = 0; i < selectedCustomers.length; i++) {
      if (i === selectedCustomers.length - 1) {
        customerNoString = customerNoString + selectedCustomers[i].value;
        customerIdString = customerIdString + selectedCustomers[i].customer_id;
      } else {
        customerNoString = customerNoString + selectedCustomers[i].value + ",";
        customerIdString =
          customerIdString + selectedCustomers[i].customer_id + ",";
      }
    }

    return { customerNoString, customerIdString };
  }, [selectedCustomers]);

  const updateGroupButton = () => {
    if (groupData === "") {
      toast.error(`Please enter a name for the group`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      console.log(groupData);
      dispatch(updateGroupCoupon(groupData, customerNoString, customerIdString));
    }
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
        <div className="mb-2">
          <label className="mt-2 mb-2">Select a group:</label>
          <Async
            defaultOptions={groupOptions}
            cacheOptions={[{ label: "1234", value: "1234" }]}
            isLoading={isLoading}
            loadOptions={(e) => loadGroupData(e)}
            onChange={(e) => {          
              // console.log(e)
              groupData.old_group_name=e[0]?.value;
                  setSelectedGroup(e)}}
            isMulti={true}
            placeholder='Search group...'
          />
        </div>
          <div className="mb-2">
            <label>New Group Name</label>
            <input
              required
              type="text"
              className="form-control"
              value={groupData.new_group_name}
              onChange={(e) => {
                e.preventDefault();
                setGroupData({
                  ...groupData,
                  new_group_name: e.target.value,
                });
              }}
            />
          </div>
          <div className="mb-2" style={{ width: "20rem" }}>
            <label className="mb-2">Select Customers:</label>
            <Async
              isMulti={true}
              defaultOptions={customerOption}
              cacheOptions={[{ label: "1234", value: "1234" }]}
              isLoading={isLoading}
              value={selectedCustomers}
              loadOptions={(e) => loadCustomers(e)}
              onChange={(e) => setCustomers(e)}
              placeholder='Search by Name or Mobile no....'
            />
          </div>

          <Button
            className="btn btn-warning"
            type="submit"
            onClick={() => {
              updateGroupButton();
              close();
            }}
          >
            Update
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditGroup;
