import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import {
  fetchCafeStock,
  getAllBranchesInventory,
  setCafeStockPagination,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import CafeStockPagination from "./CafeStockPagination";
import { toast } from "react-toastify";
import axios from "axios";
import cellEditFactory from "react-bootstrap-table2-editor";
import UpdateQuantityModal from "./UpdateQuantityModal";
import Unauthorized from "../../unauthorized";

const CafeStockList = (props) => {
  const dispatch = useDispatch();
  const cafeStockList = useSelector((state) => state.get_cafe_stock);
  const branch = useSelector((state) => state.get_all_branches);
  const page = useSelector((state) => state.cafe_stock_pagination);
  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);
  const [itemsListPaginatedData, setItemsListPaginatedData] = useState();

  const firstObj = branch?.data?.data[0] || {};

  const initialBranchValue = Object.values(firstObj || {});
  const initialBranchId = Object.values(firstObj || {});

  const [branchName, setBranchName] = useState(initialBranchId[1]);
  const [branchId, setBranchId] = useState(initialBranchValue[0]);

  const [searchTerm, setSearchTerm] = useState("");

  // states for modal
    const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    if (!!branchId) {
      setLoading(true);
      if (!searchTerm) {
        dispatch(fetchCafeStock(page, branchId));
      } else {
        onSearchData(page, branchId, searchTerm);
      }
      // dispatch(fetchCafeStock(page, branchId));
    } else {
      dispatch(getAllBranchesInventory());
    }
  }, [page, branchId]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [cafeStockList]);

  const setDefaultData = () => {
    let itemsList = [];
    if (cafeStockList.data && cafeStockList.data.data) {
      itemsList = cafeStockList.data.data.map((c) => ({
        ...c,
        createdAt: c?.inventory_item?.createdAt.toLocaleString().split("T")[0],
        name: c?.inventory_item?.name,
        price: c?.inventory_item?.price,
        unit: c?.inventory_item?.unit,
      }));
    }
    setItemsList(itemsList);
    setItemsListPaginatedData(cafeStockList.data);
  };

  const updateQty = (data) => {
    const { branch_id, inventory_items_id, quantity } = data;

    const dataClone = {
      branch_id,
      inventory_items_id,
      quantity: +quantity,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_cafe_stock`,
        dataClone
      )
      .then((res) => {
        if (res.data.status === "success") {
          setShowModal(false);
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(fetchCafeStock(page, branchId));

        } else {
          toast.error(`${res.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };




  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() => {
              setShowModal(!showModal);
              setRowData(row);
            }}
            className="btn btn-sm btn-warning"
          >
            Update
          </button>
        </div>
      );
    }
    //  else {
    //   return (
    //     <div
    //       style={{
    //         textAlign: "center",
    //         cursor: "pointer",
    //         lineHeight: "normal",
    //       }}
    //     >
    //       <button className="btn btn-sm btn-warning">Update</button>
    //     </div>
    //   );
    // }
  }

  const columns = [
    {
      dataField: "inventory_items_id",
      text: "Inventory Item Id",
      sort: true,
      editable: false,
    },

    {
      dataField: "name",
      text: "Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "unit",
      text: "Unit",
      sort: true,
      editable: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      editable: false,
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      editable: false,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
      editable: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: cafeStockList.data?.per_page,
    totalSize: cafeStockList.data
      ? cafeStockList.data.total_items
        ? cafeStockList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const cafeStockList = [...itemsList];
    return cafeStockList;
  };

  const displayBranches = () => {
    if (branch.data) {
      return branch.data.data.map((item, index) => {
        return (
          <Dropdown.Item
            key={item.branch_id}
            eventKey={`${item.branch_name},${item.branch_id}`}
          >
            {item.branch_name}
          </Dropdown.Item>
        );
      });
    }
  };

  const handleBranchChange = (e) => {
    const value = e.split(",")[1];
    const name = e.split(",")[0];
    setBranchId(value);
    setBranchName(name);
  };

  const onSearchData = (page, branchId, value) => {
    setSearchTerm(value);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/search_cafe_stock/${page}?branch_id=${branchId}&search=${value}`
      )
      .then((res) => {
        const data = res?.data?.data;
        const itemsList = (data || []).map((c) => ({
          ...c,
          createdAt: c?.inventory_item?.createdAt
            .toLocaleString()
            .split("T")[0],
          name: c?.inventory_item?.name,
          price: c?.inventory_item?.price,
          unit: c?.inventory_item?.unit,
        }));
        setItemsList(itemsList);
        setItemsListPaginatedData(res.data);
        if (res?.data?.status === "failure") {
          setItemsList([]);
          dispatch(setCafeStockPagination(1));
        }
      })
      .catch((err) => {
        console.error(err);
      }).finally(()=>{
        setLoading(false)
      });
  };

  const MySearch = (props) => {
    let input;
    const handleClick = (e) => {
      e.preventDefault();
      props.onSearch(input.value);
      onSearchData(page, branchId, input.value);
    };
    return (
      <form onSubmit={handleClick} className="d-flex gap-2">
        <input
          className="form-control"
          style={{ backgroundColor: "none" }}
          ref={(n) => (input = n)}
          type="text"
        />
        <button className="btn btn-warning">Search</button>
      </form>
    );
  };

  if (props.viewPermission) {
  if (!loading) {
    return (
      <React.Fragment>
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
            Inventory Cafe Stock{" "}
          </Col>
          <Col lg={4} sm={8} xs={12}></Col>
          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between mb-2">
                      <div
                        className="d-flex justify-content-between "
                        style={{ gap: "12px" }}
                      >
                        <Card.Title>Cafe Stock</Card.Title>

                        <DropdownButton
                          variant="light"
                          title={branchName ? branchName : "Branch"}
                          id="dropdown-menu-align-right"
                          onSelect={handleBranchChange}
                        >
                          {displayBranches()}
                        </DropdownButton>
                      </div>
                    </div>

                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="inventory_items_id2"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="inventory_items_id2"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <MySearch
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="search-box chat-search-box" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="inventory_items_id2"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        cellEdit={cellEditFactory({
                                          blurToSave: true,
                                          mode: "dbclick",
                                          afterSaveCell: (
                                            oldValue,
                                            newValue,
                                            row,
                                            column
                                          ) => {
                                            console.log(
                                              {
                                                oldValue,
                                                newValue,
                                                row,
                                                column,
                                              },
                                              "After Saving Cell!!"
                                            );
                                          },
                                        })}
                                      />
                                      <UpdateQuantityModal
                                        showModal={showModal}
                                        close={() => setShowModal(false)}
                                        data={rowData}
                                        updateQty={updateQty}
                                        setRowData={setRowData}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <CafeStockPagination
                                    pageNum={Math.ceil(
                                      itemsListPaginatedData
                                        ? itemsListPaginatedData?.total_items /
                                            itemsListPaginatedData?.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  } else
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {" "}
        <Loader />{" "}
      </Container>
    );
  } else {
    return <Unauthorized />;
    }
};

export default CafeStockList;
