import {
  Container,
  Row,
  Col,
  Card,
  Button,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import DoughnutGraph from "./doughnutGraph";
import React, { useEffect, useState } from "react";
import RevenueAnalytics from "./revenueAnalytics";
import MiniWidgets from "./miniWidgets";
import "../../css/dashboard.css";
import { RiStackLine } from "react-icons/ri";
import { RiStore2Line } from "react-icons/ri";
import { RiBriefcase4Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { salesAnalyticsDashboardPie } from "../../store/actionCreators/dashboard/dasboardActions";
import Unauthorized from "../unauthorized";
import Loader from "./../Loader/Loader";
import Select from "react-select";
import MonthPicker from "./MonthPicker";
import { toast } from "react-toastify";
import MostOrderGraph from "./mostOrderGraph";

const Dashboard = (props) => {
  const filters = useSelector((state) => state.dashboard_filters);
  const cards = useSelector((state) => state.dashboard_card);
  const salespie = useSelector((state) => state.dashboard_sales_pie);
  const login = useSelector((state) => state.login);
  const [branchArray, setBranchArray] = useState(["All"]);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const dispatch = useDispatch();
  const currYear = new Date().getFullYear();
  const [current, setcurrent] = useState({
    month: "Jan",
    year: 2022,
  });
  const [currentRevenuedate, setcurrentRevenue] = useState({
    month: "Jan",
    year: 2022,
  });

  const [revFilter, setRevFilter] = useState("yearly");
  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const yearArray = () => {
    let arrYear = ["All"];
    for (let i = 0; i < currYear - 2017 + 1; i++) {
      arrYear.push(2017 + i);
    }
    return arrYear;
  };
  let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [currentFilter, setCurrentFilters] = useState({
    branch: { branch_name: "All", branch_id: "All" },
    franchise: { franchise_name: "All", franchise_id: "All" },
  });
  useEffect(() => {
    // dispatch(setCustomerVars());
    editPermissions();
  }, []);

  const [salesCarddata, setSalesCard] = useState({
    avg_sales_per_day: 0,
    num_of_sales: 0,
    sales_revenue: 0,
  });
  useEffect(() => {
    if (cards.data) {
      setSalesCard({
        avg_sales_per_day: cards.data.avg_sales_per_day,
        num_of_sales: cards.data.num_of_sales,
        sales_revenue: cards.data.sales_revenue.toFixed(2),
      });
    }
  }, [cards]);
  const reports = [
    {
      icon: RiStackLine,
      title: "Number of Orders",
      value: Number.parseFloat(
        Math.round(salespie?.data?.data?.net_order_count)
      ).toLocaleString(),
      rate: "2.4%",
      desc: "From previous period",
    },
    {
      icon: RiStore2Line,
      title: "Sales Revenue",
      value: `₹ ${Number.parseFloat(
        Math.round(salespie?.data?.data?.net_revenue_order)
      ).toLocaleString()}`,
      rate: "2.4%",
      desc: "From previous period",
    },
    {
      icon: RiBriefcase4Line,
      title: "Average Sales per Day",
      value: `₹ ${Number.parseFloat(
        Math.round(salespie?.data?.data?.net_average_order)
      ).toLocaleString()}`,
      rate: "2.4%",
      desc: "From previous period",
    },
  ];
  const random_hex_color_code = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6);
  };

  useEffect(() => {
    callAPI(filter);
  }, []);

  useEffect(() => {
    setDoughnutState({
      // series: [42, 26],
      series: salespie.data?.data?.rev,
      avg: salespie.data?.data?.avg,
      options: {
        labels: salespie.data?.data?.branch_name,
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: (() => {
          const arr = [];
          if (salespie.data?.data?.branch_name)
            for (let i = 0; i < salespie.data?.data?.branch_name?.length; i++) {
              arr.push(random_hex_color_code());
            }
          return arr;
        })(),
      },
    });
  }, [salespie]);

  // doughnut Graph
  const [Doughnutstate, setDoughnutState] = useState({
    // series: [42, 26],
    series: salespie.data?.data?.rev,
    options: {
      labels: salespie.data?.data?.branch_name,
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: (() => {
        const arr = [];
        if (salespie.data?.data?.branch_name)
          for (let i = 0; i < salespie.data?.data?.branch_name?.length; i++) {
            arr.push(random_hex_color_code());
          }
        return arr;
      })(),
    },
  });
  // useEffect(() => {
  //   let labels = [];
  //   let series = [];
  //   let colors = [];
  //   var dynamicColors = function () {
  //     var r = Math.floor(Math.random() * 255);
  //     var g = Math.floor(Math.random() * 255);
  //     var b = Math.floor(Math.random() * 255);
  //     return "rgb(" + r + "," + g + "," + b + ")";
  //   };
  //   if (salespie.data) {
  //     for (let i = 0; i < salespie.data.sales_analytics.length; i++) {
  //       const key = Object.keys(salespie.data.sales_analytics[i]);
  //       labels.push(key[0]);
  //       series.push(
  //         parseInt(salespie.data.sales_analytics[i][key[0]].slice(0, -1))
  //       );
  //       colors.push(dynamicColors());
  //     }

  //     setDoughnutState({
  //       series: series,
  //       options: {
  //         labels: labels,
  //         plotOptions: {
  //           pie: {
  //             donut: {
  //               size: "75%",
  //             },
  //           },
  //         },
  //         dataLabels: {
  //           enabled: false,
  //         },
  //         legend: {
  //           show: false,
  //         },
  //         colors: colors,
  //       },
  //     });
  //   }
  // }, [salespie]);

  // Revenue analytics

  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      admin_permissions.forEach((item) => {
        if (item.module === "Dashboard") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };

  function monthDrop() {
    return monthArray.map((item, index) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  }
  const handleMonthChange = (e) => {
    setcurrent({
      ...current,
      month: e.target.value,
    });
  };
  const yearDrop = () => {
    const year = yearArray();
    return year.map((item, index) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };
  const handleYearChange = (e) => {
    setcurrent({
      ...current,
      year: e.target.value,
    });
  };
  // Revenue ANALYTICS month and year dropdown
  const handleMonthChangeRev = (e) => {
    setcurrentRevenue({
      ...current,
      month: e.target.value,
    });
  };

  const handleYearChangeRev = (e) => {
    setcurrentRevenue({
      ...current,
      year: e.target.value,
    });
  };
  // Filters dropdown
  const [franchiseName, setfranchiseName] = useState("All");

  const FranchiseDrop = () => {
    if (filters.data) {
      return filters.data.data.map((item, index) => {
        return (
          <option key={index} value={index}>
            {item.franchise_name}
          </option>
        );
      });
    }
  };
  const handleFranchiseChange = (e) => {
    const index = e.target.value;
    setfranchiseName(filters.data.data[index].franchise_name);
    setBranchArray(filters.data.data[index].branches);
    setCurrentFilters({
      branch: {
        branch_name: filters.data.data[index].branches[0].branch_name,
        branch_id: filters.data.data[index].branches[0].branch_id,
      },
      franchise: {
        franchise_name: filters.data.data[index].franchise_name,
        franchise_id: filters.data.data[index].franchise_id,
      },
    });
  };
  const BranchDrop = () => {
    return branchArray.map((item, index) => {
      return (
        <option
          key={index}
          value={`["${item.branch_name}","${item.branch_id}"]`}
        >
          {item.branch_name ? item.branch_name : item}
        </option>
      );
    });
  };
  const handleBranchChange = (e) => {
    let item = JSON.parse(e.target.value);
    setCurrentFilters({
      ...currentFilter,
      branch: { branch_name: item[0], branch_id: item[1] },
    });
  };

  const callAPI = (OB) => {
    console.log(OB);
    dispatch(salesAnalyticsDashboardPie({ ...OB }));
  };
  const [value1, setValue1] = React.useState({ year: 2020, month: 9 });
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const monthPickerRef1 = React.useRef(null);
  const [modal, setModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("TODAY");
  const [year, setYear] = useState(new Date().getFullYear());
  const [filter, setFilter] = useState({ type: "TODAY" });
  const [displayMonthYear, setDisplayMonthYear] = useState(
    new Date().getFullYear()
  );
  const lang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    from: "From",
    to: "To",
  };
  const dic = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const filterObj = {
    TODAY: "Today",
    L7D: "Last 7 days",
    MONTH: "Month",
    YEAR: "Year",
    ALLYEARS: "All Years",
    CUSTOM: "Custom",
  };

  const years = function (startYear) {
    var currentYear = new Date().getFullYear();
    var years = [];
    var startYear = 2021;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
  }

  const customOnClick = () => {
    if (value1 === "" || value2 === "") {
      toast.error(`Please select a date range`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const val1 = new Date(value2);
    const val2 = new Date(value3);
    const months = monthDiff(val1, val2);
    if (Math.abs(months) > 3) {
      toast.error(`Range Should be within 3 months`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setFilter({ start: value2, end: value3, type: "CUSTOM" });
    callAPI({ start: value2, end: value3, type: "CUSTOM" });
    setDisplayMonthYear(`${value2} - ${value3}`);
    setModal(false);
  };

  const modalBody = {
    MONTH: (
      <div>
        <div className="d-flex mx-auto">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Month{" "}
          </label>
          <MonthPicker
            className="my-auto "
            lang={lang.months}
            ref={monthPickerRef1}
            value={value1}
            setMonth={(e) => setValue1({ year: e.year, month: e.month })}
          ></MonthPicker>
        </div>

        <div className="d-flex mt-3">
          <Button
            className="mx-auto"
            onClick={() => {
              setFilter({
                type: "MONTH",
                year: value1.year,
                month: value1.month,
              });
              callAPI({
                type: "MONTH",
                year: value1.year,
                month: value1.month,
              });
              setDisplayMonthYear(`${dic[value1.month]}, ${value1.year}`);
              setModal(false);
            }}
          >
            Show
          </Button>
        </div>
      </div>
    ),

    YEAR: (
      <div className="mx-auto">
        <div className="d-flex">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Year{" "}
          </label>
          <Select
            value={{ label: year, value: year }}
            onChange={(e) => setYear(e.value)}
            options={[...years().map((y) => ({ label: y, value: y }))]}
          />
        </div>
        <div className="d-flex mt-3">
          <Button
            className="mx-auto"
            onClick={() => {
              setFilter({ type: "YEAR", year });
              callAPI({ type: "YEAR", year });
              setDisplayMonthYear(year);
              setModal(false);
            }}
          >
            Show
          </Button>
        </div>
      </div>
    ),

    CUSTOM: (
      <div>
        <div style={{ display: "flex" }} className="mx-auto">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Range{" "}
          </label>
          <input
            type="date"
            value={value2}
            onChange={(e) => setValue2(e.target.value)}
          />
          -
          <input
            type="date"
            value={value3}
            onChange={(e) => setValue3(e.target.value)}
          />
        </div>
        <div className="d-flex mt-3">
          <Button className="mx-auto" onClick={() => customOnClick()}>
            Show
          </Button>
        </div>
      </div>
    ),
  };
  if (viewPermission) {
    return (
      <React.Fragment>
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Row>
            <Modal onHide={() => setModal(false)} show={modal}>
              <Modal.Header closeButton>
                <Modal.Title>Select Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex">{modalBody[selectedFilter]}</div>
              </Modal.Body>
            </Modal>
            <Col lg={8} sm={6} xs={12} className="dash-head">
              DASHBOARD
            </Col>
            <Col lg={4} sm={6} xs={12}></Col>
          </Row>
          <Row>
            <Col lg="8">
              <Row>
                <RevenueAnalytics />
              </Row>
              <Row>
                <MostOrderGraph />
              </Row>
            </Col>
            <Col lg="4">
              <p className="text-center">Complete Revenue Status </p>
              <Row
                className="d-flex"
                style={{ paddingLeft: "0.75rem", paddingRight: "0.0rem" }}
              >
                <Col className="my-auto" style={{ display: "contents" }}>
                  <div className="my-auto ml-auto">{displayMonthYear}</div>
                </Col>
                <Col>
                  <div className="mr-2 ">
                    <Select
                      options={[
                        { label: "Today", value: "TODAY" },
                        { label: "Last 7 days", value: "L7D" },
                        { label: "Month", value: "MONTH" },
                        { label: "Year", value: "YEAR" },
                        { label: "All Years", value: "ALLYEARS" },
                        { label: "Custom", value: "CUSTOM" },
                      ]}
                      value={{
                        value: selectedFilter,
                        label: filterObj[selectedFilter],
                      }}
                      onChange={(e) => {
                        if (
                          e.value === "MONTH" ||
                          e.value === "YEAR" ||
                          e.value === "CUSTOM"
                        ) {
                          setModal(true);
                        } else {
                          if (e.value != "ALLYEARS")
                            setDisplayMonthYear(
                              `${
                                dic[new Date().getMonth()+1]
                              }, ${new Date().getFullYear()}`
                            );
                          else {
                            setDisplayMonthYear(`All`);
                          }
                          setFilter({ type: e.value });
                          callAPI({ type: e.value });
                        }
                        setSelectedFilter(e.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>
                <Col xl={12}>
                  <MiniWidgets reports={reports} />
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Card
                    style={{
                      width: "100%",
                      boxShadow: " 0 2px 4px rgb(0 0 0 / 8%)",
                    }}
                  >
                    <Card.Body>
                      <DoughnutGraph
                        options={Doughnutstate?.options}
                        series={Doughnutstate?.series}
                        avg={Doughnutstate?.avg}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
  return <Unauthorized />;
};
export default Dashboard;
