import React, { useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link, useNavigate } from "react-router-dom";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../css/customer/customerTable.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeePagination from "./EmployeePagination";
import {
  fetchSingleEmployee,
  setEmployeePagination,
  setEmployeeRecords,
  deleteEmployeeDetails,
  getEmployeeRecords,
} from "../../store/actionCreators/Employees/EmployeeAction";
import EditEmployeeModal from "./EditEmployeeModal";
import axios from "axios";
import Select from "react-select";

const EmployeeTable = (props) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [name, setNameData] = useState("");
  const [empId,setEmpId]=useState();
  const records = useSelector((state) => state.empPageRecords);
  const navigate = useNavigate();
  const onClickFunction = (row) => {
    dispatch(setEmployeePagination(1));
    dispatch(setEmployeeRecords(10));
    dispatch(fetchSingleEmployee(row?.employee_id));
    navigate("/employee/individual", { state: { id: row?.employee_id } });
  };
  const employees = useSelector((state) => state.employees);

  useEffect(() => {
    setEmployeePagination(1);
    setEmployeeRecords(10);
  }, []);


  const fakeData = async() => {
    
    setPage(Math.ceil(employees.data.total_employees / records));
    setProductData(
      employees.data.data.map((e) => ({
        ...e,
        full_name: capitalize(e.full_name),
        gender: capitalize(e.gender),
        employee_role: capitalize(e.employee_role),
      }))
    );
    console.log('fake');
  };
  useEffect(() => {
    fakeData();
    if (employees.data) {
      if (employees.data.status !== "failure") {
        fakeData();
      }
    }
  }, [employees]);

  const loadData = async (emp) => {
    if (emp !== ""){
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
        );
        if (res.data && res.data.data) {
          const data = res.data.data.map((e) => ({
            ...e,
            full_name: capitalize(e.full_name),
            gender: capitalize(e.gender),
            employee_role: capitalize(e.employee_role),
          }));

          setPage(1);
          setProductData(data);
          
        }
      } catch (error) {
        console.log("error", error);
      }}
    else {
      console.log('load');
    fakeData();
      
      
    }
  };

  function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  // function rankFormatter(cell, row, rowIndex, formatExtraData) {
  //   return (
  //     <div
  //       style={{
  //         textAlign: "center",
  //         cursor: "pointer",
  //         lineHeight: "normal",
  //       }}
  //     >
  //       <button
  //         exact="true"
  //         to="/employee/individual"
  //         onClick={() => onClickFunction(row)}
  //         className="btn btn-sm btn-warning"
  //       >
  //         View
  //       </button>
  //     </div>
  //   );
  // }

  async function handleSubmit(event) {
    event.preventDefault();
  }
 
  function handleDeleteEmployee(e,row){
    e.stopPropagation();
    dispatch(deleteEmployeeDetails(row?.employee_id))
  }
  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={(e)=>{
              handleDeleteEmployee(e,row)}}
            className="btn btn-sm btn-danger ms-2"
            style={{ marginLeft: "0.6rem" }}
          >
            Delete
          </button>
        </div>
      );
    }
  }
  const columns = [
    {
      dataField: "full_name",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "employee_role",
      text: "Employee Role",
      sort: false,
    },
    {
      dataField: "mobile_no",
      text: "Phone",
      sort: false,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "date_of_birth",
      text: "DOB",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "branch",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: true,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: records,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content ">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <Card.Title className="h4 mb-2 ">
                    Employee Datatable{""}
                  </Card.Title>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="employee_id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="employee_id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <Form.Control
                                      type="text"
                                      placeholder="Search..."
                                      value={name}
                                      onChange={(e) => {
                                        console.log(e.target.value);
                                        setNameData(e.target.value)
                                        loadData(name);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="employee_id"
                                    responsive
                                    rowEvents={{
                                      onClick: function (e, row) {
                                        onClickFunction(row);
                                        // navigate("/employee/individual");
                                      },
                                    }}
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col xl={6}>
                                <EmployeePagination
                                  getEmps={props.getEmps}
                                  pageNum={page}
                                />
                              </Col>
                              <Col className="d-flex">
                                <div style={{ marginLeft: "auto" }}>
                                  <Select
                                    options={[
                                      { label: "10", value: "10" },
                                      { label: "20", value: "20" },
                                      { label: "50", value: "50" },
                                      { label: "100", value: "100" },
                                    ]}
                                    onChange={(e) => {
                                      dispatch(setEmployeeRecords(e.value));
                                      // dispatch(setEmployeePagination(1));
                                      // setRecords(e.value);
                                    }}
                                    className="ml-auto"
                                    value={{ label: records, value: records }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </React.Fragment>
  );
};

export default EmployeeTable;
