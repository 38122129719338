import React, { useState, useEffect } from "react";
import { Modal, Button, Col } from "react-bootstrap";

const UpdateQuantityModal = ({
  showModal,
  close,
  data,
  updateQty,
  setRowData,
}) => {
  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={close}
        size="sm"
        aria-labelledby="contained-modal-title-center"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Quantity for {data.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateQty(data);
            }}
          >
            <div className="mb-2">
              <label> Quantity</label>
              <input
                required
                type="number"
                min="0"
                className="form-control"
                value={data.quantity}
                onChange={(e) =>
                  setRowData({ ...data, quantity: e.target.value })
                }
              />
            </div>

            <div>
              <Button className="btn btn-warning" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateQuantityModal;
