import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../Loader/LoaderTable";
import {
  fetchAllNotification,
  setNotificationListPagination,
} from "../../store/actionCreators/Notification/NotificationAction";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import NotificationListPagination from "./NotificationListPagination";
import Unauthorized from "../unauthorized";

const AllNotificationsList = (props) => {
  const dispatch = useDispatch();
  const allNotificationsList = useSelector(
    (state) => state.get_all_notifications
  );

  console.log(props, "PROPS_ALL_NOTIFICATIONS_LIST");

  const page = useSelector((state) => state.notification_list_pagination);
  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);
  const [itemsListPaginatedData, setItemsListPaginatedData] = useState();

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setLoading(true);
    if (!searchTerm) {
      dispatch(fetchAllNotification(page));
    } else {
      onSearchData(page, searchTerm);
    }
  }, [page]);

  useEffect(() => {
    if (allNotificationsList?.data?.status === "success") {
      setLoading(false);
    }
    setLoading(false);
    setDefaultData();
  }, [allNotificationsList]);

  const setDefaultData = () => {
    let itemsList = [];
    if (allNotificationsList.data && allNotificationsList.data.data) {
      itemsList = allNotificationsList.data.data.map((c) => ({
        ...c,
        createdAt: c?.createdAt.toLocaleString().split("T")[0],
        title: c?.title,
        body: c?.body,
      }));
    }
    setItemsList(itemsList);
    setItemsListPaginatedData(allNotificationsList.data);
  };

  console.log(props.editPermission, "editPermission");

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`view_notification/${row.notifications_id}`}
            // onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`edit_notification/${row.notifications_id}`}
            // onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row.notifications_id)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`view_notification/${row.notifications_id}`}
            // onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const deleteFunction = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/notifications/del_notifications`,
        {
          notifications_id: id,
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(`deleted successFully `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(fetchAllNotification(1));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  const columns = [
    {
      dataField: "notifications_id",
      text: "Notification Id",
      sort: true,
      editable: false,
    },

    {
      dataField: "title",
      text: "Title",
      sort: true,
      editable: false,
    },
    {
      dataField: "body",
      text: "Body",
      sort: true,
      editable: false,
    },

    {
      dataField: "notification_date",
      text: "Notification Date",
      sort: true,
      editable: false,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      editable: false,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
      editable: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "title",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allNotificationsList.data?.per_page,
    totalSize: allNotificationsList.data
      ? allNotificationsList.data.total_items
        ? allNotificationsList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const allNotificationsList = [...itemsList];
    return allNotificationsList;
  };

  const onSearchData = (page, value) => {
    setSearchTerm(value);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/notifications/search_notifications/${page}?search=${value}`
      )
      .then((res) => {
        const data = res?.data?.data;
        const itemsList = (data || []).map((c) => ({
          ...c,
          createdAt: c?.createdAt.toLocaleString().split("T")[0],
          title: c?.title,

          body: c?.body,
        }));
        setItemsList(itemsList);
        setItemsListPaginatedData(res.data);
        if (res?.data?.status === "failure") {
          setItemsList([]);
          dispatch(setNotificationListPagination(1));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };



  const MySearch = (props) => {
    let input;
    const handleClick = (e) => {
      e.preventDefault();
      props.onSearch(input.value);
      onSearchData(1, input.value);
    };
    return (
      <form onSubmit={handleClick} className="d-flex gap-2">
        <input
          className="form-control"
          style={{ backgroundColor: "none" }}
          ref={(n) => (input = n)}
          type="text"
        />
        <button className="btn btn-warning">Search</button>
      </form>
    );
  };



  if (props.viewPermission) {
    if (!loading) {
      return (
        <React.Fragment>
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
              notifications Dashboard{" "}
            </Col>
            <Col lg={4} sm={8} xs={12}></Col>
            <div className="page-content ">
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between mb-2">
                        <Card.Title className="h4 mb-4 ml-auto">
                          All Notifications
                        </Card.Title>

                        <Link
                          className="btn btn-warning mb-3 "
                          style={{ marginLeft: "1rem", marginRight: "1rem" }}
                          to={"/notifications/add_notification"}
                        >
                          Add New Notification
                        </Link>
                      </div>

                      {
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="notifications_id"
                          columns={columns}
                          data={getAllRows()}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="notifications_id"
                              columns={columns}
                              data={getAllRows()}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <MySearch
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="search-box chat-search-box" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="notifications_id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  {(allNotificationsList?.data?.status ===
                                    "failure" ||
                                    (itemsList || []).length === 0) && (
                                    <Row>
                                      <strong className="col-12">
                                        No Data Found
                                      </strong>
                                    </Row>
                                  )}

                                  <Row>
                                    <NotificationListPagination
                                      pageNum={Math.ceil(
                                        itemsListPaginatedData
                                          ? itemsListPaginatedData?.total_items /
                                              itemsListPaginatedData?.per_page
                                          : 1
                                      )}
                                    />
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </React.Fragment>
      );
    } else
      return (
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Loader />
        </Container>
      );
  } else {
    return <Unauthorized />;
  }
};

export default AllNotificationsList;
