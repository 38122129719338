import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import { getCafeEmployeeList } from "../../../store/actionCreators/Inventory/InventoryAction";
import { toast } from "react-toastify";
import axios from "axios";
import CafeEmployeeListPagination from "./CafeEmployeeListPagination";
import Unauthorized from "../../unauthorized";

const AllCafeEmployeesList = (props) => {
  const dispatch = useDispatch();
  const cafe_employee_list = useSelector(
    (state) => state.get_cafe_employees_list
  );
  const page = useSelector((state) => state.cafe_employee_list_pagination);

  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getCafeEmployeeList(page));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getCafeEmployeeList(page));
  }, [page]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [cafe_employee_list]);

  const setDefaultData = () => {
    let inventoryEmployeesList = [];
    if (cafe_employee_list.data && cafe_employee_list.data.data) {
      inventoryEmployeesList = cafe_employee_list.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
        last_logged_in: new Date(c.last_logged_in).toLocaleString(undefined, {
          timeZone: "Asia/Kolkata",
        }),
      }));
    }
    setEmployeeList(inventoryEmployeesList);
  };

  const deleteFunction = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/delete_cafe_inventory_employee?cafe_inventory_login_id=${id}`
      )
      .then((response) => {
        // console.log(response);
        if (response.data.status === "success") {
          toast.success(`deleted successfully `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(getCafeEmployeeList(1));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewEmployee/${row.cafe_inventory_login_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`editEmployee/${row.cafe_inventory_login_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row.cafe_inventory_login_id)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
           <Link
            exact="true"
            to={`viewEmployee/${row.cafe_inventory_login_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "mobile_no",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "date_of_birth",
      text: "Data of Birth",
      sort: true,
    },

    {
      dataField: "gender",
      text: "Gender",
      sort: true,
    },
    // {
    //   dataField: "branch_name",
    //   text: "Branch",
    //   sort: true,
    // },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
    },
    {
      dataField: "last_logged_in",
      text: "Last Logged In",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: cafe_employee_list.data?.per_page,
    totalSize: cafe_employee_list.data
      ? cafe_employee_list.data.total_items
        ? cafe_employee_list.data.total_items
        : 0
      : 0, // replace later with size(customers),
    custom: true,
  };

  const getAllRows = () => {
    const cafe_employee_list = [...employeeList];
    // couponList.unshift(searchBoxes);
    return cafe_employee_list;
  };

  if (props.viewPermission) {
    if (!loading) {
      return (
        <React.Fragment>
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
              Inventory CAFE Employees{" "}
            </Col>
            <Col lg={4} sm={8} xs={12}></Col>
            <div className="page-content ">
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between">
                        <Card.Title className="h4 mb-4 ml-auto">
                          All Employees
                        </Card.Title>

                        <Link
                          className="btn btn-warning mb-3 "
                          style={{ marginLeft: "1rem", marginRight: "1rem" }}
                          to={"/inventory/addCafeEmployees"}
                        >
                          Add New Employee
                        </Link>
                      </div>

                      {
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="cafe_inventory_login_id"
                          columns={columns}
                          data={getAllRows()}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="cafe_inventory_login_id"
                              columns={columns}
                              data={getAllRows()}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="cafe_inventory_login_id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <CafeEmployeeListPagination
                                      pageNum={Math.ceil(
                                        cafe_employee_list?.data
                                          ? cafe_employee_list?.data
                                              ?.total_items /
                                              cafe_employee_list.data?.per_page
                                          : 1
                                      )}
                                    />
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </React.Fragment>
      );
    } else return <Loader />;
  }
  return <Unauthorized />;
};

export default AllCafeEmployeesList;
