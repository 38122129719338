import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { UpdateCustomerRoles } from "../../store/actionCreators/Coupons/CouponsAction";

const EditCustomerRoles = ({ show, close, data }) => {
  const [customerRole, setCustomerRole] = useState({});
  // console.log(customerRole);

  const dispatch = useDispatch();
  useEffect(() => {
    setCustomerRole(data);
  }, [data]);

  const closeModal = () => {
    close();
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    dispatch(UpdateCustomerRoles(customerRole));
    close();
  };

  const onChangeFields = (field_name, value) => {
    const feild = { ...customerRole };
    feild[field_name] = value;
    setCustomerRole(feild);
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Customer Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitForm}>
          <div className="mb-2">
            <label>Customer Type</label>
            <input
              required
              type="text"
              className="form-control"
              disabled={true}
              value={customerRole.customer_type}
            />
          </div>
          <div className="mb-2">
            <label>Minimum Purchase</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.min_purchase}
              onChange={(e) => onChangeFields("min_purchase", e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Total days</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.total_days}
              onChange={(e) => onChangeFields("total_days", e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Reduce Purchase</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.reduce_purchase}
              onChange={(e) =>
                onChangeFields("reduce_purchase", e.target.value)
              }
            />
          </div>
          <div className="mb-2">
            <label>Upgrade Purchase</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.upg_purchase}
              onChange={(e) => onChangeFields("upg_purchase", e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Upgrade Days</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.upg_days}
              onChange={(e) => onChangeFields("upg_days", e.target.value)}
            />
          </div>
          <div className="mb-2">
            <label>Kitchen Discount Percent</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.kitchen_disc_perc}
              onChange={(e) =>
                onChangeFields("kitchen_disc_perc", e.target.value)
              }
            />
          </div>
          <div className="mb-2">
            <label>Barista Discount Percent</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.barista_disc_percent}
              onChange={(e) =>
                onChangeFields("barista_disc_percent", e.target.value)
              }
            />
          </div>
          <div className="mb-2">
            <label>Created At</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.createdAt}
              disabled={true}
            />
          </div>
          <div className="mb-2">
            <label>Updated At</label>
            <input
              required
              type="text"
              className="form-control"
              value={customerRole.updatedAt}
              disabled={true}
            />
          </div>

          <div>
            <Button className="btn btn-warning" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCustomerRoles;
