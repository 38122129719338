import axios from "axios";

export const getDailyInvestorReport = (query, page) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/daily_investor_report/${page}`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_DAILY_INVESTOR_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const getCustomInvestorReport = (query) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/custom_investor_report`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_CUSTOM_INVESTOR_REPORT",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
