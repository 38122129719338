import React, { useState, useEffect, createRef } from "react";
import { CurrencyRupeeIcon } from "@heroicons/react/outline";
import { Row, Col, Container, Button, NavLink } from "react-bootstrap";
import { useScreenshot } from "use-react-screenshot";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import Loader from "../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const SingleEmployeeLogoutReport = (props) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const [report, setReport] = useState({});
  // const [data, setdata] = useState([]);
  const ref = createRef(null);
  const [hover, setHover] = useState(false);
  const [image, takeScreenshot] = useScreenshot();
  // const [loading, setLoading] = useState(false);
  const getImage = () => takeScreenshot(ref.current);

  const logout_report_data = useSelector(
    (state) => state.single_employee_logout_report
  );

  const customStyle = {
    borderRadius: "20px",
    background: "#8e44ad40",
    overflow: "hidden",
    position: "relative",
    transform: hover ? `translateY(5px)` : `translateY(0px)`,
    transition: `transform ${250}ms`,
    borderLeft: "4px solid #8e44ad",
    cursor: "default",
  };

  useEffect(() => {
    setReport({});

    // setLoading(true);
  }, []);

  const toggleHover = () => {
    setHover(!hover);
  };

  const downloadImage = () => {
    saveAs(image, "image.jpg"); // Put your image url here.
  };

  useEffect(() => {
    if (image) downloadImage();
  }, [image]);

  useEffect(() => {
    if (logout_report_data.data?.status !== "success") {
      // setLoading(true);
    }
    if (logout_report_data.data?.status === "success") {
      // setLoading(false);
      // setdata(logout_report_data?.data?.data);
      setReport(logout_report_data?.data?.data[0]);
    }
  }, [logout_report_data]);

  // customStyle={
  //   hover
  //   ? style= {
  //       borderRadius: "20px",
  //       background: "#8e44ad40",
  //       overflow: "hidden",
  //       position: "relative",
  //       borderLeft: "4px solid #8e44ad",
  //       cursor: "default",
  //     }
  //   : style={
  //       borderRadius: "20px",
  //       background: "#8e44ad40",
  //       overflow: "hidden",
  //       position: "relative",
  //       transform: "translateY(5px)",
  //       transition: "transform 250ms",
  //       borderLeft: "4px solid #8e44ad",
  //       cursor: "default",
  //     }
  // }

  if (!logout_report_data?.loading)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "25px", backgroundColor: "#F1F5F7" }}
      >
        {/* {!logout_report_data?.data ? (
          <Loader />
        ) : ( */}
        <div className=" m-[5px] px-3" ref={ref}>
          <Row className="row justify-content-around flex-wrap gap-3 mb-3">
            <Col className="d-flex">
              <Button class="btn btn-primary mr-2" onClick={() => navigate(-1)}>
                Back
              </Button>

              <Button
                type="button"
                class="btn btn-warning my-auto"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  // console.log("Screenshot taken");
                  getImage();
                }}
              >
                Print
              </Button>
            </Col>
          </Row>
          {/* FIRST ROW */}

          <Row className="row justify-content-around flex-wrap gap-3">
            <Col
              onMouseLeave={toggleHover}
              onMouseEnter={toggleHover}
              style={customStyle}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#8e44ad",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Total Cash Collection
                </h3>
                <span
                  style={{
                    color: "rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.total_cash_collection * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#8e44ad40",
                overflow: "hidden",
                position: "relative",
                // transform: hover ? `translateY(5px)` : `translateY(0px)`,
                // transition: `transform ${250}ms`,
                borderLeft: "4px solid #8e44ad",
                cursor: "default",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#8e44ad",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Sole Cash sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.sole_cash_sales * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#8e44ad40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #8e44ad",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#8e44ad",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Cash+wallet
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.cash_plus_wallet * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* SECOND ROW SAME COLOR */}
          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#8e44ad40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #8e44ad",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#8e44ad",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Cash+card
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.cash_plus_card * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#8e44ad40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #8e44ad",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#8e44ad",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Wallet additions through cash
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.wallet_add_cash * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#8e44ad40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #8e44ad",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#8e44ad",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Petty cash in begining
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report ? Math.round(report.petty_cash * 100) / 100 || 0 : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          {/*THIRD ROW SECOND COLOR */}
          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#2980b940",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #2980b9",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#2980b9",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Total digital collection
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.total_digital_collection * 100) / 100 ||
                      0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>

            <Col
              style={{
                borderRadius: "20px",
                background: "#2980b940",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #2980b9",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#2980b9",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Sole card sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.sole_card_sales * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#2980b940",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #2980b9",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#2980b9",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Card+cash
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.card_plus_cash * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* FOURTH ROW SAMES COLOR */}
          <Row className="d-flex justify-content-around flex-wrap gap-3">
            <Col
              style={{
                borderRadius: "20px",
                background: "#2980b940",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #2980b9",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#2980b9",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Card+wallet
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.card_plus_wallet * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#2980b940",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #2980b9",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#2980b9",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Wallet additions through card
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.wallet_add_card * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* FIFTH ROW GREEN COLOR */}
          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#16a08540",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #16a085",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#16a085",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Total wallet collection
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.total_wallet_collection * 100) / 100 ||
                      0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>

            <Col
              style={{
                borderRadius: "20px",
                background: "#16a08540",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #16a085",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#16a085",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Wallet addition through cash
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.wallet_add_cash * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#16a08540",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #16a085",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#16a085",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Wallet addition through card
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.wallet_add_card * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>

          {/* SIXTH ROW GREEN COLOR */}
          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#16a08540",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #16a085",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#16a085",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Total wallet deduction/sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.total_wallet_deduction_sales * 100) /
                        100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#16a08540",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #16a085",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#16a085",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Sole Wallet Spends
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.sole_wallet_spends * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#66bb6a40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #66bb6a",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#66bb6a",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Wallet + Cash
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.wallet_plus_cash * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#66bb6a40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #66bb6a",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#66bb6a",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Wallet + Card
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.wallet_plus_card * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#66bb6a40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #66bb6a",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#66bb6a",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Total delievery partner sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.total_delievery_partner_sales * 100) /
                        100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* SEVENETH ROW COLOR CHANGE LIGHT GREEN */}
          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#66bb6a40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #66bb6a",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#66bb6a",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Total sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.total_sales * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#66bb6a40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #66bb6a",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#66bb6a",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Delievery charges
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.delievery_charges * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#66bb6a40",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #66bb6a",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#66bb6a",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Net total POS sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.net_total_pos_sales * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#ff704340",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #ff7043",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#ff7043",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Net total POS cash sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.net_total_pos_cash_sales * 100) / 100 ||
                      0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col
              style={{
                borderRadius: "20px",
                background: "#ff704340",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #ff7043",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#ff7043",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Net digital sales collection
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.net_digital_sales_collection * 100) /
                        100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#ff704340",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #ff7043",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#ff7043",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Net Status of Wallet
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.net_status_of_wallet * 100) / 100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
            <Col
              style={{
                borderRadius: "20px",
                background: "#ff704340",
                overflow: "hidden",
                position: "relative",
                borderLeft: "4px solid #ff7043",
              }}
              className="hoverable  px-4 py-3  d-flex justify-content-between align-items-center  relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out "
            >
              <div>
                <h3
                  style={{
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    textTransform: "uppercase",
                    textOpacity: 0.5,
                    color: "#ff7043",
                    lineHeight: "1",
                  }}
                  className="   uppercase leading-none mb-2"
                >
                  Net Total Delivery Sales
                </h3>
                <span
                  style={{
                    color: " rgba(0,0,0,.7)",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                  className="text-black font-weight-bold text-2xl"
                >
                  &#8377;{" "}
                  {report
                    ? Math.round(report.net_total_delievery_sales * 100) /
                        100 || 0
                    : ""}
                </span>
              </div>
              <div>
                <CurrencyRupeeIcon
                  style={{
                    height: "4rem",
                    width: "60px",
                    position: "absolute",

                    opacity: " 0.2",
                    right: "-16px",
                    top: "10px",
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* )} */}
      </Container>
    );
  return <Loader />;
};

export default SingleEmployeeLogoutReport;
