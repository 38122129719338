import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSinglePromo } from "../../../store/actionCreators/Coupons/CouponsAction";

const ViewPromo = () => {
  const promo = useSelector((state) => state.promo);
  const dispatch = useDispatch();
  const params = useParams();

  const [data, setData] = useState({
    coupon_code: "",
    title: "",
    description: "",
    min_amount: 0,
    extra_amount: 0,
    expiry_time: null,
    start_time: null,
    status: "",
  });

  useEffect(() => {
    dispatch(getSinglePromo(params.promo_id));
  }, []);

  useEffect(() => {
    setData(promo);
  }, [promo]);

  return (
    <div className="page-content ">
      {promo ? (
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <Card.Title className="h4 mb-4 my-auto">
                    View PromoCode Data
                  </Card.Title>
                </div>
                <Row className="mb-2">
                  <Col>
                    <label>Promo Code</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="coupon_code"
                      value={data.coupon_code}
                    />
                  </Col>
                  <Col>
                    <label>Title</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="title"
                      value={data.title}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <label>Start Time</label>
                    <input
                      disabled
                      type="date"
                      className="form-control"
                      id="start_time"
                      value={data.start_time}
                    />
                  </Col>
                  <Col>
                    <label>Expiry Time</label>
                    <input
                      disabled
                      type="date"
                      className="form-control"
                      id="expiry_time"
                      value={data.expiry_time}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <label>Min Amount</label>
                    <input
                      disabled
                      type="number"
                      className="form-control"
                      id="min_amount"
                      value={data.min_amount}
                    />
                  </Col>
                  <Col>
                    <label>Extra Amount</label>
                    <input
                      disabled
                      type="number"
                      className="form-control"
                      id="extra_amount"
                      value={data.extra_amount}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Description</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="description"
                      value={data.description}
                    />
                  </Col>

                  <Col>
                    <label>Status:</label>
                    <Select
                      value={{
                        label: String(data?.status),
                        value: data?.status,
                      }}
                      options={[
                        { label: "True", value: true },
                        { label: "False", value: false },
                      ]}
                      isDisabled={true}
                    />
                  </Col>
                  {/* <Col>
                  <label>Status</label>
                  <input
                    disabled
                    type="boolean"
                    className="form-control"
                    id="status"
                    value={data.status}
                  />
                </Col> */}
                </Row>

                {/* <Row className="d-flex">
                <div className="d-flex">
                  <Button
                    className="btn btn-warning ml-auto"
                    style={{ marginLeft: "auto" }}
                    onClick={handleAddCoupon}
                  >
                    Add Coupon
                  </Button>
                </div>
              </Row> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <div>PLease Wait..</div>
      )}
    </div>
  );
};

export default ViewPromo;
