import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { MdCreditScore } from "react-icons/md";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import UsedCredits from "./UsedCredits";
import AvailableCredits from "./AvailableCredits";
import EditCredit from "./EditCredit";

const Credits = (props) => {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Row>
        <Col>
          <Button className="my-2" onClick={() => navigate("/credits")}>
            Back to All Credits Section
          </Button>
        </Col>
      </Row>
      <Row className="mb-3 mt-3">
        <Col>
          <h5>CREDITS</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <NavLink to="available">
            <Card>
              <div className="p-3 py-4" style={{ display: "flex" }}>
                <MdCreditScore
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "0.5rem",
                  }}
                />
                <div className="my-auto">Available Credit</div>
              </div>
            </Card>
          </NavLink>
        </Col>
        <Col>
          <NavLink to="used">
            <Card>
              <div className="p-3 py-4" style={{ display: "flex" }}>
                <MdCreditScore
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "0.5rem",
                  }}
                />
                <div className="my-auto">Used Credit</div>
              </div>
            </Card>
          </NavLink>
        </Col>
      </Row>
      <Row>
        <Routes>
          <Route
            path="available"
            element={<AvailableCredits sideToggle={props.sideToggle} />}
          />
          <Route
            path="used"
            element={<UsedCredits sideToggle={props.sideToggle} />}
          />
          <Route
            path="used/edit/:id"
            element={<EditCredit sideToggle={props.sideToggle} />}
          />
          <Route
            path="available/edit/:id"
            element={<EditCredit sideToggle={props.sideToggle} />}
          />
        </Routes>
      </Row>
    </Container>
  );
};

export default Credits;
