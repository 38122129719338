import { toast } from "react-toastify";

export const identifiers = [
  "egg",
  "non-veg",
  "veg",
  "cake",
  "chef-special",
  "contains-pork",
  "dairy-free",
  "fodmap-friendly",
  "gluten-free",
  "lactose-free",
  "new-restaurant-recommended",
  "seasonal",
  "spicy",
  "vegan",
  "wheat-free",
  "mrp-item",
  "contains-alcohol",
  "cake-flavor-chocolate",
  "cake-flavor-black-forest",
  "cake-flavor-vanilla",
  "cake-flavor-fruit",
  "cake-flavor-pineapple",
  "cake-flavor-butterscotch",
  "cake-flavor-red-velvet",
  "cake-flavor-blueberry",
  "cake-flavor-cheesecake",
  "cake-flavor-strawberry",
  "cake-flavor-cream",
  "cake-flavor-mango",
  "anniversary-wedding-cake",
  "tiered-cake",
  "birthday-cake",
  "kids-birthday-cake",
  "gourmet-cake",
  "premium-cake",
  "home-style-meal",
  "services",
  "goods",
];

export const updateProduct = async function updateProduct(productId, data) {
  console.log(data,"BRO");
  const {
    product_display_name,
    zomato_category_id,
    stock,
    tags,
    additional_price,
   
  } = data;
  
  const url = `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/edit_product/${productId}`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_display_name,
        zomato_category_id:  zomato_category_id,
        stock: stock === 'true',
        tags,
        additional_price: parseInt(additional_price),
      }),
    });
    if (response.ok) {
      const result = await response.json();
      toast.success("Product updated successfully");
      return result;
    } else {
      throw new Error("Failed to update product.");
    }
  } catch (error) {
    console.error(error);
  }
};

