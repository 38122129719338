import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateAddonProduct } from "../../../store/actionCreators/Catalog/Catalog";
import { deleteAddonOption } from "../../../store/actionCreators/Catalog/Catalog";
import AddMoreAddons from "./AddMoreAddons";
import ShowAddonProducts from "./ShowAddonProducts";
import Select from "react-select";

const EditAddonModal = ({ show, close, data }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);
  const [visible, setVisible] = useState(true);

  console.log(data, "ggg");

  // now edit addon modal data
  const [showModal, setShowModal] = useState(false);
  const [showModalProducts, setShowModalProducts] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalProducts(false);
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const onChangeFields = (feild_name, value) => {
    const feild = { ...formData };
    feild[feild_name] = value;
    setFormData(feild);
  };

  const handleAddonChange = (feild_name, e) => {
    const arr = formData.add_on_options;
    arr.forEach((val) => {
      if (val.add_on_option_id === e.target.id) {
        val[feild_name] = e.target.value;
      }
    });
    setFormData({ ...formData, add_on_options: arr });
  };

  const onDelete = (id) => {
    dispatch(deleteAddonOption(id));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    dispatch(updateAddonProduct(formData));
  };
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Addon Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around m-2">
            <button
              type="button"
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-info "
            >
              <span>Add more Addon Options</span>
            </button>
            <AddMoreAddons
              show={showModal}
              close={handleCloseModal}
              data={data}
              setFormData={setFormData}
            />
            <button
              type="button"
              onClick={() => setShowModalProducts(true)}
              className="btn btn-info "
            >
              <span>Show Addon Products</span>
            </button>
            <ShowAddonProducts
              show={showModalProducts}
              close={handleCloseModal}
              data={data}
            />
          </div>
          <form onSubmit={onSubmitForm}>
            <div className="mb-2">
              <label>Addon Name</label>
              <input
                required
                type="text"
                className="form-control"
                value={formData.title}
                onChange={(e) => onChangeFields("title", e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Add on Type</label>
              {/* {formData.add_on_type!=="radio"?  onClick={(e)=>setType(e.target.value)}*/}
              <div className="mb-1 ">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    { label: "Radio", value: "radio" },
                    { label: "Checkbox", value: "checkbox" },
                  ]}
                  value={{
                    label: formData.add_on_type,
                    value: formData.add_on_type,
                  }}
                  onChange={(e) => {
                    console.log("add_on_type", e.value);
                    onChangeFields("add_on_type", e.value);
                  }}
                />
              </div>
            </div>
            <h5 style={{ fontWeight: "400" }}>Add On Options</h5>
            {formData.add_on_options.map((item) => {
              return (
                <Row key={item.add_on_option_id} className="gx-1 mb-0">
                  <Col lg={3} xl={3}>
                    <div className="p-2">
                      <label className="form-label">Title</label>
                      <input
                        name="title"
                        type="text"
                        className="form-control"
                        value={item.title}
                        required
                        id={item.add_on_option_id}
                        onChange={(e) => handleAddonChange("title", e)}
                      />
                    </div>
                  </Col>
                  <Col lg={3} xl={3}>
                    <div className="p-2">
                      <label className="form-label">Price</label>
                      <input
                        name="title"
                        type="text"
                        className="form-control"
                        value={item.price}
                        required
                        id={item.add_on_option_id}
                        onChange={(e) => handleAddonChange("price", e)}
                      />
                    </div>
                  </Col>
                  <Col lg={3} xl={3}>
                    <div className="p-2">
                      <label className="form-label">Order</label>
                      <input
                        name="title"
                        type="text"
                        className="form-control"
                        value={item.order}
                        required
                        id={item.add_on_option_id}
                        onChange={(e) => handleAddonChange("order", e)}
                      />
                    </div>
                  </Col>
                  <Col lg={3} xl={3} md={6} sm={12}>
                    <div className="p-2">
                      <label className="form-label">SKU</label>
                      <div className="d-flex ">
                        <input
                          name="title"
                          type="text"
                          className="form-control"
                          value={item.sku}
                          required
                          id={item.add_on_option_id}
                          onChange={(e) => handleAddonChange("sku", e)}
                        />
                        <button
                          type="button"
                          onClick={() => onDelete(item.add_on_option_id)}
                          className="btn btn-danger m-0 p-0 ms-3"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        >
                          <span className="font-bold text-2xl">-</span>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}

            <div>
              <Button className="btn btn-warning" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default EditAddonModal;
