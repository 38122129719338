export const CustomerByGroupReportReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CBG_REPORT":
      return state;
    case "SET_CBG_REPORT":
      return action.res;
    default:
      return state;
  }
};
export const CustomerSalesSummaryReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CSS_REPORT":
      return state;
    case "SET_CSS_REPORT":
      return action.res;
    default:
      return state;
  }
};
export const CustomerSalesOrderListReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CSOL_REPORT":
      return state;
    case "SET_CSOL_REPORT":
      return action.res;
    default:
      return state;
  }
};

export const CBGPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_CBG_PAGE":
      return state;
    case "SET_CBG_PAGE":
      return action.page;
    default:
      return state;
  }
};

export const CustomerListByGroupReportReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CLBG_REPORT":
      return state;
    case "SET_CLBG_REPORT":
      return action.res;
    default:
      return state;
  }
};
export const CustomerGroupChangeLogsReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CGCL_REPORT":
      return state;
    case "SET_CGCL_REPORT":
      return action.res;
    default:
      return state;
  }
};
export const CustomerWalletBalanceReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_CWB_REPORT":
      return state;
    case "SET_CWB_REPORT":
      return action.res;
    default:
      return state;
  }
};

export const CLBGPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_CLBG_PAGE":
      return state;
    case "SET_CLBG_PAGE":
      return action.page;
    default:
      return state;
  }
};

export const PerCustomerAnalysisReport = (state = "", action) => {
  switch (action.type) {
    case "GET_PCA_REPORT":
      return state;
    case "SET_PCA_REPORT":
      return action.res;
    default:
      return state;
  }
};

export const PerCustomerOrderList = (state = "", action) => {
  switch (action.type) {
    case "GET_PCOL":
      return state;
    case "SET_PCOL":
      return action.res;
    default:
      return state;
  }
};

export const PCOLPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_PCOL_PAGE":
      return state;
    case "SET_PCOL_PAGE":
      return action.page;
    default:
      return state;
  }
};
