import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getAllBranchId } from "../../../store/actionCreators/Employees/EmployeeAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const EditOutlets = (props) => {
  const dispatch = useDispatch();
  const AllBranchList = useSelector((state) => state.branches);
  const location = useLocation();
  const singleOutLetData = location?.state?.outletData;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    zomato_outlets_id: singleOutLetData
      ? singleOutLetData.zomato_outlets_id
      : "",
    api_key: singleOutLetData.api_key ? singleOutLetData.api_key : "",
    branch_id: singleOutLetData ? singleOutLetData.branch_id : "",
    name: singleOutLetData ? singleOutLetData.name : "",
    city: singleOutLetData ? singleOutLetData.city : "",
    region: singleOutLetData ? singleOutLetData.region : "",
    address: singleOutLetData ? singleOutLetData.address : "",
    latitude: singleOutLetData ? singleOutLetData.latitude : "",
    longitude: singleOutLetData ? singleOutLetData.longitude : "",
    contact: singleOutLetData ? singleOutLetData.contact : "",
  });

  useEffect(() => {
    dispatch(getAllBranchId());
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/${
      singleOutLetData
        ? "edit_outlet/" + singleOutLetData.zomato_outlets_id
        : "add_outlet"
    }`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "success") {
          toast.success(`outlet updated successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormData({
            zomato_outlets_id: "",
            api_key: "",
            branch_id: "",
            name: "",
            city: "",
            region: "",
            address: "",
            latitude: "",
            longitude: "",
            contact: "",
          });
        } else {
          toast.error(`${data?.msg}`);
        }

        navigate("/zomato/allOutlets");
        console.log(data);
        // Reset the form data after submission
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    console.log({ name, value }, "something");

    // If branch_id field is changed, find the branch data for the selected branch
    let branchData = null;
    if (name === "branch_id") {
      branchData = AllBranchList.data.data.find(
        (branch) => branch.branch_id === value
      );
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      name: branchData?.branch_name || prevFormData.name,
      address: branchData?.address || prevFormData.address,
      city: branchData?.city || prevFormData.city,
      contact: branchData?.contact || prevFormData.contact,
      email: branchData?.email || prevFormData.email,
      franchise_id: branchData?.franchise_id || prevFormData.franchise_id,
      latitude: branchData?.latitude || prevFormData.latitude,
      longitude: branchData?.longitude || prevFormData.longitude,
      region: branchData?.region || prevFormData.region,
      timing: branchData?.timing || prevFormData.timing,
    }));
  };

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <button className="btn btn-primary m-2 " onClick={() => navigate(-1)}>
        Back
      </button>
      <div className="row d-flex justify-content-center">
        <div className="form-container">
          <div className="form-head">Update Outlet</div>
          <div className="form-body">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Zomato Outlets ID</label>
                    <input
                      type="text"
                      className="form-control"
                      name="zomato_outlets_id"
                      value={formData.zomato_outlets_id}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          zomato_outlets_id: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>

                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">API KEY</label>
                    <input
                      type="text"
                      className="form-control"
                      name="api_key"
                      value={formData.api_key}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          api_key: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Branch Name</label>
                    <select
                      className="form-select"
                      value={formData.branch_id}
                      onChange={handleChange}
                      name="branch_id"
                      required
                    >
                      <option value="">Select Branch Name</option>
                      {(AllBranchList?.data?.data || []).map((branch) => (
                        <option key={branch.branch_id} value={branch.branch_id}>
                          {branch.branch_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>

                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={formData.city}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          city: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Region</label>
                    <input
                      type="text"
                      className="form-control"
                      name="region"
                      value={formData.region}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          region: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={formData.address}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          address: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Latitude</label>
                    <input
                      type="text"
                      className="form-control"
                      name="latitude"
                      value={formData.latitude}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          latitude: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Longitude</label>
                    <input
                      type="text"
                      className="form-control"
                      name="longitude"
                      value={formData.longitude}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          longitude: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3 p-2">
                    <label className="form-label">Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      name="contact"
                      value={formData.contact}
                      onChange={(event) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          contact: event.target.value,
                        }));
                      }}
                      required
                    />
                  </div>
                </Col>
              </Row>

              <button className="btn btn-primary " type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EditOutlets;
