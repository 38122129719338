import axios from "axios";
export const getOrderListLocation = (query, records, page) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/order_list_by_location/${page}?show=${records}`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_ORDER_LIST_LOCATION",
          res,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getItemwiseSalesSummary = (query, page) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/itemswise_sales/${page}`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_ITEMWISE_SALES",
          res,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setOLLPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_OLL_PAGE",
      page,
    });
  };
};
