import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleItem } from "../../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { getItemsUnits } from "../../../store/actionCreators/Inventory/InventoryAction";

const EditItem = ({ sideToggle }) => {
  const item = useSelector((state) => state.get_single_item);
  const allItemsUnit = useSelector((state) => state.get_items_units_list);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState({
    name: "",
    price: 0,
    unit: "",
    danger_zone: "",
    save_zone: "",
    cgst: "",
  });

  useEffect(() => {
    dispatch(getSingleItem(params.inventory_items_id));
    dispatch(getItemsUnits());
  }, []);

  useEffect(() => {
    setData(item);
  }, [item]);

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, price, unit,danger_zone, save_zone, cgst  } = data;

    const dataClone = {
      name,
      price: +price,
      unit,
      danger_zone: +danger_zone,
      save_zone: +save_zone,
      cgst: +cgst,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_item_list?inventory_items_id=${params.inventory_items_id}`,
        dataClone
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/allitems");
        } else {
          toast.error(`${res.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const itemsUnitsArray = allItemsUnit?.data?.data.map((item) => ({
    label: `${item.name}`,
    value: `${item.name}`,
  }));

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {item ? (
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Edit Item Details
                    </Card.Title>
                  </div>

                  <form onSubmit={onSubmit}>
                    <Row className="mb-2">
                      <Col>
                        <label>Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.name}
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Price:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          placeholder="0"
                          step="any"
                          value={data.price}
                          min={0}
                          onChange={(e) =>
                            setData({ ...data, price: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Danger Zone:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            placeholder="0"
                            min={0}
                            step="any" 
                            value={data.danger_zone}
                            onChange={(e) =>
                              setData({ ...data, danger_zone: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Safe Zone:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            placeholder="0"
                            min={0}
                            step="any" 
                            value={data.save_zone}
                            onChange={(e) =>
                              setData({ ...data, save_zone: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Tax:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            placeholder="0"
                            min={0}
                            step="any" 
                            value={data.cgst}
                            onChange={(e) =>
                              setData({ ...data, cgst: e.target.value })
                            }
                          />
                        </Col>
                      </Row>


                    <Row className="mb-2">
                      <Col>
                        <label>Item Unit:</label>
                        <Select
                          value={{ label: data.unit, value: data.unit }}
                          options={itemsUnitsArray}
                          onChange={(e) => setData({ ...data, unit: e.value })}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <Button type="submit" className="btn btn-warning">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default EditItem;
