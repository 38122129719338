import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import { toast } from "react-toastify";
import Select from "react-select";
import { BiArrowBack } from "react-icons/bi";
import { getSingleCafeRequest } from "../../../store/actionCreators/Inventory/InventoryAction";

const ViewCafeRequest = ({ sideToggle }) => {
  const cafeRequest = useSelector((state) => state.get_single_cafe_request);
  const allStatus = useSelector((state) => state.cafe_request_count);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState({
    status: "",
    file: null,
    cafe_msg: "",
  });

  const [file, setFile] = useState(null);

  const [cafeExchangeItem, setCafeExchangeItem] = useState([]);
  const [cafeRequestItem, setCafeRequestItem] = useState([]);

  useEffect(() => {
    dispatch(getSingleCafeRequest(params.cafe_request_id));
  }, []);

  useEffect(() => {
    setData(cafeRequest);
    setCafeExchangeItem(cafeRequest?.cafe_exchange_items || []);
    setCafeRequestItem(cafeRequest?.cafe_request_items || []);
  }, [cafeRequest]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
    setData({ ...data, file: file });
  };

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          {" "}
          <BiArrowBack className="mr-2" />
          Back
        </button>
        {cafeRequest ? (
          <Row>
            <Col className="col-12 mb-5">
              <Card>
                <Card.Body style={{ padding: "40px" }}>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Cafe Request Details
                    </Card.Title>
                  </div>

                  <Row className="mb-5">
                    <Card
                      style={{
                        background: "transparent",
                        width: "100%",
                        color: "#8d8d8d",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Card.Body>
                        <Row>
                          <Col>
                            {" "}
                            <strong>Cafe Request ID:</strong>
                            <span className="text-capitalize">
                              {data.cafe_request_id}
                            </span>
                          </Col>
                          <Col>
                            {" "}
                            <strong>Total Price :</strong>
                            &#8377;{data.total_price}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {" "}
                            <strong> Subtotal:</strong> &#8377;
                            {data.sub_total}
                          </Col>

                          <Col>
                            <strong>Discount:</strong>
                            &#8377;{data.discount}
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            {" "}
                            <strong>Tax: </strong>
                            &#8377;{data.tax}
                          </Col>

                          <Col>
                            {" "}
                            <strong>Status:</strong>
                            <div className="d-flex ">
                              <Select
                                value={{
                                  label: String(data.status),
                                  value: data.status,
                                }}
                                // options={allStatus.data.arr.map((i) => {
                                //   return {
                                //     label: i.status,
                                //     value: i.status,
                                //   };
                                // })}
                                // onChange={(e) =>
                                //   setData({ ...data, status: e.value })
                                // }
                                disabled
                              />
                              {/* <button
                                onClick={updatePurchaseOrder}
                                className="btn btn-warning mx-2"
                              >
                                Update
                              </button> */}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <div className="inputWrapper">
                            {/* <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="inputGroupFile01"
                                  aria-describedby="inputGroupFileAddon01"
                                  onChange={handleFileChange}
                                  accept="application/pdf"
                                  multiple={false}
                                />
                                <label
                                  className="custom-file-label font-weight-bold  border border-2 p-1 border-dark pe-auto"
                                  for="inputGroupFile01"
                                  style={{ cursor: "pointer" }}
                                >
                                  Choose file
                                </label>
                              </div>
                            </div> */}
                            <div>
                              <strong>Bill:</strong>{" "}
                              {!!file
                                ? `${file?.name} - ${file?.type}`
                                : `${data?.bill ? String(data.bill) : ""}`}
                            </div>
                            {!!data.bill && (
                              <button
                                className="btn-sm btn btn-primary"
                                onClick={() => window.open(data.bill)}
                              >
                                View
                              </button>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-start gap-2 mt-3">
                            {" "}
                            <strong>Cafe Msg: </strong>
                            <input
                              type="text"
                              className="form-control "
                              style={{ width: "400px" }}
                              name="cafe_msg"
                              value={data.cafe_msg}
                              onChange={(e) =>
                                setData({ ...data, cafe_msg: e.target.value })
                              }
                              disabled
                            />
                          </Col>
                          <Col className="d-flex justify-content-start gap-2 mt-3">
                            {" "}
                            <strong>Store Msg: </strong>
                            <input
                              type="text"
                              className="form-control "
                              style={{ width: "400px" }}
                              name="cafe_msg"
                              value={data.store_msg}
                              onChange={(e) =>
                                setData({ ...data, store_msg: e.target.value })
                              }
                              disabled
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>

                  <div>
                    <b className="mt-5 p-2 border border-top border-dark">
                      Cafe Request Items:
                    </b>
                    {cafeRequestItem.length === 0 ? (
                      <strong className="mx-3">EMPTY</strong>
                    ) : (
                      cafeRequestItem.map((item, i) => {
                        return (
                          <Card.Body key={i}>
                            <b className="fw-bold">
                              Order Id: {item.cafe_request_id}
                            </b>
                            <hr className="my-1" />
                            <div className="d-flex flex-column">
                              <table>
                                <tr></tr>
                                <th>Name:</th>
                                <th>Unit:</th>
                                <th>Tax:</th>
                                <th>Total Price :</th>

                                <th>Cafe msg:</th>
                                <th>Store msg:</th>
                                {/* <th>Status:</th> */}
                                {/* <th>Action:</th> */}
                                <tr>
                                  <td>{item.name}</td>

                                  <td>{item.unit}</td>

                                  <td>{item.tax}</td>

                                  <td>{item.total_price}</td>

                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      name="cafe_msg"
                                      value={item.cafe_msg}
                                      onChange={(e) =>
                                        setCafeExchangeItem((list) => {
                                          const updatedList = [...list];
                                          updatedList[i] = {
                                            ...updatedList[i],
                                            msg: e.target.value,
                                          };

                                          return updatedList;
                                        })
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      name="store_msg"
                                      value={item.store_msg}
                                      onChange={(e) =>
                                        setCafeExchangeItem((list) => {
                                          const updatedList = [...list];
                                          updatedList[i] = {
                                            ...updatedList[i],
                                            store_msg: e.target.value,
                                          };

                                          return updatedList;
                                        })
                                      }
                                      disabled
                                    />
                                  </td>
                                  {/* <td>
                            <Select
                              value={{
                                label: String(item.status),
                                value: item.status,
                              }}
                              options={allStatus.data.arr.map((i) => {
                                return {
                                  label: i.status,
                                  value: i.status,
                                };
                              })}
                              onChange={(e) =>
                                setCafeExchangeItem((list) => {
                                  const updatedList = [...list];
                                  updatedList[i] = {
                                    ...updatedList[i],
                                    status: e.value,
                                  };

                                  return updatedList;
                                })
                              }
                            />
                          </td> */}
                                  {/* <td>
                            <Button
                              type="submit"
                              className="btn btn-warning"
                              onClick={() =>
                                onUpdatePurchaseOrderItem(item)
                              }
                            >
                              Update
                            </Button>
                          </td> */}
                                </tr>
                              </table>
                            </div>
                          </Card.Body>
                        );
                      })
                    )}

                    <br />
                    <b className="mt-5 p-2 border border-top border-dark">
                      Cafe Exchange Items:
                    </b>
                    {cafeExchangeItem.length === 0 ? (
                      <strong className="mx-3">EMPTY</strong>
                    ) : 
                    (cafeExchangeItem.map((item, i) => {
                      return (
                        <Card.Body key={i}>
                          <b className="fw-bold">
                            Order Id: {item.cafe_request_id}
                          </b>
                          <hr className="my-1" />
                          <div className="d-flex flex-column">
                            <table>
                              <tr></tr>
                              <th>Name:</th>
                              <th>Unit:</th>
                              <th>Tax:</th>
                              <th>Total Price :</th>

                              <th>Note:</th>
                              {/* <th>Status:</th> */}
                              {/* <th>Action:</th> */}
                              <tr>
                                <td>{item.name}</td>

                                <td>{item.unit}</td>

                                <td>{item.tax}</td>

                                <td>{item.total_price}</td>

                                <td>
                                  {" "}
                                  <input
                                    type="text"
                                    name="cafe_msg"
                                    value={item.cafe_msg}
                                    onChange={(e) =>
                                      setCafeExchangeItem((list) => {
                                        const updatedList = [...list];
                                        updatedList[i] = {
                                          ...updatedList[i],
                                          msg: e.target.value,
                                        };

                                        return updatedList;
                                      })
                                    }
                                    disabled
                                  />
                                </td>
                                {/* <td>
                                <Select
                                  value={{
                                    label: String(item.status),
                                    value: item.status,
                                  }}
                                  options={allStatus.data.arr.map((i) => {
                                    return {
                                      label: i.status,
                                      value: i.status,
                                    };
                                  })}
                                  onChange={(e) =>
                                    setCafeExchangeItem((list) => {
                                      const updatedList = [...list];
                                      updatedList[i] = {
                                        ...updatedList[i],
                                        status: e.value,
                                      };

                                      return updatedList;
                                    })
                                  }
                                />
                              </td> */}
                                {/* <td>
                                <Button
                                  type="submit"
                                  className="btn btn-warning"
                                  onClick={() =>
                                    onUpdatePurchaseOrderItem(item)
                                  }
                                >
                                  Update
                                </Button>
                              </td> */}
                              </tr>
                            </table>
                          </div>
                        </Card.Body>
                      );
                    }))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default ViewCafeRequest;
