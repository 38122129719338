import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearDashBoard } from "../../../store/actionCreators/dashboard/dasboardActions";
import { setCustomerVars } from "../../../store/actionCreators/Customers/CustomerAction";

import AllOutlets from "../Outlets/AllOutlets";
import AddOutlets from "../Outlets/AddOutlets";
import EditOutlets from "../Outlets/EditOutlets";
import ViewOutlets from "../Outlets/ViewOutlets/ViewOutlets";

const ZomatoDashboard = (props) => {
  const branch = useSelector((state) => state.branch);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [branchObj, setBranchObj] = useState({});
  const [branchArray, setBranchArray] = useState([]);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const convertArrayToObj = () => {
    if (branch.data && branch.data.status === "success") {
      const branches = {};
      branch.data.data.forEach((e) => {
        branches[e.branch_id] = e.branch_name;
      });
      setBranchObj(branches);
      setBranchArray(branch.data.data);
    }
  };

  useEffect(() => {
    editPermissions();
    convertArrayToObj();
  }, [branch]);

  useEffect(() => {
    dispatch(setCustomerVars());
    dispatch(clearDashBoard());
  }, [viewPermission, editPermission]);

  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      admin_permissions.forEach((item) => {
        if (item.module === "Zomato") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/allOutlets"
          element={
            <AllOutlets
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />

        <Route
          path="/addOutlets"
          element={
            <AddOutlets
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />

        {/* <Route
          path="/addOutlets"
          element={
            <AddOutlets
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        /> */}
        <Route
          path="/allOutlets/editOutlet"
          element={
            <EditOutlets
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />
        <Route
          path="/allOutlets/:zomato_outlets_id"
          element={
            <ViewOutlets
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default ZomatoDashboard;
