import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useCallback } from "react";
import EditAddonModal from "../../AddCoupon/EditAddonModal";
import { useRef } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";

const EditAddonDiscountForm = ({
  branchArray,
  empArray,
  empObj,
  branchObj,
  step,
  setStep,
  data,
  setAddonsArr,
  productFullData,
  setProductFullData,
}) => {
  const dispatch = useDispatch();
  const [dataA, setDataA] = useState([]);
  const groups = useSelector((state) => state.groups);
  const productData = useSelector((state) => state.products);
  const syncedDataWithApi = useRef(false);

  const afterSaveCell = (row, cellName, cellValue) => {
    console.log({ row, cellName, cellValue }, "ccccc");
  };

  useEffect(() => {
    console.log("SMARTSHIVA - BUG!!!", { productData });
    setProductFullData(productData?.data?.data || []);
  }, [productData]);

  const [showModal, setShowModal] = useState(false);
  const [addonData, setAddonData] = useState({});

  useEffect(() => {
    if (!data || syncedDataWithApi.current) {
      return;
    }

    syncedDataWithApi.current = true;

    const selectedCouponsMap = (data?.coupon_products || []).reduce(
      (map, p) => ({ ...map, [p?.product_list_id]: p }),
      {}
    );

    setDataA((list) => {
      list = [...list];

      list.forEach((item, i) => {
        if (!!selectedCouponsMap[item?.product_list_id]) {
          list[i] = {
            ...list[i],
            ...selectedCouponsMap[item?.product_list_id],
            selected: true,
          };
        }
      });

      return list;
    });
    setProductFullData((list) => {
      list = [...list];

      list.forEach((item, i) => {
        if (!!selectedCouponsMap[item?.product_list_id]) {
          list[i] = {
            ...list[i],
            ...selectedCouponsMap[item?.product_list_id],
            selected: true,
          };
        }
      });

      return list;
    });
  }, [data?.coupon_products]);

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Form.Check
            checked={row?.selected}
            // checked={matchingProduct()}
            type="checkbox"
            onChange={(e) => {
              console.log(e.target.checked, "apiii");
              if (e.target.checked) {
                setAddonData({ ...row, index: row.index, selected: true });
                setShowModal(true);
              } else {
                setAddonData({ ...row, index: row.index, selected: false });
              }

              setProductFullData((list) => {
                list = [...list];
                list[row.index] = { ...list[row.index] };
                list[row.index].selected = e.target.checked;

                return list;
              });
            }}
          />
        </div>

        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          {/* <Button className="btn btn-sm btn-warning  ms-5">Edit Addons</Button> */}
        </div>
      </>
    );
  }
  const columns = [
    {
      dataField: "product_name",
      text: "Product Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      editable: true,
    },
    {
      dataField: "Select",
      text: "Select",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      editable: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "category_name",
      order: "asc",
    },
  ];
  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <div className="page-content ">
      <div className="mb-3">
        <strong>Addon Discount</strong>{" "}
      </div>
      <Row>
        <Col className="col-12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="product_list_id"
            columns={columns}
            data={productFullData}
            paginationProps={{
              sizePerPageList: [50, 100, 200],
            }}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="product_list_id"
                columns={columns}
                data={productFullData}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="search-box chat-search-box" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="product_list_id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            cellEdit={cellEditFactory({
                              blurToSave: true,
                              mode: "dbclick",
                              afterSaveCell: (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                dataA[row.index][column.dataField] = newValue;
                                setDataA([...dataA]);
                                console.log(
                                  { oldValue, newValue, row, column },
                                  "After Saving Cell!!"
                                );
                              },
                            })}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone
                            {...paginationProps}
                            sizePerPageList={[50, 100, 200]}
                          />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone
                            {...paginationProps}
                            className="table-pagination"
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
      <EditAddonModal
        show={showModal}
        close={() => setShowModal(false)}
        data={addonData}
        setAddonsArr={setAddonsArr}
        productFullData={productFullData}
        setProductFullData={setProductFullData}
      />
      <Button
        className=" mx-2"
        type="warning"
        onClick={() => {
          setStep(step - 1);
        }}
      >
        Back
      </Button>
      <Button
        className="m-2"
        type="warning"
        onClick={() => {
          setStep(5);
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default EditAddonDiscountForm;
