import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

// change the pagination into their own

const OutletProductPagination = ({ pageNum, page, filters, setFilters }) => {
  const [pageCount, setPageCount] = useState(pageNum);

  console.log(pageNum, "pageNuum");
  //   const page = useSelector((state) => state.store_list_pagination);
  //   const dispatch = useDispatch();
  useEffect(() => {
    setPageCount(pageNum);
  }, [pageNum]);

 

  const handlePageClick = (event) => {
    console.log(event);
    // dispatch(setStoreListPagination(event.selected + 1)); // const newOffset = (event.selected * itemsPerPage) % items.length;
    setFilters({
      ...filters,
      pageNumber: event.selected + 1,
    });
  };

  return (
    <div className="container ">
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={page - 1}
      />
    </div>
  );
};

export default OutletProductPagination;
