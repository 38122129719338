import React, { useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import { RiStackLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStoreList,
  getCountStoreRequestSender,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import { useState } from "react";
import StoreSentReqList from "./StoreSentReqList";
import Unauthorized from "../../unauthorized";

const StoreSentRequest = (props) => {
  const dispatch = useDispatch();
  const storeReqSenderCount = useSelector(
    (state) => state.count_store_req_sender
  );
  const allStoreList = useSelector((state) => state.get_all_stores);
  const [query, setQuery] = useState("");
  const [clickedData, setClickedData] = useState({});
  const [options, setOptions] = useState([]);
  const [storeValue, setCurrStoreValue] = useState("");
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    let array = [];
    if (allStoreList.data) {
      array = allStoreList.data.data.map((item, index) => {
        return { label: item.store_name, value: item.store_id };
      });
    } else array = [];
    setOptions(array);
    setCurrStoreValue(allStoreList?.data?.data[0]?.store_id);
    setStoreName(allStoreList?.data?.data[0]?.store_name);
  }, [allStoreList]);

  useEffect(() => {
    if (!!storeValue) {
      dispatch(getCountStoreRequestSender(storeValue));
    }
  }, [storeValue]);

  useEffect(() => {
    dispatch(getAllStoreList());
  }, []);

  const displayBranches = () => {
    return options.map((item, index) => {
      return (
        <Dropdown.Item key={index} eventKey={`${item?.label},${item?.value}`}>
          {" "}
          {item?.label}
        </Dropdown.Item>
      );
    });
  };

  const handleSelectBranch = (e) => {
    const value = e.split(",")[1];
    const name = e.split(",")[0];
    setStoreName(name);
    setCurrStoreValue(value);
  };


  if (props.viewPermission) {
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Col lg={8} sm={4} xs={12} className="inventory-dash-head ">
        STORE SENDER REQUESTS{" "}
        <div className="d-flex mt-2" style={{ gap: "12px" }}>
          <DropdownButton
            variant="light"
            title={storeName ? storeName : "Select Store"}
            id="dropdown-menu-align-right"
            onSelect={handleSelectBranch}
          >
            {displayBranches()}
          </DropdownButton>
        </div>
      </Col>
      <Row>
        {storeReqSenderCount.data &&
          storeReqSenderCount?.data?.arr.map((item, i) => (
            <Col
              lg
              sm={6}
              xs={6}
              key={i}
              style={{ cursor: "pointer", margin: "5px" }}
              onClick={() => {
                setQuery(item.status);
                setClickedData(item);
              }}
            >
              <Card className="dash-inv-card">
                <Card.Body>
                  <div className="d-flex">
                    <div className="flex-1">
                      <Card.Title className="card-title">
                        {item.status}
                      </Card.Title>
                      <Card.Text>{item.count}</Card.Text>
                    </div>
                    <div
                      className="text-primary icon-color"
                      style={{ fontSize: "24px" }}
                    >
                      <RiStackLine />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        {storeReqSenderCount?.data?.arr.length === 0 && (
          <strong> NO REQUEST FOUND</strong>
        )}
      </Row>
      <StoreSentReqList
        query={query}
        storeValue={storeValue}
        clickedData={clickedData}
        editPermission={props.editPermission}
      />
    </Container>
  );
} else {
  return <Unauthorized />;
}
};

export default StoreSentRequest;
