import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";

// Function to filter cities based on user input

const CitySelector = ({
  selectedCities,
  setSelectedCities,
  disableSelection,
  locations,
}) => {
  // Load all cities (replace with your actual list of cities)
  const allCities = [
    { value: "Agra", label: "Agra" },
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Ajmer", label: "Ajmer" },
    { value: "Akola", label: "Akola" },
    { value: "Aligarh", label: "Aligarh" },
    { value: "Allahabad", label: "Allahabad" },
    { value: "Alwar", label: "Alwar" },
    { value: "Ambala", label: "Ambala" },
    { value: "Amravati", label: "Amravati" },
    { value: "Amritsar", label: "Amritsar" },
    { value: "Anantapur", label: "Anantapur" },
    { value: "Arrah", label: "Arrah" },
    { value: "Asansol", label: "Asansol" },
    { value: "Aurangabad", label: "Aurangabad" },
    { value: "Avadi", label: "Avadi" },
    { value: "Bagaha", label: "Bagaha" },
    { value: "Bahraich", label: "Bahraich" },
    { value: "Bally", label: "Bally" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Baranagar", label: "Baranagar" },
    { value: "Barasat", label: "Barasat" },
    { value: "Bardhaman", label: "Bardhaman" },
    { value: "Bareilly", label: "Bareilly" },
    { value: "Bathinda", label: "Bathinda" },
    { value: "Begusarai", label: "Begusarai" },
    { value: "Belgaum", label: "Belgaum" },
    { value: "Bellary", label: "Bellary" },
    { value: "Berhampore", label: "Berhampore" },
    { value: "Bettiah", label: "Bettiah" },
    { value: "Bhagalpur", label: "Bhagalpur" },
    { value: "Bharatpur", label: "Bharatpur" },
    { value: "Bhatpara", label: "Bhatpara" },
    { value: "Bhavnagar", label: "Bhavnagar" },
    { value: "Bhilai", label: "Bhilai" },
    { value: "Bhilwara", label: "Bhilwara" },
    { value: "Bhind", label: "Bhind" },
    { value: "Bhiwandi", label: "Bhiwandi" },
    { value: "Bhiwani", label: "Bhiwani" },
    { value: "Bhopal", label: "Bhopal" },
    { value: "Bhubaneswar", label: "Bhubaneswar" },
    { value: "Bhusawal", label: "Bhusawal" },
    { value: "Bidar", label: "Bidar" },
    { value: "Bihar Sharif", label: "Bihar Sharif" },
    { value: "Bijapur", label: "Bijapur" },
    { value: "Bikaner", label: "Bikaner" },
    { value: "Bilaspur", label: "Bilaspur" },
    { value: "Bokaro Steel City", label: "Bokaro Steel City" },
    { value: "Brahmapur", label: "Brahmapur" },
    { value: "Bulandshahr", label: "Bulandshahr" },
    { value: "Burhanpur", label: "Burhanpur" },
    { value: "Buxar", label: "Buxar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chandrapur", label: "Chandrapur" },
    { value: "Chapra", label: "Chapra" },
    { value: "Chennai", label: "Chennai" },
    { value: "Chinsurah", label: "Chinsurah" },
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Cuttack", label: "Cuttack" },
    { value: "Danapur", label: "Danapur" },
    { value: "Davanagere", label: "Davanagere" },
    { value: "Dehradun", label: "Dehradun" },
    { value: "Dehri", label: "Dehri" },
    { value: "Delhi", label: "Delhi" },
    { value: "Deoghar", label: "Deoghar" },
    { value: "Dewas", label: "Dewas" },
    { value: "Dhanbad", label: "Dhanbad" },
    { value: "Dhule", label: "Dhule" },
    { value: "Durg", label: "Durg" },
    { value: "Durgapur", label: "Durgapur" },
    { value: "Eluru", label: "Eluru" },
    { value: "English Bazar", label: "English Bazar" },
    { value: "Erode", label: "Erode" },
    { value: "Etawah", label: "Etawah" },
    { value: "Faizabad", label: "Faizabad" },
    { value: "Faridabad", label: "Faridabad" },
    { value: "Faridkot", label: "Faridkot" },
    { value: "Farrukhabad", label: "Farrukhabad" },
    { value: "Fatehpur", label: "Fatehpur" },
    { value: "Firozabad", label: "Firozabad" },
    { value: "Gandhidham", label: "Gandhidham" },
    { value: "Gandhinagar", label: "Gandhinagar" },
    { value: "Gaya", label: "Gaya" },
    { value: "Ghaziabad", label: "Ghaziabad" },
    { value: "Giridih", label: "Giridih" },
    { value: "Gopalpur", label: "Gopalpur" },
    { value: "Gorakhpur", label: "Gorakhpur" },
    { value: "Gudivada", label: "Gudivada" },
    { value: "Gulbarga", label: "Gulbarga" },
    { value: "Guna", label: "Guna" },
    { value: "Guntakal", label: "Guntakal" },
    { value: "Guntur", label: "Guntur" },
    { value: "Gurgaon", label: "Gurgaon" },
    { value: "Guwahati", label: "Guwahati" },
    { value: "Gwalior", label: "Gwalior" },
    { value: "Haldia", label: "Haldia" },
    { value: "Haldwani", label: "Haldwani" },
    { value: "Hapur", label: "Hapur" },
    { value: "Hardoi", label: "Hardoi" },
    { value: "Haridwar", label: "Haridwar" },
    { value: "Hazaribagh", label: "Hazaribagh" },
    { value: "Hindupur", label: "Hindupur" },
    { value: "Hisar", label: "Hisar" },
    { value: "Hospet", label: "Hospet" },
    { value: "Howrah", label: "Howrah" },
    { value: "Hubli-Dharwad", label: "Hubli-Dharwad" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Ichalkaranji", label: "Ichalkaranji" },
    { value: "Imphal", label: "Imphal" },
    { value: "Indore", label: "Indore" },
    { value: "Jabalpur", label: "Jabalpur" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Jalandhar", label: "Jalandhar" },
    { value: "Jalgaon", label: "Jalgaon" },
    { value: "Jalna", label: "Jalna" },
    { value: "Jamalpur", label: "Jamalpur" },
    { value: "Jammu", label: "Jammu" },
    { value: "Jamnagar", label: "Jamnagar" },
    { value: "Jamshedpur", label: "Jamshedpur" },
    { value: "Jaunpur", label: "Jaunpur" },
    { value: "Jhansi", label: "Jhansi" },
    { value: "Jhunjhunu", label: "Jhunjhunu" },
    { value: "Jodhpur", label: "Jodhpur" },
    { value: "Junagadh", label: "Junagadh" },
    { value: "Kadapa", label: "Kadapa" },
    { value: "Kakinada", label: "Kakinada" },
    { value: "Kalyan-Dombivali", label: "Kalyan-Dombivali" },
    { value: "Kamarhati", label: "Kamarhati" },
    { value: "Kanpur", label: "Kanpur" },
    { value: "Karaikudi", label: "Karaikudi" },
    { value: "Karawal Nagar", label: "Karawal Nagar" },
    { value: "Karimnagar", label: "Karimnagar" },
    { value: "Karnal", label: "Karnal" },
    { value: "Katihar", label: "Katihar" },
    { value: "Katni", label: "Katni" },
    { value: "Kavali", label: "Kavali" },
    { value: "Khammam", label: "Khammam" },
    { value: "Khandwa", label: "Khandwa" },
    { value: "Khanna", label: "Khanna" },
    { value: "Kharagpur", label: "Kharagpur" },
    { value: "Khora", label: "Khora" },
    { value: "Kirari Suleman Nagar", label: "Kirari Suleman Nagar" },
    { value: "Kishanganj", label: "Kishanganj" },
    { value: "Kochi", label: "Kochi" },
    { value: "Kolhapur", label: "Kolhapur" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Kollam", label: "Kollam" },
    { value: "Korba", label: "Korba" },
    { value: "Kota", label: "Kota" },
    { value: "Kottayam", label: "Kottayam" },
    { value: "Kovilpatti", label: "Kovilpatti" },
    { value: "Kozhikode", label: "Kozhikode" },
    { value: "Kulti", label: "Kulti" },
    { value: "Kumbakonam", label: "Kumbakonam" },
    { value: "Kurnool", label: "Kurnool" },
    { value: "Latur", label: "Latur" },
    { value: "Loni", label: "Loni" },
    { value: "Lucknow", label: "Lucknow" },
    { value: "Ludhiana", label: "Ludhiana" },
    { value: "Machilipatnam", label: "Machilipatnam" },
    { value: "Madanapalle", label: "Madanapalle" },
    { value: "Madhyamgram", label: "Madhyamgram" },
    { value: "Madurai", label: "Madurai" },
    { value: "Mahbubnagar", label: "Mahbubnagar" },
    { value: "Maheshtala", label: "Maheshtala" },
    { value: "Malda", label: "Malda" },
    { value: "Malegaon", label: "Malegaon" },
    { value: "Mangalore", label: "Mangalore" },
    { value: "Mango", label: "Mango" },
    { value: "Mathura", label: "Mathura" },
    { value: "Mau", label: "Mau" },
    { value: "Meerut", label: "Meerut" },
    { value: "Mehsana", label: "Mehsana" },
    { value: "Mirzapur", label: "Mirzapur" },
    { value: "Moradabad", label: "Moradabad" },
    { value: "Morena", label: "Morena" },
    { value: "Motihari", label: "Motihari" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Munger", label: "Munger" },
    { value: "Muzaffarnagar", label: "Muzaffarnagar" },
    { value: "Muzaffarpur", label: "Muzaffarpur" },
    { value: "Mysore", label: "Mysore" },
    { value: "Nadiad", label: "Nadiad" },
    { value: "Nagaon", label: "Nagaon" },
    { value: "Nagapattinam", label: "Nagapattinam" },
    { value: "Nagpur", label: "Nagpur" },
    { value: "Naihati", label: "Naihati" },
    { value: "Nanded", label: "Nanded" },
    { value: "Nandyal", label: "Nandyal" },
    { value: "Nangloi Jat", label: "Nangloi Jat" },
    { value: "Nashik", label: "Nashik" },
    { value: "Navghar", label: "Navghar" },
    { value: "Navi Mumbai", label: "Navi Mumbai" },
    { value: "Nellore", label: "Nellore" },
    { value: "New Delhi", label: "New Delhi" },
    { value: "Nizamabad", label: "Nizamabad" },
    { value: "Noida", label: "Noida" },
    { value: "North Barrackpur", label: "North Barrackpur" },
    { value: "Ongole", label: "Ongole" },
    { value: "Orai", label: "Orai" },
    { value: "Osmanabad", label: "Osmanabad" },
    { value: "Pali", label: "Pali" },
    { value: "Pallavaram", label: "Pallavaram" },
    { value: "Panchkula", label: "Panchkula" },
    { value: "Panihati", label: "Panihati" },
    { value: "Panipat", label: "Panipat" },
    { value: "Panvel", label: "Panvel" },
    { value: "Parbhani", label: "Parbhani" },
    { value: "Patiala", label: "Patiala" },
    { value: "Patna", label: "Patna" },
    { value: "Pilibhit", label: "Pilibhit" },
    { value: "Pimpri-Chinchwad", label: "Pimpri-Chinchwad" },
    { value: "Pondicherry", label: "Pondicherry" },
    { value: "Proddatur", label: "Proddatur" },
    { value: "Pune", label: "Pune" },
    { value: "Purnia", label: "Purnia" },
    { value: "Purulia", label: "Purulia" },
    { value: "Raebareli", label: "Raebareli" },
    { value: "Raichur", label: "Raichur" },
    { value: "Raiganj", label: "Raiganj" },
    { value: "Raipur", label: "Raipur" },
    { value: "Rajahmundry", label: "Rajahmundry" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Rajpur Sonarpur", label: "Rajpur Sonarpur" },
    { value: "Ramagundam", label: "Ramagundam" },
    { value: "Rampur", label: "Rampur" },
    { value: "Ranchi", label: "Ranchi" },
    { value: "Ratlam", label: "Ratlam" },
    { value: "Raurkela", label: "Raurkela" },
    { value: "Rewa", label: "Rewa" },
    { value: "Rohtak", label: "Rohtak" },
    { value: "Roorkee", label: "Roorkee" },
    { value: "Rourkela", label: "Rourkela" },
    { value: "Sagar", label: "Sagar" },
    { value: "Saharanpur", label: "Saharanpur" },
    { value: "Saharsa", label: "Saharsa" },
    { value: "Salem", label: "Salem" },
    { value: "Sambalpur", label: "Sambalpur" },
    { value: "Sambhal", label: "Sambhal" },
    { value: "Sangli-Miraj & Kupwad", label: "Sangli-Miraj & Kupwad" },
    { value: "Satara", label: "Satara" },
    { value: "Satna", label: "Satna" },
    { value: "Secunderabad", label: "Secunderabad" },
    { value: "Serampore", label: "Serampore" },
    { value: "Shahjahanpur", label: "Shahjahanpur" },
    { value: "Shimla", label: "Shimla" },
    { value: "Shimoga", label: "Shimoga" },
    { value: "Shivpuri", label: "Shivpuri" },
    { value: "Sikar", label: "Sikar" },
    { value: "Siliguri", label: "Siliguri" },
    { value: "Singrauli", label: "Singrauli" },
    { value: "Sirsa", label: "Sirsa" },
    { value: "Sitapur", label: "Sitapur" },
    { value: "Siwan", label: "Siwan" },
    { value: "Solapur", label: "Solapur" },
    { value: "Sonipat", label: "Sonipat" },
    { value: "South Dumdum", label: "South Dumdum" },
    { value: "Sri Ganganagar", label: "Sri Ganganagar" },
    { value: "Srikakulam", label: "Srikakulam" },
    { value: "Srinagar", label: "Srinagar" },
    { value: "Sultan Pur Majra", label: "Sultan Pur Majra" },
    { value: "Surat", label: "Surat" },
    { value: "Surendranagar Dudhrej", label: "Surendranagar Dudhrej" },
    { value: "Suryapet", label: "Suryapet" },
    { value: "Tadepalligudem", label: "Tadepalligudem" },
    { value: "Tadipatri", label: "Tadipatri" },
    { value: "Tenali", label: "Tenali" },
    { value: "Tezpur", label: "Tezpur" },
    { value: "Thane", label: "Thane" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Thrissur", label: "Thrissur" },
    { value: "Tinsukia", label: "Tinsukia" },
    { value: "Tiruchirappalli", label: "Tiruchirappalli" },
    { value: "Tirunelveli", label: "Tirunelveli" },
    { value: "Tirupati", label: "Tirupati" },
    { value: "Tiruppur", label: "Tiruppur" },
    { value: "Tiruvottiyur", label: "Tiruvottiyur" },
    { value: "Tumkur", label: "Tumkur" },
    { value: "Udaipur", label: "Udaipur" },
    { value: "Udgir", label: "Udgir" },
    { value: "Ujjain", label: "Ujjain" },
    { value: "Ulhasnagar", label: "Ulhasnagar" },
    { value: "Unnao", label: "Unnao" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Varanasi", label: "Varanasi" },
    { value: "Vasai-Virar", label: "Vasai-Virar" },
    { value: "Vellore", label: "Vellore" },
    { value: "Veraval", label: "Veraval" },
    { value: "Vidisha", label: "Vidisha" },
    { value: "Vijayawada", label: "Vijayawada" },
    { value: "Visakhapatnam", label: "Visakhapatnam" },
    { value: "Vizianagaram", label: "Vizianagaram" },
    { value: "Warangal", label: "Warangal" },
    { value: "Wardha", label: "Wardha" },
    { value: "Yamunanagar", label: "Yamunanagar" },
    { value: "Yavatmal", label: "Yavatmal" },
    { value: "Yelahanka", label: "Yelahanka" },
    { value: "Yemmiganur", label: "Yemmiganur" },
    { value: "Yunao", label: "Yunao" },
    { value: "Zirakpur", label: "Zirakpur" },
  ];

  useEffect(() => {
    if (locations && locations.length > 0) {
      const selected = allCities.filter((city) =>
        locations.includes(city.value)
      );
 
      setSelectedCities(selected);
    }
  }, [locations, setSelectedCities]);

  const filterCities = (inputValue, callback) => {
    console.log(inputValue, "INPUT");
    const filteredCities = allCities.filter((city) =>
      city.value.toLowerCase().includes(inputValue.toLowerCase())
    );
    console.log(filteredCities, "filteredCities");
    callback(filteredCities);
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      filterCities(inputValue, callback);
    }, 1000);
  };

  const handleCityChange = (selectedOptions) => {
    setSelectedCities(selectedOptions);
  };

  return (
    <AsyncSelect
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      onChange={handleCityChange}
      placeholder="Select cities..."
      value={selectedCities}
      isDisabled={disableSelection}
    />
  );
};

export default CitySelector;
