import axios from "axios";

export const getSalesByBranchReport = (query) => {
  console.log("");
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/branch_sales`,
        query
      )
      .then((res) => {
        dispatch({
          type: "SET_SALES_BY_BRANCH",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getSalesByBranchDaily = (query) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/branch_sales_daily`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_SALES_BY_BRANCH_DAILY",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
