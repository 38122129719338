import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { addBranchInvestor } from "../../store/actionCreators/Investors/InvestorsAction";

const EditBranchInvestor = ({ show, close, data }) => {
  // const dispatch = useDispatch();
  const branch = useSelector((state) => state.branch);
  const [product, setProduct] = useState({});

  useEffect(() => {
    setProduct(data);
  }, [data]);

  const closeModal = () => {
    close();
  };

  const EditInvestorBranchApi = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/edit_branch_investor`,
        {
          investors_branch_id: product.investors_branch_id,
          branch_id: product.branch_id,
          investor_id: product.investor_id,
          percentage_cut: product.percentage_cut,
        }
      )
      .then(
        (response) => {
          console.log(response.data.data);
          if (response?.data?.status === "success") {
            toast.success(`Successfully Updated`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 4000);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const onChangeFields = (field_name, value) => {
    const feild = { ...product };
    feild[field_name] = value;
    setProduct(feild);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    EditInvestorBranchApi();
    close();
  };

  const displayBranches = () => {
    if (branch.data) {
      return branch.data.data.map((item, index) => {
        return (
          <Dropdown.Item
            key={item.branch_id}
            eventKey={`["${item.branch_name}","${item.branch_id}"]`}
          >
            {" "}
            {item.branch_name}
          </Dropdown.Item>
        );
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Investor Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitForm}>
          <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
            <div className="mb-2">
              <label>Percentage Cut</label>
              <input
                required
                type="number"
                className="form-control"
                value={product.percentage_cut}
                onChange={(e) =>
                  onChangeFields("percentage_cut", e.target.value)
                }
              />
            </div>

            <Button className="btn btn-warning" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditBranchInvestor;
