import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomerRoles } from "../../../store/actionCreators/Customers/CustomerRolesActions";
import { toast } from "react-toastify";
import axios from "axios";

const ExportReportCustomerRoles = () => {
  const dispatch = useDispatch();
  const customerRoleData = useSelector((state) => state.customer_roles);

  const customerRolesList = customerRoleData?.data?.all_customer_roles || [];

  const [data, setData] = useState("All");

  const onExportReport = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_customer`,
        {
          membership_type: data,
        }
      );

      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      }
      if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data.data;
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getAllCustomerRoles());
  }, []);

  const customerRolesOptions = [{ label: "All", value: "All" }].concat(
    customerRolesList.map((item, i) => {
      return {
        label: item.customer_type,
        value: item.customer_type,
      };
    })
  );

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">CUSTOMERS</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onExportReport}
          >
            Export
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row style={{ paddingBottom: "50px" }}>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body style={{ padding: "50px" }}>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>Select Customer Roles:</td>
                      <td>
                        <Select
                          value={{ label: data, value: data }}
                          options={customerRolesOptions}
                          onChange={(e) => {
                            setData(e.value);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ExportReportCustomerRoles;
