export const fetchOrderListLocation = (state = "", action) => {
  console.log();
  switch (action.type) {
    case "SET_ORDER_LIST_LOCATION":
      return action.res;
    case "GET_ORDER_LIST_LOCATION":
      return state;
    default:
      return state;
  }
};
export const fetchItemWiseSalesSummary = (state = "", action) => {
  // console.log();
  switch (action.type) {
    case "SET_ITEMWISE_SALES":
      return action.res;
    case "SET_ITEMWISE_SALES":
      return state;
    default:
      return state;
  }
};

export const OrderListLocationPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_OLL_PAGE":
      return state;
    case "SET_OLL_PAGE":
      return action.page;
    default:
      return state;
  }
};
