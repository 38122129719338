import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import { getAllCafeRequest } from "../../../store/actionCreators/Inventory/InventoryAction";
import AllCafeRequestListPagination from "./AllCafeRequestListPagination";

const AllCafeRequestList = (props) => {
  const { query, clickedData, branchValue } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cafeRequestList = useSelector((state) => state.cafe_request_list);
  const page = useSelector((state) => state.cafe_request_pagination);

  const [loading, setLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCafeRequest(page, query, branchValue));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCafeRequest(page, query, branchValue));
  }, [page, query, branchValue]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [cafeRequestList]);

  const setDefaultData = () => {
    let storeList = [];
    if (cafeRequestList.data && cafeRequestList.data.data) {
      storeList = cafeRequestList.data.data.map((c) => ({
        ...c,

        createdAt: new Date(`${c?.createdAt}`).toLocaleString(undefined, {
          timeZone: "Asia/Kolkata",
        }),
      }));
    }
    setStoreList(storeList);
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (props.viewPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() => navigate(`view-cafe-request/${row.cafe_request_id}`)}
            className="btn btn-sm btn-warning mx-2"
          >
            View
          </button>

          {/* <button
            onClick={() => navigate(`edit-cafe-request/${row.cafe_request_id}`)}
            className="btn btn-sm btn-warning mx-2"
          > Edit   </button> */}
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() => navigate(`view-cafe-request/${row.cafe_request_id}`)}
            className="btn btn-sm btn-warning mx-2"
          >
            View
          </button>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "cafe_request_id",
      text: "Cafe Request ID",
      sort: true,
    },
    {
      dataField: "store_name",
      text: "Store Name",
      sort: true,
    },
    {
      dataField: "branch_name",
      text: "Branch Name",
      sort: true,
    },
    {
      dataField: "total_price",
      text: "Total Price",
      sort: true,
    },
    {
      dataField: "sub_total",
      text: "Subtotal",
      sort: true,
    },

    {
      dataField: "tax",
      text: "Tax",
      sort: true,
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "cafe_msg",
      text: "Cafe Msg",
      sort: true,
    },
    {
      dataField: "order_status_history",
      text: "Order Status History",
      sort: true,
    },
    {
      dataField: "store_msg",
      text: "Store Message",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: cafeRequestList.data?.per_page,
    totalSize: cafeRequestList.data
      ? cafeRequestList.data.total_items
        ? cafeRequestList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const cafeRequestList = [...storeList];

    return cafeRequestList;
  };

  if (!loading) {
    return (
      <React.Fragment>
        <Container>
          <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
            All {clickedData?.status}
          </Col>

          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="store_id"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="store_id"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="store_id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                      {(storeList || []).length === 0 && (
                                        <strong>No Data Found</strong>
                                      )}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <AllCafeRequestListPagination
                                    pageNum={Math.ceil(
                                      cafeRequestList?.data
                                        ? cafeRequestList?.data?.total_items /
                                            cafeRequestList.data?.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  } else return <Loader />;
};

export default AllCafeRequestList;
