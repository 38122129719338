import axios from "axios";

export const getSalesByLocation = (query) => {
  console.log("");
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/sales_by_location`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_SALES_BY_LOCATION",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setSBLPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_SBL_PAGE",
      page,
    });
  };
};

export const getSalesByLocationDaily = (query, records, page) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/sales_by_location_daily/${page}?show=${records}`,
        query
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "SET_SALES_BY_LOCATION_DAILY",
          res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const setSBLDPagination = (page) => {
  return (dispatch, getSate) => {
    dispatch({
      type: "SET_SBLD_PAGE",
      page,
    });
  };
};
