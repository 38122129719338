import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
  CalendarIcon,
  ClipboardListIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import Unauthorized from "../../unauthorized";
import SalesByBranchReport from "./SalesByBranch";
import SalesByBranchDaily from "./SalesByBranchDaily";

const SalesReports = (props) => {
  const navigate = useNavigate();
  if (props.viewPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Row>
          <Col>
            <Button className="my-2" onClick={() => navigate("/reports")}>
              Back to All Reports Section
            </Button>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5 className="">SALES REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="salesbybranch">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Sales by Branch</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="salesbybranchdaily">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Sales by Branch (Daily)</div>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Routes>
            <Route
              path="salesbybranch"
              element={<SalesByBranchReport sideToggle={props.sideToggle} />}
            />
            <Route
              path="salesbybranchdaily"
              element={<SalesByBranchDaily sideToggle={props.sideToggle} />}
            />
          </Routes>
        </Row>
      </Container>
    );
  else return <Unauthorized />;
};

export default SalesReports;
