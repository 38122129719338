import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { getSingleInventoryEmployee } from "../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { toast } from "react-toastify";
import { getAllStoreList } from "../../store/actionCreators/Inventory/InventoryAction";
import { useMemo } from "react";

const EditInventoryEmployee = ({ sideToggle }) => {
  const [options, setOptions] = useState([]);
  const [selectedStores, setSelected] = useState([]);
  const [changePwd, setChangePwd] = useState(false);
  const [pwd, setPwd] = useState("");
  const employeeData = useSelector(
    (state) => state.get_single_inventory_employee
  );

  const allStoreList = useSelector((state) => state.get_all_stores);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    store_name: "",
    city: "",
    region: "",
    address: "",
    email: "",
    contact: "",
    timing: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    let array = [];
    if (allStoreList.data) {
      array = allStoreList.data.data.map((item, index) => {
        return { label: item.store_name, value: item.store_id };
      });
    } else array = [];
    setOptions(array);
  }, [allStoreList]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  useEffect(() => {
    dispatch(getSingleInventoryEmployee(params.inventory_login_id));
    dispatch(getAllStoreList());
  }, []);

  useEffect(() => {
    setData(employeeData);

    if (employeeData) {
      setSelected(
        (employeeData?.inventory_stores_logins || []).map((e) => ({
          label: e?.store.store_name,
          value: e?.store.store_id,
        }))
      );
    }
  }, [employeeData]);

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayStores = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Stores"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedStores}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  let storeString = useMemo(() => {
    let storeString = "";
    for (let i = 0; i < selectedStores.length; i++) {
      if (selectedStores[i].label === "All") {
        storeString = "";
        continue;
      } else {
        if (i === selectedStores.length - 1) {
          storeString = storeString + selectedStores[i].value;
        } else {
          storeString = storeString + selectedStores[i].value + ",";
        }
      }
    }
    return storeString;
  }, [selectedStores]);

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      store_id,
      address,
      password,
      active,
    } = data;

    const dataClone = {
      name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      store_id,
      address,

      active,
    };
    if (changePwd) dataClone.password = pwd;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_inventory_employee?inventory_login_id=${params.inventory_login_id}`,
        { ...dataClone, store_id: storeString }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/inventoryemployees");
        } else {
          toast.error(`${res.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {employeeData ? (
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Edit Employee Details
                    </Card.Title>
                  </div>

                  <form onSubmit={onSubmit}>
                    <Row className="mb-2">
                      <Col>
                        <label>Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.name}
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Mobile No:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          value={data.mobile_no}
                          onChange={(e) =>
                            setData({ ...data, mobile_no: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Email:</label>
                        <input
                          required
                          type="email"
                          className="form-control"
                          value={data.email}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>date_of_birth:</label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          value={data.date_of_birth}
                          onChange={(e) =>
                            setData({
                              ...data,
                              date_of_birth: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col>
                        <label>Gender:</label>
                        <Select
                          value={{ label: data.gender, value: data.gender }}
                          options={[
                            { label: "Male", value: "male" },
                            { label: "Female", value: "female" },
                          ]}
                          onChange={(e) =>
                            setData({ ...data, gender: e.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2 ">
                          <label className="form-label">Select Stores</label>
                          <div className="d-flex">
                            {displayStores()}
                            <span className="ms-3 p-2">{data.store}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Address:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.address}
                          onChange={(e) =>
                            setData({ ...data, address: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col>
                        <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                          <div className="mb-2">
                            <label>Change password ?</label>
                            <Form.Check
                              type="checkbox"
                              checked={changePwd}
                              onChange={() => {
                                setChangePwd(!changePwd);
                                setPwd("");
                              }}
                            />
                          </div>
                        </div>
                      </Col>{" "}
                      <Col>
                        <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                          <div className="mb-2">
                            <label>Password</label>
                            <input
                              required
                              type="password"
                              className="form-control"
                              disabled={!changePwd}
                              onChange={(e) => setPwd(e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <Button type="submit" className="btn btn-warning">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default EditInventoryEmployee;
