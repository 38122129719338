import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearDashBoard } from "../../../store/actionCreators/dashboard/dasboardActions";
import { setCustomerVars } from "../../../store/actionCreators/Customers/CustomerAction";

import AllOndcProducts from "../AllOndcProducts";
import AddOndcProduct from "../AddOndcProduct";
import EditOndcProduct from "../EditOndcProduct";

import OndcStoreList from "../Store/OndcStoreList";
import AddOndcStore from "../Store/AddOndcStore";
import EditOndcStore from "../Store/EditOndcStore";
import ViewOndcStore from "../Store/ViewOndcStore";
import SingleProductOptions from "../Store/singleProductOptions/SingleProductOptions";
// import EditOutlets from "../Outlets/EditOutlets";
// import ViewOutlets from "../Outlets/ViewOutlets/ViewOutlets";

const OndcDashboard = (props) => {
  const branch = useSelector((state) => state.branch);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [branchObj, setBranchObj] = useState({});
  const [branchArray, setBranchArray] = useState([]);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const convertArrayToObj = () => {
    if (branch.data && branch.data.status === "success") {
      const branches = {};
      branch.data.data.forEach((e) => {
        branches[e.branch_id] = e.branch_name;
      });
      setBranchObj(branches);
      setBranchArray(branch.data.data);
    }
  };

  useEffect(() => {
    editPermissions();
    convertArrayToObj();
  }, [branch]);

  useEffect(() => {
    dispatch(setCustomerVars());
    dispatch(clearDashBoard());
  }, [viewPermission, editPermission]);

  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      admin_permissions.forEach((item) => {
        if (item.module === "ONDC") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/allOndcVendor/ondcProducts"
          element={<AllOndcProducts />}
          sideToggle={props.sideToggle}
          viewPermission={viewPermission}
          editPermission={editPermission}
        />
        <Route
          path="/allOndcStores"
          element={<OndcStoreList />}
          sideToggle={props.sideToggle}
          viewPermission={viewPermission}
          editPermission={editPermission}
        />
        <Route
          path="/addOndcStore"
          element={
            <AddOndcStore
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />
        <Route
          path="/allOndcStores/edit_ondc_store/:ondc_store_id"
          element={
            <EditOndcStore
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />
        <Route
          path="/allOndcStores/view_ondc_store/:ondc_store_id"
          element={
            <ViewOndcStore
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />

        <Route
          path="/addOndcProduct"
          element={
            <AddOndcProduct
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
              branchObj={branchObj}
            />
          }
        />

        {/* <Route
          path="/addOutlets"
          element={
            <AddOutlets
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        /> */}
        <Route
          path="/allOndcStores/view_ondc_store/:ondcProductId/:product_list_id"
          element={
            <SingleProductOptions
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />
        <Route
          path="/allOndcProducts/editOndcProduct/:ondcProductId"
          element={
            <EditOndcProduct
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
              branchArray={branchArray}
            />
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default OndcDashboard;
