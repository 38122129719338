import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Button, Modal } from "react-bootstrap";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import { Link, useNavigate } from "react-router-dom";

const OndcStoreList = (props) => {
  // const { SearchBar } = Search;

  const [ondcStores, setOndcStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [storeToToggle, setStoreToToggle] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/ondc_store/get_all_ondc_stores?active=${activeStatus}`
      );
      const data = await response.json();
      setOndcStores(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching ONDC stores:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    // Simulating an API call to fetch data
    fetchData();
  }, [activeStatus]);

  const confirmToggleStatus = async () => {
    setConfirmationModalOpen(false);
    const activeStatus = ondcStores.find(
      (store) => store.ondc_store_id === storeToToggle
    ).active;

    console.log(storeToToggle)
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/ondc_store/act_status_ondc_store/${storeToToggle}?active=${!activeStatus}`,    
      );
      const updatedStores = ondcStores.map((store) => {
        if (store.ondc_store_id === storeToToggle) {
          return {
            ...store,
            active: !activeStatus,
          };
        }
        return store;
      });
      setOndcStores(updatedStores);
    } catch (error) {
      console.error("Error updating store status:", error);
    }
  };

  const rankFormatter = (cell, row, rowIndex, formatExtraData) => {
    const activeStatus = row?.active;
    const handleEditClick = (row) => {
      navigate(`edit_ondc_store/${row.ondc_store_id}`, { state: { row } });
    };
    const handleViewClick = (row) => {
      navigate(`view_ondc_store/${row.ondc_store_id}`, { state: { row } });
    };
    return (
      <div
        style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}
      >
        <button
          className="btn btn-sm btn-warning"
          style={{ marginLeft: "0.6rem" }}
          onClick={() => handleViewClick(row)}
        >
          View
        </button>

        <button
          className="btn btn-sm btn-warning"
          style={{ marginLeft: "0.6rem" }}
          onClick={() => handleEditClick(row)}
        >
          Edit
        </button>
        {/* <Button
          className="btn btn-sm btn-danger"
          style={{ marginLeft: "0.6rem" }}
        >
          Delete
        </Button> */}
        <Button
          variant={activeStatus ? "danger" : "success"}
          size="sm"
          onClick={() => toggleStoreStatus(row.ondc_store_id, activeStatus)}
          style={{ marginLeft: "0.6rem", color:'white' }}
        >
          {activeStatus ? "Inactive" : "Active"}
        </Button>
      </div>
    );
  };

  const toggleStoreStatus = (storeID) => {
    const storeToToggle = ondcStores.find(
      (store) => store.ondc_store_id === storeID
    );
    if (storeToToggle) {
      setStoreToToggle(storeID);
      setConfirmationModalOpen(true);
    }
  };

  const cancelToggleStatus = () => {
    setConfirmationModalOpen(false);
    setStoreToToggle(null);
  };

  const columns = [
    {
      dataField: "store_name",
      text: "Store Name",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: false,
    },
    {
      dataField: "region",
      text: "Region",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Contact",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => new Date(cell).toLocaleString(),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      isDummyField: true,
      csvExport: false,
      dataAlign: "center",
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "store_name",
      order: "asc",
    },
  ];

  const getAllRows = () => {
    return ondcStores;
  };

  const handleActiveStatusChange = (event) => {
    const newActiveStatus = event.target.value === "true";
    setActiveStatus(newActiveStatus);
  };

  if (loading) {
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Loader />
      </Container>
    );
  }

  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="page-content">
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="d-flex align-items-start">
                 

                    <select
                      className="form-select mx-2"
                      value={activeStatus.toString()}
                      onChange={handleActiveStatusChange}
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Link
                      className="btn btn-warning mb-3"
                      style={{ marginLeft: "1rem", marginRight: "1rem" }}
                      to={"/ondc/addOndcStore"}
                    >
                      Add Store
                    </Link>
                  </div>
                </div>

                <PaginationProvider
                  pagination={paginationFactory({ sizePerPage: 10 })}
                  keyField="store_name"
                  columns={columns}
                  data={getAllRows()}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="ondc_store_id"
                      columns={columns}
                      data={ondcStores || []}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes="table align-middle table-nowrap"
                                  headerWrapperClasses="thead-light"
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  noDataIndication={() => {
                                    return (
                                      <div className="text-center">
                                        <h4>No Store found</h4>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal show={confirmationModalOpen} onHide={cancelToggleStatus}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {ondcStores &&
          ondcStores.find((store) => store.ondc_store_id === storeToToggle)
            ?.active
            ? "deactivate"
            : "activate"}{" "}
          this store?
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={cancelToggleStatus}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmToggleStatus}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default OndcStoreList;
