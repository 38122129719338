import React, { useEffect, useState } from "react";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBranches } from "../../store/actionCreators/Branch/BranchAction";
import Unauthorized from "../unauthorized";
import AddNewInvestorNext from "./AddNewInvestorNext";
import AddInvestors from "./AddInvestors";
import axios from "axios";
import { useMemo } from "react";

const AddNewInvestorMain = (props) => {
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();
  const branch = useSelector((state) => state.branch);
  const [selectedBranches, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [newInvestor, setNewInvestor] = useState({
    name: "",
    gst_no: " ",
    contact: "",
    email: "",
    address: "",
    bank: "",
    ifsc: "",
    account_no: "",
    branch_id: "",
    percentage_cut: "",
    mail_monthly: null,
    mail_daily: null,
  });

  console.log(newInvestor, "ccc");
  useEffect(() => {
    dispatch(getBranches());
  }, []);
  useEffect(() => {
    let array = [];
    if (branch.data) {
      array = branch.data.data.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [branch]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    // if (value && value.some((o) => o.value === "*")) {
    //   return `${placeholderButtonLabel}: All`;
    // } else {
    return `${placeholderButtonLabel}: ${value.length} selected`;
    // }
  }
  function onChange(value, event) {
    console.log({ value, event }, "check");
    // if (event.action === "select-option" && event.option.value === "*") {
    //   this.setState(this.options);
    // } else if (
    //   event.action === "deselect-option" &&
    //   event.option.value === "*"
    // ) {
    //   this.setState([]);
    // } else

    if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayBranch = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  const percentChange = (index, value) => {
    selectedBranches[index] = {
      ...selectedBranches[index],
      percentage_cut: value,
    };
    setSelected([...selectedBranches]);
  };

  const handleChange = (entry) => (e) => {
    setNewInvestor({ ...newInvestor, [entry]: e.target.value });
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  // First next
  const nextStep = (e) => {
    if (selectedBranches.length > 0) {
      setStep(2);
    } else {
      toast.error(`please add branches`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    e.preventDefault();
  };

  //   sECOND FORM bACK
  const handleBackSecondForm = (e) => {
    setStep(1);
    e.preventDefault();
  };

  let { branchString, percentageCut } = useMemo(() => {
    let branchString = "",
      percentageCut = "";
    for (let i = 0; i < selectedBranches.length; i++) {
      if (i === selectedBranches.length - 1) {
        branchString = branchString + selectedBranches[i].value;
        percentageCut = percentageCut + selectedBranches[i].percentage_cut;
      } else {
        branchString = branchString + selectedBranches[i].value + ",";
        percentageCut =
          percentageCut + selectedBranches[i].percentage_cut + ",";
      }
    }

    return { branchString, percentageCut };
  }, [selectedBranches]);

  const handleFinalSubmit = (e) => {
    e.preventDefault();
    for (let i = 0; i < selectedBranches.length; i++) {
      let branch = selectedBranches[i];
      if (!branch.percentage_cut || !branch.percentage_cut.length) {
        toast.error(`please add percentage cut`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/investor/add_investor`,

        {
          ...newInvestor,
          branch_id: branchString,
          percentage_cut: percentageCut,
        }
      )
      .then(
        (response) => {
          console.log(response.data.data);
          if (response?.data?.status === "success") {
            toast.success(`Successfully Updated`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 4000);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  // render
  const main = () => {
    if (props.editPermission) {
      if (step === 1) {
        return (
          <>
            <AddInvestors
              sideToggle={props.sideToggle}
              setStep={setStep}
              newInvestor={newInvestor}
              handleChange={handleChange}
              nextStep={nextStep}
              displayBranch={displayBranch}
            />
          </>
        );
      } else if (step === 2) {
        return (
          <>
            <AddNewInvestorNext
              sideToggle={props.sideToggle}
              setStep={setStep}
              handleChange={handleChange}
              handleBackSecondForm={handleBackSecondForm}
              selectedBranches={selectedBranches}
              handleFinalSubmit={handleFinalSubmit}
              percentChange={percentChange}
            />
          </>
        );
      }
    }
    return <Unauthorized />;
  };
  return <div>{main()}</div>;
};

export default AddNewInvestorMain;
