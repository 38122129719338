import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  deleteGiftCard,
  getGiftList,
} from "../../../store/actionCreators/Coupons/CouponsAction";
import Select from "react-select";
import Loader from "../../Loader/LoaderTable";
import GiftCardPagination from "./GiftCardPagination";
import Unauthorized from "../../unauthorized";

const AllGiftCard = (props) => {
  const dispatch = useDispatch();
  const allGiftList = useSelector((state) => state.gift_cards_list);
  // const [page, setPage] = useState(1);
  const page = useSelector((state) => state.gift_card_pagination);

  console.log(page, "hoket");

  const [displayableCoupons, setDisplayableCoupons] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getGiftList(page));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getGiftList(page));
  }, [page]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [allGiftList]);

  const setDefaultData = () => {
    let giftList = [];
    if (allGiftList.data && allGiftList.data.data) {
      giftList = allGiftList.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
        branch_name: props.branchObj[c.branch_id],
        employee_name: props.empObj[c.employee_id],
      }));
    }

    setDisplayableCoupons(giftList);
  };

  const deleteFunction = (row) => {
    dispatch(deleteGiftCard(row.gift_card_id));
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewGiftCard/${row.gift_card_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`editGiftCard/${row.gift_card_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewGiftCard/${row.gift_card_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "created_by",
      text: "Created By",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allGiftList.data?.per_page,
    totalSize: allGiftList.data
      ? allGiftList.data.total_count
        ? allGiftList.data.total_count
        : 0
      : 0, // replace later with size(customers),
    custom: true,
  };

  const getAllRows = () => {
    const promoList = [...displayableCoupons];
    // couponList.unshift(searchBoxes);

    return promoList;
  };
  if (props.viewPermission)
    if (!loading) {
      return (
        <React.Fragment>
          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between">
                      <Card.Title className="h4 mb-4 ml-auto">
                        All Gift Cards
                      </Card.Title>

                      <Link
                        className="btn btn-warning mb-3 "
                        style={{ marginLeft: "1rem", marginRight: "1rem" }}
                        to={"/coupons/addGiftCard"}
                      >
                        Add New Gift Card
                      </Link>
                    </div>

                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="coupon_id"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="coupon_id"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="promo_code"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <GiftCardPagination
                                    pageNum={Math.ceil(
                                      allGiftList?.data
                                        ? allGiftList?.data?.total_count /
                                            allGiftList.data?.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      );
    } else return <Loader />;
  return <Unauthorized />;
};

export default AllGiftCard;
