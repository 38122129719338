import {
  Container,
  Row,
  Col,
  Card,
  Button,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import "../../css/dashboard.css";
import { RiStackLine } from "react-icons/ri";
import { RiStore2Line } from "react-icons/ri";
import { RiBriefcase4Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Unauthorized from "../unauthorized";
import Select from "react-select";
import MonthPicker from "../Dashboard/MonthPicker";
import { toast } from "react-toastify";
import {
  CustomerAnalyticsPie,
  CustomerDashboardAction,
} from "./../../store/actionCreators/Customers/CustomerAction";
import CustomerAnalytics from "./customerAnalytics";
import DoughnutGraph from "./customerDoughnut";
import CustomerMostOrder from "./customerMostOrder";

const CustomerDashboard = (props) => {
  const customer_dashboard = useSelector((state) => state.customer_dashboard);
  const salespie = useSelector((state) => state.customer_ananlytics_pie);
  const login = useSelector((state) => state.login);
  const [branchArray, setBranchArray] = useState(["All"]);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const dispatch = useDispatch();
  const currYear = new Date().getFullYear();
  const branch = useSelector((state) => state.branch);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [branches, setBranches] = useState([]);

  console.log(viewPermission,"viewPermission")

  useEffect(() => {
    if (branches) {
      setSelectedBranch(branches[0]);
    }
  }, []);
  useEffect(() => {
    if (branch.data && branch.data.status === "success") {
      const clone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      setBranches(clone);
      setSelectedBranch(clone[0]);
    }
  }, [branch]);

  useEffect(() => {
    if (selectedBranch.value) {
      console.log();
      callAPI({ ...filter });
    }
  }, [selectedBranch]);

  useEffect(() => {
    dispatch(CustomerDashboardAction());
  }, []);

  useEffect(() => {
    editPermissions();
  }, []);

  const reports = [
    {
      icon: RiStackLine,
      title: "Number of Sales",
      value: salespie?.data?.data?.net_order_count,
      rate: "2.4%",
      desc: "From previous period",
    },
    {
      icon: RiStore2Line,
      title: "Sales Revenue",
      value: `₹ ${salespie?.data?.data?.net_revenue_order}`,
      rate: "2.4%",
      desc: "From previous period",
    },
    {
      icon: RiBriefcase4Line,
      title: "Average Sales per Day",
      value: `₹ ${salespie?.data?.data?.net_average_order}`,
      rate: "2.4%",
      desc: "From previous period",
    },
  ];
  const random_hex_color_code = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6);
  };

  useEffect(() => {
    setDoughnutState({
      // series: [42, 26],
      series: salespie.data?.result?.rev,
      avg: salespie.data?.result?.order,
      options: {
        labels: salespie.data?.result?.memb,
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: (() => {
          const arr = [];
          if (salespie.data?.result?.memb)
            for (let i = 0; i < salespie.data?.result?.memb?.length; i++) {
              arr.push(random_hex_color_code());
            }
          return arr;
        })(),
      },
    });
  }, [salespie]);

  // doughnut Graph
  const [Doughnutstate, setDoughnutState] = useState({
    // series: [42, 26],
    series: salespie.data?.data?.rev,
    options: {
      labels: salespie.data?.data?.branch_name,
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: (() => {
        const arr = [];
        if (salespie.data?.data?.branch_name)
          for (let i = 0; i < salespie.data?.data?.branch_name?.length; i++) {
            arr.push(random_hex_color_code());
          }
        return arr;
      })(),
    },
  });

  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      admin_permissions.forEach((item) => {
        if (item.module === "Customer") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };

  const callAPI = (OB) => {
    console.log("API", { ...OB, branch_id: selectedBranch.value });
    dispatch(CustomerAnalyticsPie({ ...OB, branch_id: selectedBranch.value }));
  };
  const [value1, setValue1] = React.useState({ year: 2020, month: 9 });
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const monthPickerRef1 = React.useRef(null);
  const [modal, setModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("TODAY");
  const [year, setYear] = useState(new Date().getFullYear());
  const [filter, setFilter] = useState({ type: "TODAY" });
  const [displayMonthYear, setDisplayMonthYear] = useState(
    new Date().getFullYear()
  );
  const lang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    from: "From",
    to: "To",
  };
  const dic = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const filterObj = {
    TODAY: "Today",
    L7D: "Last 7 days",
    MONTH: "Month",
    YEAR: "Year",
    ALLYEARS: "All Years",
    CUSTOM: "Custom",
  };

  const years = function (startYear) {
    var currentYear = new Date().getFullYear();
    var years = [];
    var startYear = 2021;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
  }

  const customOnClick = () => {
    if (value1 === "" || value2 === "") {
      toast.error(`Please select a date range`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const val1 = new Date(value2);
    const val2 = new Date(value3);
    const months = monthDiff(val1, val2);
    if (Math.abs(months) > 3) {
      toast.error(`Range Should be within 3 months`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setFilter({ start: value2, end: value3, type: "CUSTOM" });
    callAPI({ start: value2, end: value3, type: "CUSTOM" });
    setDisplayMonthYear(`${value2} - ${value3}`);
    setModal(false);
  };

  const modalBody = {
    MONTH: (
      <div>
        <div className="d-flex mx-auto">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Month{" "}
          </label>
          <MonthPicker
            className="my-auto "
            lang={lang.months}
            ref={monthPickerRef1}
            value={value1}
            setMonth={(e) => setValue1({ year: e.year, month: e.month })}
          ></MonthPicker>
        </div>

        <div className="d-flex mt-3">
          <Button
            className="mx-auto"
            onClick={() => {
              setFilter({
                type: "MONTH",
                year: value1.year,
                month: value1.month,
              });
              callAPI({
                type: "MONTH",
                year: value1.year,
                month: value1.month,
              });
              setDisplayMonthYear(`${dic[value1.month]}, ${value1.year}`);
              setModal(false);
            }}
          >
            Show
          </Button>
        </div>
      </div>
    ),

    YEAR: (
      <div className="mx-auto">
        <div className="d-flex">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Year{" "}
          </label>
          <Select
            value={{ label: year, value: year }}
            onChange={(e) => setYear(e.value)}
            options={[...years().map((y) => ({ label: y, value: y }))]}
          />
        </div>
        <div className="d-flex mt-3">
          <Button
            className="mx-auto"
            onClick={() => {
              setFilter({ type: "YEAR", year });
              callAPI({ type: "YEAR", year });
              setDisplayMonthYear(year);
              setModal(false);
            }}
          >
            Show
          </Button>
        </div>
      </div>
    ),

    CUSTOM: (
      <div>
        <div style={{ display: "flex" }} className="mx-auto">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Range{" "}
          </label>
          <input
            type="date"
            value={value2}
            onChange={(e) => setValue2(e.target.value)}
          />
          -
          <input
            type="date"
            value={value3}
            onChange={(e) => setValue3(e.target.value)}
          />
        </div>
        <div className="d-flex mt-3">
          <Button className="mx-auto" onClick={() => customOnClick()}>
            Show
          </Button>
        </div>
      </div>
    ),
  };
  if (viewPermission) {
    return (
      <React.Fragment>
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Row>
            <Modal onHide={() => setModal(false)} show={modal}>
              <Modal.Header closeButton>
                <Modal.Title>Select Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex">{modalBody[selectedFilter]}</div>
              </Modal.Body>
            </Modal>
            <Col lg={8} sm={6} xs={12} className="dash-head">
              CUSTOMER DASHBOARD
            </Col>
            <Col lg={4} sm={6} xs={12}></Col>
          </Row>
          <Row className="mb-3 text-center">
            <Col>
              <Card>
                <Card.Header>Total Customer</Card.Header>
                <Card.Body>
                  {Number.parseFloat(
                    Math.round(customer_dashboard?.data?.tota_customer * 100) /
                      100
                  ).toLocaleString()}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>Wallet Balance</Card.Header>
                <Card.Body>
                  ₹
                  {Number.parseFloat(
                    Math.round(customer_dashboard?.data?.wallet_balance * 100) /
                      100
                  ).toLocaleString()}
                </Card.Body>
              </Card>
            </Col>
            
          </Row>
          <Row className="mb-3 text-center">
            <Col>
              <Card>
                <Card.Header>
                  {customer_dashboard?.data?.data[0]
                    ? customer_dashboard.data.data[0].customer_type
                    : ""}
                </Card.Header>
                <Card.Body>
                  {customer_dashboard?.data?.data[0]
                    ? Number.parseFloat(
                        Math.round(
                          customer_dashboard.data.data[0].total_customers * 100
                        ) / 100
                      ).toLocaleString()
                    : ""}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  {customer_dashboard?.data?.data[1]
                    ? customer_dashboard.data.data[1].customer_type
                    : ""}
                </Card.Header>
                <Card.Body>
                  {customer_dashboard?.data?.data[1]
                    ? Number.parseFloat(
                        Math.round(
                          customer_dashboard.data.data[1].total_customers * 100
                        ) / 100
                      ).toLocaleString()
                    : ""}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  {customer_dashboard?.data?.data[2]
                    ? customer_dashboard.data.data[2].customer_type
                    : ""}
                </Card.Header>
                <Card.Body>
                  {customer_dashboard?.data?.data[2]
                    ? Number.parseFloat(
                        Math.round(
                          customer_dashboard.data.data[2].total_customers * 100
                        ) / 100
                      ).toLocaleString()
                    : ""}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  {customer_dashboard?.data?.data[3]
                    ? customer_dashboard.data.data[3].customer_type
                    : ""}
                </Card.Header>
                <Card.Body>
                  {customer_dashboard?.data?.data[3]
                    ? Number.parseFloat(
                        Math.round(
                          customer_dashboard.data.data[3].total_customers * 100
                        ) / 100
                      ).toLocaleString()
                    : ""}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="8">
              <CustomerAnalytics />
            </Col>
            <Col lg="4">
              <Row
                className="d-flex mb-3"
                style={{ paddingLeft: "0.75rem", paddingRight: "0.0rem" }}
              >
                <Col className="my-auto" style={{ display: "contents" }}>
                  <div className="my-auto ml-auto">{displayMonthYear}</div>
                </Col>
                <Col>
                  <div className="mr-2 ">
                    <Select
                      options={[
                        { label: "Today", value: "TODAY" },
                        { label: "Last 7 days", value: "L7D" },
                        { label: "Month", value: "MONTH" },
                        { label: "Year", value: "YEAR" },
                        { label: "All Years", value: "ALLYEARS" },
                        { label: "Custom", value: "CUSTOM" },
                      ]}
                      value={{
                        value: selectedFilter,
                        label: filterObj[selectedFilter],
                      }}
                      onChange={(e) => {
                        if (
                          e.value === "MONTH" ||
                          e.value === "YEAR" ||
                          e.value === "CUSTOM"
                        ) {
                          setModal(true);
                        } else {
                          if (e.value != "ALLYEARS")
                            setDisplayMonthYear(
                              `${
                                dic[new Date().getMonth()]
                              }, ${new Date().getFullYear()}`
                            );
                          else {
                            setDisplayMonthYear(`All`);
                          }
                          setFilter({ type: e.value });
                          callAPI({ type: e.value });
                        }
                        setSelectedFilter(e.value);
                      }}
                    />
                  </div>
                  <div>
                    <Select
                      options={branches}
                      value={selectedBranch}
                      onChange={(e) => {
                        setSelectedBranch(e);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Card
                    style={{
                      width: "100%",
                      boxShadow: " 0 2px 4px rgb(0 0 0 / 8%)",
                    }}
                  >
                    <Card.Body>
                      <DoughnutGraph
                        options={Doughnutstate?.options}
                        series={Doughnutstate?.series}
                        avg={Doughnutstate?.avg}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 px-3">
            <CustomerMostOrder />
          </Row>
        </Container>
      </React.Fragment>
    );
  }
  return <Unauthorized />;
};
export default CustomerDashboard;
