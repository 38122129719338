import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AllNotificationsList from "./AllNotificationsList";
import AddNotification from "./AddNotification";
import EditNotification from "./EditNotification";
import ViewNotification from "./ViewNotification";

const NotificationDashboard = (props) => {
  const login = useSelector((state) => state.login);
  const employees = useSelector((state) => state.employees);
  const branch = useSelector((state) => state.branch);
  const groups = useSelector((state) => state.groups);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [empObj, setEmpObj] = useState({});
  const [empArray, setEmpArray] = useState([]);
  const [branchObj, setBranchObj] = useState({});
  const [branchArray, setBranchArray] = useState([]);
  const [groupsArr, setGroupsArr] = useState([]);

  const convertArrayToObj = () => {
    if (employees.data && employees.data.status === "success") {
      const emps = {};
      employees.data.data.forEach((e) => {
        emps[e.employee_id] = e.full_name;
      });
      setEmpObj(emps);
      setEmpArray(employees.data.data);
    }
    if (branch.data && branch.data.status === "success") {
      const branches = {};
      branch.data.data.forEach((e) => {
        branches[e.branch_id] = e.branch_name;
      });
      setBranchObj(branches);
      setBranchArray(branch.data.data);
    }
    if (groups.data && groups.data.data) {
      setGroupsArr(groups.data.data.map((g) => g.customer_group_name));
    }
  };

  useEffect(() => {
    editPermissions();
    convertArrayToObj();
  }, [employees, branch]);

  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      console.log(admin_permissions, "admin_permissions");
      admin_permissions.forEach((item) => {
        if (item.module === "Notifications") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <AllNotificationsList
              viewPermission={viewPermission}
              editPermission={editPermission}
              sideToggle={props.sideToggle}
            />
          }
        />
      </Routes>

      <Routes>
        <Route
          path="/add_notification/*"
          element={
            <AddNotification
              viewPermission={viewPermission}
              editPermission={editPermission}
              sideToggle={props.sideToggle}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/view_notification/:notifications_id"
          element={
            <ViewNotification
              viewPermission={viewPermission}
              editPermission={editPermission}
              sideToggle={props.sideToggle}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/edit_notification/:notifications_id"
          element={
            <EditNotification
              viewPermission={viewPermission}
              editPermission={editPermission}
              sideToggle={props.sideToggle}
            />
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default NotificationDashboard;
