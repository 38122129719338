import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import {
  fetchStoreStock,
  getAllStoreList,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import StoreStockPagination from "./StoreStockPagination";
import { toast } from "react-toastify";
import axios from "axios";
import cellEditFactory from "react-bootstrap-table2-editor";
import { setStoreStockPagination } from "../../../store/actionCreators/Inventory/InventoryAction";
import { useRef } from "react";
import UpdateQuantityModal from "../CafeStock/UpdateQuantityModal";
import Unauthorized from "../../unauthorized";

const StoreStockList = (props) => {
  const dispatch = useDispatch();
  const storeStockList = useSelector((state) => state.get_store_stock);
  const page = useSelector((state) => state.store_stock_pagination);
  const allStoreList = useSelector((state) => state.get_all_stores);
  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);
  const [itemsListPaginatedData, setItemsListPaginatedData] = useState();

  const firstObj = allStoreList?.data?.data[0] || {};

  const initialBranchValue = Object.values(firstObj || {});
  const initialBranchId = Object.values(firstObj || {});

  const [storeName, setStoreName] = useState(initialBranchId[1]);
  const [storeId, setStoreId] = useState(initialBranchValue[0]);

  const [searchTerm, setSearchTerm] = useState("");

  // states for modal
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    setLoading(true);

    dispatch(getAllStoreList());
    if (!!allStoreList) {
      dispatch(fetchStoreStock(page, storeId));
    }
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(fetchStoreStock(page, storeId));
    } else {
      onSearchData(page, storeId, searchTerm);
    }
  }, [page, storeId]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [storeStockList]);

  const setDefaultData = () => {
    let itemsList = [];
    if (storeStockList.data && storeStockList.data.data) {
      itemsList = storeStockList.data.data.map((c) => ({
        ...c,
        createdAt: c?.inventory_item?.createdAt.toLocaleString().split("T")[0],
        name: c?.inventory_item?.name,
        price: c?.inventory_item?.price,
        unit: c?.inventory_item?.unit,
      }));
    }
    setItemsList(itemsList);
    setItemsListPaginatedData(storeStockList.data);
  };

  const updateQty = (data) => {
    const { store_id, inventory_items_id, quantity } = data;

    const dataClone = {
      store_id,
      inventory_items_id,
      quantity: +quantity,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_invnetory_stock`,
        dataClone
      )
      .then((res) => {
        if (res.data.status === "success") {
          setShowModal(false);
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${res.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() => {
              setShowModal(!showModal);
              setRowData(row);
            }}
            className="btn btn-sm btn-warning"
          >
            Update
          </button>
        </div>
      );
    }
    
    // else {
    //   return (
    //     <div
    //       style={{
    //         textAlign: "center",
    //         cursor: "pointer",
    //         lineHeight: "normal",
    //       }}
    //     >
    //       <button className="btn btn-sm btn-warning">Update</button>
    //     </div>
    //   );
    // }
  }

  const columns = [
    {
      dataField: "inventory_items_id",
      text: "Inventory Item Id",
      sort: true,
      editable: false,
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      editable: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "unit",
      text: "Unit",
      sort: true,
      editable: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      editable: false,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
      editable: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: storeStockList.data?.per_page,
    totalSize: storeStockList.data
      ? storeStockList.data.total_items
        ? storeStockList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const displayStores = () => {
    if (allStoreList.data) {
      return allStoreList.data.data.map((item, index) => {
        return (
          <Dropdown.Item
            key={item.store_id}
            eventKey={`${item.store_name},${item.store_id}`}
          >
            {item.store_name}
          </Dropdown.Item>
        );
      });
    }
  };

  const handleStoreChange = (e) => {
    const value = e.split(",")[1];
    const name = e.split(",")[0];
    setStoreId(value);
    setStoreName(name);
  };

  const onSearchData = (page, storeId, value) => {
    setSearchTerm(value);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/search_inventory_stock/${page}?store_id=${storeId}&search=${value}`
      )
      .then((res) => {
        console.log(res.data.status);
        const data = res?.data?.data;
        const itemsList = (data || []).map((c) => ({
          ...c,
          createdAt: c?.inventory_item?.createdAt
            .toLocaleString()
            .split("T")[0],
          name: c?.inventory_item?.name,
          price: c?.inventory_item?.price,
          unit: c?.inventory_item?.unit,
        }));
        if (res?.data?.status === "failure") {
          setItemsList([]);
          dispatch(setStoreStockPagination(1));
        }
        else{
        setItemsList(itemsList);
        setItemsListPaginatedData(res.data);}
      })
      .catch((err) => {
        console.error(err);
      }).finally(()=>{
        setLoading(false)
      });;
  };



  const MySearch = (props) => {
    const input = useRef();
    const handleClick = (e) => {
      e.preventDefault();
      props.onSearch(input.current.value);
      onSearchData(page, storeId, input.current.value);
    };
    return (
      <form onSubmit={handleClick} className="d-flex gap-2">
        <input
          className="form-control"
          style={{ backgroundColor: "none" }}
          ref={input}
          type="text"
        />
        <button className="btn btn-warning">Search</button>
      </form>
    );
  };


  
  if (props.viewPermission) {
  if (!loading) {
    return (
      <React.Fragment>
        <Container
          fluid
          className={props.sideToggle === true ? "closeDash" : "openDash"}
          style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
        >
          <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
            Inventory Store Stock{" "}
          </Col>
          <Col lg={4} sm={8} xs={12}></Col>
          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between mb-2">
                      <div
                        className="d-flex justify-content-between "
                        style={{ gap: "12px" }}
                      >
                        <Card.Title>Store Stock</Card.Title>

                        <DropdownButton
                          variant="light"
                          title={storeName}
                          id="dropdown-menu-align-right"
                          onSelect={handleStoreChange}
                        >
                          {displayStores()}
                        </DropdownButton>
                      </div>
                    </div>

                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="inventory_items_id3"
                        columns={columns}
                        data={itemsList}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="inventory_items_id3"
                            columns={columns}
                            data={itemsList}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <MySearch
                                          {...toolkitProps.searchProps}
                                        />

                                        <i className="search-box chat-search-box" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="inventory_items_id3"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        cellEdit={cellEditFactory({
                                          blurToSave: true,
                                          mode: "dbclick",
                                          afterSaveCell: (
                                            oldValue,
                                            newValue,
                                            row,
                                            column
                                          ) => {
                                            console.log(
                                              {
                                                oldValue,
                                                newValue,
                                                row,
                                                column,
                                              },
                                              "After Saving Cell!!"
                                            );
                                          },
                                        })}
                                      />

                                      <UpdateQuantityModal
                                        showModal={showModal}
                                        close={() => setShowModal(false)}
                                        data={rowData}
                                        updateQty={updateQty}
                                        setRowData={setRowData}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <StoreStockPagination
                                    pageNum={Math.ceil(
                                      itemsListPaginatedData
                                        ? itemsListPaginatedData.total_items /
                                            itemsListPaginatedData.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    );
  } else
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {" "}
        <Loader />{" "}
      </Container>
    );
  } else {
    return <Unauthorized />;
    }
};

export default StoreStockList;
