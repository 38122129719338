export const singleCreditReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SINGLE_CREDIT":
      return action.credit;
    default:
      return state;
  }
};

export const availableCreditReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_AVAILABLE_CREDIT":
      return action.availableCredit;
    default:
      return state;
  }
};
export const usedCreditReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_USED_CREDIT":
      return action.usedCredit;
    default:
      return state;
  }
};

export const creditAvailableListPagination = (state = 1, action) => {
  switch (action.type) {
    case "SET_AVAILABLE_CREDIT_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
export const creditUsedListPagination = (state = 1, action) => {
  switch (action.type) {
    case "SET_USED_CREDIT_LIST_PAGINATION":
      return action.page;
    default:
      return state;
  }
};
