import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Container } from "react-bootstrap";
import Unauthorized from "../unauthorized";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { getAllStoreList } from "../../store/actionCreators/Inventory/InventoryAction";
import { useDispatch } from "react-redux";

const AddInventoryEmployees = ({ editPermission, sideToggle }) => {
  const [options, setOptions] = useState([]);
  const [selectedStores, setSelected] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allStoreList = useSelector((state) => state.get_all_stores);

  const [data, setData] = useState({
    name: "",
    mobile_no: "",
    email: "",
    date_of_birth: "",
    gender: "",
    store_id: "",
    address: "",
    password: "",
    active: true,
  });

  useEffect(() => {
    dispatch(getAllStoreList());
  }, []);

  useEffect(() => {
    let array = [];
    if (allStoreList.data) {
      array = allStoreList.data.data.map((item, index) => {
        return { label: item.store_name, value: item.store_id };
      });
    } else array = [];
    setOptions(array);
  }, [allStoreList]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayStores = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Stores"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedStores}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  const storeString = selectedStores.filter(
    ({label} ) => label !== 'All' 
    ).map(
    ({value} ) => value 
    ).join(",")

  console.log({ storeString, selectedStores }, "chccc");

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      store_id,
      address,
      password,
      active,
    } = data;

    const dataClone = {
      name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      store_id,
      address,
      password,
      active,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/add_inventory_employee`,
        { ...dataClone, store_id: storeString }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully added`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/inventoryemployees");
        } else {
          toast.error(
            `${res.data.msg ? res.data.msg : "something went wrong"} `,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  if (editPermission) {
    return (
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="page-content ">
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="">
                    <Card.Title className="h4 mb-4 my-auto">
                      Add New Inventory Employee
                    </Card.Title>
                    <form onSubmit={onSubmit}>
                      <Row className="mb-2">
                        <Col>
                          <label>Name:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.name}
                            onChange={(e) =>
                              setData({ ...data, name: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Mobile No:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            value={data.mobile_no}
                            onChange={(e) =>
                              setData({ ...data, mobile_no: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Email:</label>
                          <input
                            required
                            type="email"
                            className="form-control"
                            value={data.email}
                            onChange={(e) =>
                              setData({ ...data, email: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Date Of Birth:</label>
                          <input
                            type="date"
                            className="form-control"
                            value={data.date_of_birth}
                            onChange={(e) =>
                              setData({
                                ...data,
                                date_of_birth: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col>
                          <label>Gender:</label>
                          <Select
                            value={{ label: data.gender, value: data.gender }}
                            options={[
                              { label: "Male", value: "male" },
                              { label: "Female", value: "female" },
                            ]}
                            onChange={(e) =>
                              setData({ ...data, gender: e.value })
                            }
                            
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {" "}
                          <div className="mb-3 p-2 ">
                            <label className="form-label">Select Stores</label>
                            <div className="d-flex">
                              {displayStores()}
                              <span className="ms-3 p-2">{data.store}</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Address:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.address}
                            onChange={(e) =>
                              setData({ ...data, address: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Password:</label>
                          <input
                            required
                            type="password"
                            className="form-control"
                            value={data.password}
                            onChange={(e) =>
                              setData({ ...data, password: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <Button type="submit" className="btn btn-warning">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddInventoryEmployees;
