import axios from "axios";

export const getAllOutlets = () => {
    return (dispatch, getState) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/get_outlet`
        )
        .then((outlets) => {
          dispatch({
            type: "GET_ALL_OUTLETS",
             outlets,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
  };