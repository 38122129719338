import React from "react";
import { Modal, Row, Col, Badge, Button } from "react-bootstrap";

const AddonModal = ({ show, close, data }) => {
  return (
    <Modal
      show={show}
      onHide={close}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add ons</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.keys(data).map((ao) => (
          <Row>
            <Col className="mb-2">
              <h5>
                <Badge bg="warning">{ao}</Badge>
              </h5>
              {data[ao].map((i, index) => {
                return (
                  <div>
                    {index + 1}) {i.value} [Price: {i.price} SKU: {i.sku}]
                  </div>
                );
              })}
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddonModal;
