import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { getSingleInventoryEmployee } from "../../store/actionCreators/Inventory/InventoryAction";
import { getAllStoreList } from "../../store/actionCreators/Inventory/InventoryAction";

const ViewInventoryEmployee = ({ sideToggle }) => {
  const [options, setOptions] = useState([]);
  const [selectedStores, setSelected] = useState([]);
  const [changePwd, setChangePwd] = useState(false);
  const [pwd, setPwd] = useState("");
  const allStoreList = useSelector((state) => state.get_all_stores);

  const employeeData = useSelector(
    (state) => state.get_single_inventory_employee
  );
  const dispatch = useDispatch();
  const params = useParams();

  const [data, setData] = useState({
    store_name: "",
    city: "",
    region: "",
    address: "",
    email: "",
    contact: "",
    timing: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    let array = [];
    if (allStoreList.data) {
      array = allStoreList.data.data.map((item, index) => {
        return { label: item.store_name, value: item.store_id };
      });
    } else array = [];
    setOptions(array);
  }, [allStoreList]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  useEffect(() => {
    dispatch(getSingleInventoryEmployee(params.inventory_login_id));
    dispatch(getAllStoreList());
  }, []);

  useEffect(() => {
    setData(employeeData);

    if (employeeData) {
      setSelected(
        (employeeData?.inventory_stores_logins || []).map((e) => ({
          label: e?.store.store_name,
          value: e?.store.store_id,
        }))
      );
    }
  }, [employeeData]);

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayStores = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Stores"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedStores}
            onChange={onChange}
            setState={setSelected}
            required
            isDisabled={true}
          />
        </div>
      );
    }
  };

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {employeeData ? (
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      View Employee Details
                    </Card.Title>
                  </div>

                  <Row className="mb-2">
                    <Col>
                      <label>Name:</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={data.name}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Mobile No:</label>
                      <input
                        required
                        type="number"
                        className="form-control"
                        value={data.mobile_no}
                        onChange={(e) =>
                          setData({ ...data, mobile_no: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Email:</label>
                      <input
                        required
                        type="email"
                        className="form-control"
                        value={data.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>date_of_birth:</label>
                      <input
                        required
                        type="date"
                        className="form-control"
                        value={data.date_of_birth}
                        onChange={(e) =>
                          setData({
                            ...data,
                            date_of_birth: e.target.value,
                          })
                        }
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col>
                      <label>Gender:</label>
                      <Select
                        value={{ label: data.gender, value: data.gender }}
                        options={[
                          { label: "Male", value: "male" },
                          { label: "Female", value: "female" },
                        ]}
                        onChange={(e) => setData({ ...data, gender: e.value })}
                        isDisabled={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {" "}
                      <div className="mb-3 p-2 ">
                        <label className="form-label">Select Stores</label>
                        <div className="d-flex">
                          {displayStores()}
                          <span className="ms-3 p-2">{data.store}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <label>Address:</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={data.address}
                        onChange={(e) =>
                          setData({ ...data, address: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                        <div className="mb-2">
                          <label>Change password ?</label>
                          <Form.Check
                            type="checkbox"
                            checked={changePwd}
                            onChange={() => {
                              setChangePwd(!changePwd);
                              setPwd("");
                            }}
                            disabled
                          />
                        </div>
                      </div>
                    </Col>{" "}
                    <Col>
                      <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                        <div className="mb-2">
                          <label>Password</label>
                          <input
                            required
                            type="password"
                            className="form-control"
                            disabled={!changePwd}
                            onChange={(e) => setPwd(e.target.value)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default ViewInventoryEmployee;
