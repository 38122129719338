import axios from "axios";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";

const ClearAllOrders = (props) => {
  const clearAllOrders = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_orders/clear_orders`
      );
      if (response && response.data?.status !== "failure") {
        toast.success(`cleared successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(`Error While Clearing Data`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data?.data;
    } catch (error) {}
  };
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <Row>
        <Col lg={9} sm={6} xs={12} className="dash-head">
          Orders Dashboard
        </Col>
      </Row>
      <Button
        className="btn btn-warning"
        onClick={() => {
          clearAllOrders();
        }}
      >
        Clear All orders
      </Button>
    </Container>
  );
};

export default ClearAllOrders;
