import React from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";

const AddNewInvestorNext = (props) => {
  console.log(props.selectedBranches, "cc");
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="row d-flex justify-content-center">
        <div className="form-container">
          <div className="form-head">Add Investor</div>
          <div className="form-body">
            <form>
              <Row>
                <Col lg={8}>
                  <div className="d-flex align-items-center">
                    <h1 className="mb-2">Selected Branches</h1>
                  </div>
                  <Table bordered hover className="mt-3">
                    <thead>
                      <tr>
                        <th>BRANCHES</th>
                        <th>PERCENTAGE CUT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.selectedBranches.map((item, index) => (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>
                            <Form.Control
                              onChange={(e) =>
                                props.percentChange(
                                  index,
                                  e.target.value
                                )
                              }
                              value={item.percentage_cut || ""}
                              type="number"
                              required
                              className="w-50"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                {/* <Col>
                  {" "}
                  <div className="mb-3 p-2">
                    <label className="form-label">Percentage Cut</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="percentage_cut"
                      value={props?.newInvestor?.percentage_cut}
                      onChange={props.handleChange("percentage_cut")}
                      required
                    />
                  </div>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  {" "}
                  <div className="mb-2 p-2">
                    <button
                      className="btn btn-primary me-2  "
                      onClick={props.handleBackSecondForm}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary me-2  "
                      onClick={props.handleFinalSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AddNewInvestorNext;
