import axios from "axios";
import { toast } from "react-toastify";
import qs from "qs";

export const setCouponsPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_COUPONS_PAGE",
      page,
    });
  };
};

export const getCouponList = (page, search_filters = {}) => {
  const search = qs.stringify(search_filters);
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_coupons/${page}?${search}`
      )
      .then((res) => {
        dispatch({
          type: "GET_COUPONS",
          coupons: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const getCoupon = (coupon_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/fetch_sngle_coupon?coupon_id=${coupon_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_COUPON",
          coupon: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const updateCoupon = (coupon) => {
  // console.log(coupon);
  const data = {};
  const {
    coupon_code,
    title,
    start,
    end,
    disc_percent,
    flat_discount,
    customer_no,
    employee_id,
    branch_id,
    min_cart,
    customer_group_name,
    coupon_id,
  } = coupon;
  data.coupon_id = coupon_id;
  if (coupon_code) data.coupon_code = coupon_code;
  if (title) data.title = title;
  if (start) data.start = start;
  if (end) data.end = end;
  if (disc_percent) data.disc_percent = disc_percent;
  if (flat_discount) data.flat_discount = flat_discount;
  if (customer_no) data.customer_no = customer_no;
  if (employee_id) data.employee_id = employee_id;
  if (branch_id) data.branch_id = branch_id;
  if (min_cart) data.min_cart = min_cart;
  if (customer_group_name) data.customer_group_name = customer_group_name;
  console.log(data);
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/update_coupons`,
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          dispatch({
            type: "UPDATE_COUPON",
            coupon: coupon,
          });
          toast.success(`successFully Updated Coupon`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// UPDATE ROLES

export const UpdateCustomerRoles = (customerRole) => {
  const data = {};
  const {
    min_purchase,
    total_days,
    reduce_purchase,
    upg_purchase,
    upg_days,
    kitchen_disc_perc,
    barista_disc_percent,
  } = customerRole;
  data.min_purchase = min_purchase;

  if (min_purchase) data.min_purchase = min_purchase;
  if (total_days) data.total_days = total_days;
  if (reduce_purchase) data.reduce_purchase = reduce_purchase;
  if (upg_purchase) data.upg_purchase = upg_purchase;
  if (upg_days) data.upg_days = upg_days;
  if (kitchen_disc_perc) data.kitchen_disc_perc = kitchen_disc_perc;
  if (barista_disc_percent) data.barista_disc_percent = barista_disc_percent;

  // console.log(data);
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/update_customer_roles?customer_type=${customerRole?.customer_type}`,
        data
      )
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch({
            type: "UPDATE_CUSTOMER_ROLES",
            customerRole: { ...res, customerRole },
          });
          toast.success(`successFully Updated Customer Role`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 4000);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const addNewCoupon = (coupon) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_coupon`,
        coupon
      )
      .then((res) => {
        console.log(res);
        
        if (res.data.status === "success") {
          toast.success(`Successfully Added Coupon`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Coupon already exists`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

            dispatch({
              type: "ADD_COUPON",
              res,
            });
           
        setTimeout(() => {
          window.location.reload(false);
        }, 4000);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const addNewGiftCard = (data) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_gift_card`,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "ADD_GIFT_CARD",
          res,
        });
        if (res.data.status === "success")
          toast.success(`successFully Added Gift Card`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

        setTimeout(() => {
          window.location.href = "allgiftcards";
        }, 4000);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const addPromoCode = (data) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_promo_code`,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "ADD_PROMO_CODE",
          res,
        });
        if (res.data.status === "success")
          toast.success(`successFully Added Promo Code`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setTimeout(() => {
          window.location.href = "allpromocodes";
        }, 4000);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
// promos actions

export const getPromoList = (page) => {
  // const search = qs.stringify(search_filters);
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/get_promo_code/${page} `
      )
      .then((res) => {
        dispatch({
          type: "GET_PROMOS",
          promos: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const deletePromoCode = (data) => {
  return (dispatch, getState) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/del_promo_code?promo_code_id=${data}`
      )
      .then((res) => {
        console.log(res);
        if (res.data && res.data.status === "success") {
          toast.success(`Promocode deleted successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "DEL_PROMOCODE",
            data,
          });
        }

        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
};

export const getSinglePromo = (promo_code_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/get_single_promo_code?promo_code_id=${promo_code_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_PROMO",
          promo: res.data.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const updatePromo = (promo) => {
  console.log(promo.status, "edits2");
  // console.log(promo.expiry_time, "promoji");
  const data = {};
  const {
    coupon_code,
    title,
    description,
    min_amount,
    extra_amount,
    expiry_time,
    start_time,
    status,
    promo_code_id,
  } = promo;
  data.promo_code_id = promo_code_id;
  if (coupon_code) data.coupon_code = coupon_code;
  if (title) data.title = title;
  if (description) data.description = description;
  if (min_amount) data.min_amount = min_amount;
  if (extra_amount) data.extra_amount = extra_amount;
  data.expiry_time = expiry_time;
  data.start_time = start_time;
  data.status = status;

  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/edit_promo_code?promo_code_id=${promo_code_id}`,
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          dispatch({
            type: "UPDATE_PROMO",
            promo: promo,
          });
          toast.success(`successFully Updated Coupon`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(`${res.data.status}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// gift card actions

export const deleteGiftCard = (data) => {
  return (dispatch, getState) =>
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/del_gift_card?gift_card_id=${data}`
      )
      .then((res) => {
        console.log(res);
        if (res.data && res.data.status === "success") {
          toast.success(`Giftcard deleted successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch({
            type: "DEL_GIFTCARD",
            data,
          });
        }

        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
};

// SINGLE GIFT CARD ACTION

export const getSingleGiftCard = (gift_card_id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/get_single_gift_card?gift_card_id=${gift_card_id}`
      )
      .then((res) => {
        dispatch({
          type: "GET_SINGLE_GIFT_CARD",
          giftCard: res.data,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// UPDATE GIFT CARD ACTION

export const updateGiftCard = (gift, customerString) => {
  console.log(customerString, "ed");
  const data = {};
  const { title, description, customer_id, amount, status, gift_card_id } =
    gift;

  data.gift_card_id = gift_card_id;

  if (title) data.title = title;
  if (description) data.description = description;
  if (customer_id) data.customer_id = customerString;
  if (amount) data.amount = amount;

  if (status) data.status = status;

  return (dispatch, getState) => {
   
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/edit_gift_card?gift_card_id=${gift_card_id}`,
        data
      )
      .then((res) => {
        // console.log(res);
        if (res.data.status === "success") {
          dispatch({
            type: "UPDATE_GIFT_CARD",
            giftCard: gift,
          });
          toast.success(`Successfully Updated Gift Card`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
        } else {
          toast.error(`${res.data.status}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// GET ALL GIFT CARDS ACTION

export const getGiftList = (page) => {
  // const search = qs.stringify(search_filters);
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/get_gift_card/${page}`
      )
      .then((res) => {
        dispatch({
          type: "GET_GIFTS",
          giftCards: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

// actions

export const addNewGroupCoupon = (
  customer_data,
  customerNoString,
  customerIdString
) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_new_group`,
        {
          ...customer_data,
          customer_no: customerNoString,
          customer_id: customerIdString,
        }
      )
      .then((res) => {
        dispatch({
          type: "ADD_NEW_GROUP_COUPON",
          group: { customer_data },
        });
        if (res.data.status === "success")
          toast.success(`successFully Added Group`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        else {
          toast.error(`${res.data.msg}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};


export const updateGroupCoupon = (
  customer_data,
  customerNoString,
  customerIdString
) => {
  return (dispatch, getState) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/edit_group`,
        {
          ...customer_data,
          customer_no: customerNoString,
          customer_id: customerIdString,
        }
      )
      .then((res) => {
        dispatch({
          type: "UPDATE_GROUP_COUPON",
          group: { customer_data },
        });
        if (res.data.status === "success")
          toast.success(`successFully Added Group`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        else {
          toast.error(`${res.data.msg}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export const getSingleProductList = (id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/get_single_product_list?product_list_id=${id}`
      )
      .then((get_single_product_list) => {
        dispatch({
          type: "GET_SINGLE_PRODUCT_LIST",
          get_single_product_list,
        });
      });
  };
};

// new coupons system ALL COUPONS

export const getCouponNew = (page) => {
  // const search = qs.stringify(search_filters);
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/fetch_all_coupons/${page}`
      )
      .then((res) => {
        dispatch({
          type: "GET_ALL_COUPONS",
          allcoupons: res,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const deleteCoupon=(coupon_id)=>{
  return (dispatch, getState) => {
    
     return axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/delete_coupons/${coupon_id}`
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          toast.success(`${res.data.msg}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });}
        dispatch({
          type: "DELETE_COUPON",
        });
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
}

export const getSingleCouponNew = (id) => {
  return (dispatch, getState) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/fetch_sngle_coupon?all_coupons_id=${id}`
      )
      .then((get_single_coupon_new) => {
        dispatch({
          type: "GET_SINGLE_COUPON",
          get_single_coupon_new,
        });
      });
  };
};

export const setGiftCardPagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_GIFTCARD_PAGINGATION",
      page,
    });
  };
};

export const setPromoCodePagination = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_PROMO_PAGINATION",
      page,
    });
  };
};
