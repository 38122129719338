import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { fetchAllUsedCredit } from "../../store/actionCreators/Credits/CreditActions";
import { useDispatch } from "react-redux";

const StatusUpdateModal = ({
  showModal,
  close,
  data,
  updateInventoryCredit,
  setRowData,
  currStores,
}) => {
  const dispatch = useDispatch();
  const allStatus = [
    { label: "Available", value: true },
    { label: "Used", value: false },
  ];

  useEffect(() => {
    console.log(data?.credit_status, "third");
  }, [data]);

  const statusFc = useMemo(() => {
    let status = "";
    if (data?.credit_status === false) {
      status = "Used";
    } else if (data?.credit_status === true) {
      status = "Available";
    }
    return status;
  }, [data]);

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={close}
        size="sm"
        aria-labelledby="contained-modal-title-center"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Status for {data?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateInventoryCredit(data).then(() => {
                close();
                dispatch(fetchAllUsedCredit(1, currStores));
                dispatch(fetchAllUsedCredit(1,currStores))
              });
            }}
          >
            <div className="mb-2">
              <label> Credit Status</label>
              <Select
                value={{
                  label: statusFc,
                  value: data?.credit_status,
                }}
                options={allStatus.map((i) => {
                  return {
                    label: i.label,
                    value: i.value,
                  };
                })}
                onChange={(e) =>
                  setRowData({ ...data, credit_status: e.value })
                }
              />
            </div>

            <div>
              <Button className="btn btn-warning" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default StatusUpdateModal;
