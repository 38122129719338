import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { getSingleCafeEmployee } from "../../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { toast } from "react-toastify";
import { getAllBranchesInventory } from "../../../store/actionCreators/Inventory/InventoryAction";
import { useMemo } from "react";

const EditCafeEmployee = ({ sideToggle }) => {
  const [options, setOptions] = useState([]);
  const [selectedBranches, setSelected] = useState([]);
  const [changePwd, setChangePwd] = useState(false);
  const [pwd, setPwd] = useState("");
  const employeeData = useSelector((state) => state.get_single_cafe_employee);

  const AllBranchList = useSelector((state) => state.get_all_branches);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: "",
    mobile_no: "",
    email: "",
    date_of_birth: "",
    gender: "",
    branch_id: "",
    address: "",
    password: "",
    active: "",
  });

  useEffect(() => {
    let array = [];
    if (AllBranchList.data) {
      array = AllBranchList.data.data.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [AllBranchList]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  useEffect(() => {
    dispatch(getSingleCafeEmployee(params.cafe_inventory_login_id));
    dispatch(getAllBranchesInventory());
  }, []);

  useEffect(() => {
    setData(employeeData);

    if (employeeData) {
      setSelected(
        (employeeData?.cafe_inventory_branch_logins || []).map((e) => ({
          label: e?.branch?.branch_name || '',
          value: e?.branch?.branch_id || '',
        }))
      );
    }
  }, [employeeData]);

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayBranches = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  let branchString = useMemo(() => {
    let branchString = "";
    for (let i = 0; i < selectedBranches.length; i++) {
      if (selectedBranches[i].label === "All") {
        branchString = "";
        continue;
      } else {
        if (i === selectedBranches.length - 1) {
          branchString = branchString + selectedBranches[i].value;
        } else {
          branchString = branchString + selectedBranches[i].value + ",";
        }
      }
    }
    return branchString;
  }, [selectedBranches]);

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      branch_id,
      address,
      password,
      active,
    } = data;

    const dataClone = {
      name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      branch_id,
      address,

      active,
    };
    if (!!changePwd) dataClone.password = pwd;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_cafe_inventory_employee?cafe_inventory_login_id=${params.cafe_inventory_login_id}`,
        { ...dataClone, branch_id: branchString }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/cafe_employees");
        } else {
          toast.error(`${res.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {employeeData ? (
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Edit Cafe's Employee's Details
                    </Card.Title>
                  </div>

                  <form onSubmit={onSubmit}>
                    <Row className="mb-2">
                      <Col>
                        <label>Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.name}
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Mobile No:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          value={data.mobile_no}
                          onChange={(e) =>
                            setData({ ...data, mobile_no: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Email:</label>
                        <input
                          required
                          type="email"
                          className="form-control"
                          value={data.email}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Date of Birth:</label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          value={data.date_of_birth}
                          onChange={(e) =>
                            setData({
                              ...data,
                              date_of_birth: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col>
                        <label>Gender:</label>
                        <Select
                          value={{ label: data.gender, value: data.gender }}
                          options={[
                            { label: "Male", value: "male" },
                            { label: "Female", value: "female" },
                          ]}
                          onChange={(e) =>
                            setData({ ...data, gender: e.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2 ">
                          <label className="form-label">Select Branches</label>
                          <div className="d-flex">
                            {displayBranches()}
                            <span className="ms-3 p-2">{data.branch}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Address:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.address}
                          onChange={(e) =>
                            setData({ ...data, address: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col>
                        <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                          <div className="mb-2">
                            <label>Change password ?</label>
                            <Form.Check
                              type="checkbox"
                              checked={changePwd}
                              onChange={() => {
                                setChangePwd(!changePwd);
                                setPwd("");
                              }}
                            />
                          </div>
                        </div>
                      </Col>{" "}
                      <Col>
                        <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                          <div className="mb-2">
                            <label>Password</label>
                            <input
                              required
                              type="password"
                              className="form-control"
                              disabled={!changePwd}
                              onChange={(e) => setPwd(e.target.value)}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <Button type="submit" className="btn btn-warning">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default EditCafeEmployee;
