import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  deletePromoCode,
  getPromoList,
} from "../../../store/actionCreators/Coupons/CouponsAction";
// import CouponPagination from "./CouponsPagination";
import Select from "react-select";
// import AddCouponModal from "./AddCoupon";
// import { setCouponsPagination } from "./../../store/actionCreators/Coupons/CouponsAction";
// import ShowCustomerRolesList from "../Customer/ShowCustomerRolesList";
// import { getAllCustomerRoles } from "../../store/actionCreators/Customers/CustomerRolesActions";
import Loader from "../../Loader/LoaderTable";
import PromoCodePagination from "./PromoCodePagination";
import Unauthorized from "../../unauthorized";

const AllPromoCodes = (props) => {
  const dispatch = useDispatch();
  const allpromoList = useSelector((state) => state.allpromos);
  const [isCustomerRoles, setIsCustomerRoles] = useState(false);
  const page = useSelector((state) => state.promo_code_pagination);
  const [couponListVisible, setCouponListVisible] = useState(true);
  const [displayableCoupons, setDisplayableCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    coupon_id: "",
    coupon_code: "",
    title: "",
    start: "",
    end: "",
    disc_percent: "",
    flat_discount: "",
    customer_no: "",
    employee_id: "",
    branch_id: "",
    min_cart: "",
    customer_group_name: "",
  });
  const [addCouponModal, setAddCouponModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getPromoList(page));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getPromoList(page));
  }, [page]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [allpromoList]);

  const setDefaultData = () => {
    let promoList = [];
    if (allpromoList.data && allpromoList.data.data) {
      promoList = allpromoList.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
        branch_name: props.branchObj[c.branch_id],
        employee_name: props.empObj[c.employee_id],
      }));
    }

    setDisplayableCoupons(promoList);
  };

  const onChangeForSearchFields = (field, value) => {
    const searchOptionsClone = { ...searchOptions };
    searchOptionsClone[field] = value;
    setSearchOptions(searchOptionsClone);
  };

  const deleteFunction = (row) => {
    dispatch(deletePromoCode(row.promo_code_id));
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewpromo/${row.promo_code_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`editPromo/${row.promo_code_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewpromo/${row.promo_code_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "coupon_code",
      text: "Coupon Code",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "min_amount",
      text: "Min Amount",
      sort: true,
    },
    {
      dataField: "extra_amount",
      text: "Extra Amount",
      sort: true,
    },

    {
      dataField: "start_time",
      text: "Start Time",
      sort: true,
    },
    {
      dataField: "expiry_time",
      text: "Expiry Time",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allpromoList.data?.per_page,
    totalSize: allpromoList.data
      ? allpromoList.data.total_count
        ? allpromoList.data.total_count
        : 0
      : 0, // replace later with size(customers),
    custom: true,
  };

  const getAllRows = () => {
    const promoList = [...displayableCoupons];
    // couponList.unshift(searchBoxes);

    return promoList;
  };


  if (props.viewPermission)
  if (!loading) {
    return (
      <React.Fragment>
        <div className="page-content ">
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <Card.Title className="h4 mb-4 ml-auto">
                      {couponListVisible ? "All Promo Codes" : "Customer Roles"}
                    </Card.Title>
                    {/* <Button
                      className="btn btn-warning mb-3 ml-auto "
                      style={{ marginLeft: "auto" }}
                      onClick={() => showFilteredCoupons()}
                    >
                      Show filtered Promos
                    </Button> */}

                    <Link
                      className="btn btn-warning mb-3 "
                      style={{ marginLeft: "1rem", marginRight: "1rem" }}
                      to={"/coupons/addPromoCode"}
                    >
                      Add New Promo Code
                    </Link>
                    {/* <Button
                    className="btn btn-warning mb-3  "
                    onClick={() => showCustomeRoles()}
                  >
                    Show Customer Roles
                  </Button> */}
                  </div>

                  {!isCustomerRoles ? (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="coupon_id"
                      columns={columns}
                      data={getAllRows()}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="coupon_id"
                          columns={columns}
                          data={getAllRows()}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="promo_code"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <PromoCodePagination
                                  pageNum={Math.ceil(
                                    allpromoList.data
                                      ? allpromoList.data.total_count
                                        ? allpromoList.data.total_count /
                                          allpromoList.data?.per_page
                                        : 1
                                      : 1
                                  )}
                                />
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  } else return <Loader />;
  return <Unauthorized />;
};

export default AllPromoCodes;
