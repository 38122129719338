import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import { getAllPurchaseOrder } from "../../../store/actionCreators/Inventory/InventoryAction";
import PurhcasedOrderListPagination from "./PurhcasedOrderListPagination";
import PurchaseOrderPrint from "./PurchaseOrderPrint";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

const AllPurchasedOrdersList = (props) => {
  const { query, clickedData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allPurchaseOrders = useSelector(
    (state) => state.get_all_purchase_order
  );
  const page = useSelector((state) => state.purchase_order_list_pagination);

  const [loading, setLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);
  // const [showPrint, setShowPrint] = useState(false);
  const [rowId, setRowId] = useState({});

  useEffect(() => {
    setLoading(true);
    dispatch(getAllPurchaseOrder(page, query));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllPurchaseOrder(page, query));
  }, [page, query]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [allPurchaseOrders]);

  const setDefaultData = () => {
    let storeList = [];
    if (allPurchaseOrders.data && allPurchaseOrders.data.data) {
      storeList = allPurchaseOrders.data.data.map((c) => ({
        ...c,
        // createdAt: c?.createdAt?.toLocaleString().split("T")[0],
        createdAt: new Date(`${c?.createdAt}`).toLocaleString(undefined, {
          timeZone: "Asia/Kolkata",
        }),
      }));
    }
    setStoreList(storeList);
  };

  const componentref = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentref.current,
  });

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          {row?.bill !== null && (
            <button
              onClick={() => window.open(row?.bill)}
              className="btn btn-sm btn-warning mx-2"
            >
              View
            </button>
          )}

          <button
            onClick={() =>
              navigate(`edit-purchased-order/${row.purchase_orders_id}`)
            }
            className="btn btn-sm btn-warning"
          >
            Edit
          </button>

          <button
            className="btn btn-sm btn-warning ms-2"
            onClick={() => {
              setRowId(row);
              if (rowId != null) {
                handlePrint();
              }
            }}
          >
            Print
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          {row?.bill !== null && (
            <button
              onClick={() => window.open(row?.bill)}
              className="btn btn-sm btn-warning mx-2"
            >
              View
            </button>
          )}

          <button
            className="btn btn-sm btn-warning ms-2"
            onClick={() => {
              setRowId(row);
              if (rowId != null) {
                handlePrint();
              }
            }}
          >
            Print
          </button>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "vendor_name",
      text: "Vendor Name",
      sort: true,
    },
    {
      dataField: "purchase_orders_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },
    {
      dataField: "store_name",
      text: "Store Name",
      sort: true,
    },
    {
      dataField: "total_price",
      text: "Total Price",
      sort: true,
    },
    {
      dataField: "sub_total",
      text: "Subtotal",
      sort: true,
    },

    {
      dataField: "tax",
      text: "Tax",
      sort: true,
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "img",
      text: "Image",
      sort: true,
    },
    {
      dataField: "bill",
      text: "Bill",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allPurchaseOrders.data?.per_page,
    totalSize: allPurchaseOrders.data
      ? allPurchaseOrders.data.total_items
        ? allPurchaseOrders.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const allPurchaseOrders = [...storeList];

    return allPurchaseOrders;
  };

  if (!loading) {
    return (
      <React.Fragment>
        <Container>
          <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
            All {clickedData?.status}
          </Col>

          <div className="page-content ">
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    {
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="store_id"
                        columns={columns}
                        data={getAllRows()}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="store_id"
                            columns={columns}
                            data={getAllRows()}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="store_id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <PurhcasedOrderListPagination
                                    pageNum={Math.ceil(
                                      allPurchaseOrders?.data
                                        ? allPurchaseOrders?.data?.total_items /
                                            allPurchaseOrders.data?.per_page
                                        : 1
                                    )}
                                  />
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          {rowId !== null ? (
            <div className="d-none">
              <PurchaseOrderPrint row={rowId} componentref={componentref} />
            </div>
          ) : (
            ""
          )}
        </Container>
      </React.Fragment>
    );
  } else return <Loader />;
};

export default AllPurchasedOrdersList;
