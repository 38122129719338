import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { getSalesByStaffDaily } from "../../../store/actionCreators/Reports/StaffReportsAction";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
// import SBSDPagination from "./SBSDPagination";
// import { CSVLink } from "react-csv";
import { fetchEmployees } from "../../../store/actionCreators/Employees/EmployeeAction";
import Async from "react-select/async";
import axios from "axios";
import ScrollButton from "../../scrolltotop/ScrollButton";
import Loader from "../../Loader/LoaderTable";
import { toast } from "react-toastify";

const SalesByStaffDaily = (props) => {
  const dispatch = useDispatch();
  const sbsReport = useSelector((state) => state.sales_by_staff_daily);
  const branch = useSelector((state) => state.branch);
  const employees = useSelector((state) => state.employees);
  const [page, setPage] = useState(1);
  const [branchArr, setBranchArr] = useState(["All"]);
  const [showReportClicked, setShowReportClicked] = useState(false);
  const [csv, setCSV] = useState({});
  const [isDataFetched, setisDataFetched] = useState(false);
  const [empArr, setEmpArr] = useState(["All"]);
  const [query, setQuery] = useState({
    employee_id: "All",
    branch_id: "All",
    status: "All",
    // start: "2021-01-19",
    // end: "2022-01-02",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });
  const [empSelected, setEmpSelected] = useState({
    label: "Search Employee Name",
    value: "All",
  });
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  // const [durationSelected, setDurationSelected] = useState({
  //   label: "All",
  //   value: "All",
  // });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    // dispatch(getSalesByStaffDaily(query));
    dispatch(fetchEmployees(1, "All", "All", ""));
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [branch, employees]);

  useEffect(() => {
    if (sbsReport.data && sbsReport.data?.status === "success") {
      setisDataFetched(true);
      setProductData(
        sbsReport?.data?.data.map((e) => ({
          ...e,
          // full_name: e.emplyees.Full Name,
          account_amount:
            Number.parseFloat(
              Math.round(e.account_amount * 100) / 100
            ).toLocaleString() || 0,
          total_tax:
            Number.parseFloat(
              Math.round(e.total_tax * 100) / 100
            ).toLocaleString() || 0,
          cash_sales:
            Number.parseFloat(
              Math.round(e.cash_sales * 100) / 100
            ).toLocaleString() || 0,
          card_sales:
            Number.parseFloat(
              Math.round(e.card_sales * 100) / 100
            ).toLocaleString() || 0,
          wallet_amount:
            Number.parseFloat(
              Math.round(e.wallet_amount * 100) / 100
            ).toLocaleString() || 0,
          total_discount:
            Number.parseFloat(
              Math.round(e.total_discount * 100) / 100
            ).toLocaleString() || 0,
          membership_discount:
            Number.parseFloat(
              Math.round(e.membership_discount * 100) / 100
            ).toLocaleString() || 0,
          coupon_discount:
            Number.parseFloat(
              Math.round(e.coupon_discount * 100) / 100
            ).toLocaleString() || 0,

          delivery_sales:
            Number.parseFloat(
              Math.round(e.delivery_sales * 100) / 100
            ).toLocaleString() || 0,
          delivery_charges:
            Number.parseFloat(
              Math.round(e.delivery_charges * 100) / 100
            ).toLocaleString() || 0,
          Average_Time_per_order:
            Number.parseFloat(
              Math.round(e.Average_Time_per_order * 100) / 100
            ).toLocaleString() || 0,

          paid_price: Number.parseFloat(e.paid_price).toLocaleString(),
          Average_Ticket_Size:
            Number.parseFloat(
              Math.round(e.Average_Ticket_Size * 100) / 100
            ).toLocaleString() || 0,
        }))
      );
      setPage(1);
    } else if (sbsReport.data?.status === "failure") {
      setisDataFetched(true);
      setProductData([]);
    }
  }, [sbsReport]);

  const setDefaultData = () => {
    if (branch.data && branch.data?.data) {
      const branchArrClone = branch?.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }

    if (employees.data && employees.data?.data) {
      const empArrClone = employees?.data?.data.map((b) => ({
        label: b?.full_name,
        value: b?.employee_id,
      }));
      empArrClone.unshift({ label: "All", value: "All" });
      setEmpArr(empArrClone);
    }
  };

  const onShowReport = () => {
    setProductData([]);
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    queryClone.employee_id = empSelected.value;
    dispatch(getSalesByStaffDaily(queryClone));
    setShowReportClicked(true);
  };

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_emp?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.full_name}`,
          value: e.employee_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const loadDataemplo = () => {
    return (
      !!employees.data?.data &&
      employees?.data?.data.map((item, index) => {
        return {
          label: item.full_name,
          value: item.employee_id,
          branch_id: item.branch_id,
          employee_id: item.employee_id,
        };
      })
    );
  };
  const exportSBSDCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_staff_reports_by_daily`,
        query
      );
      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      }
      if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data.data;
    } catch (error) {}
  };

  const columns = [
    {
      dataField: "Date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "emplyees.Full Name",
      text: "Employee Name",
      sort: true,
    },
    {
      dataField: "total_orders",
      text: "Total Orders",
      sort: true,
    },

    {
      dataField: "Average_Ticket_Size",
      text: "Average Ticket Size",
      sort: true,
    },
    {
      dataField: "paid_price",
      text: "Total Sale",
      sort: true,
    },
    {
      dataField: "cash_sales",
      text: "Cash Sales",
      sort: true,
    },
    {
      dataField: "card_sales",
      text: "Card Sales",
      sort: true,
    },
    {
      dataField: "wallet_amount",
      text: "Wallet Sales",
      sort: true,
    },
    {
      dataField: "delivery_sales",
      text: "Delivery Sales",
      sort: true,
    },
    {
      dataField: "membership_discount",
      text: "Membership Discount",
      sort: true,
    },
    {
      dataField: "coupon_discount",
      text: "Coupon Discount",
      sort: true,
    },

    {
      dataField: "total_discount",
      text: "Total Discount",
      sort: true,
    },

    {
      dataField: "total_tax",
      text: "Tax",
      sort: true,
    },
    {
      dataField: "delivery_charges",
      text: "Delivery Partner Charges",
      sort: true,
    },

    {
      dataField: "Average_Time_per_order",
      text: "Average Time per order",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: productData.length,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">SALES BY STAFF DAILY</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Order status:</td>
                      <td className="">
                        <Select
                          options={orderStatusOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Employee:</td>
                      <td className="">
                        <Async
                          defaultOptions={loadDataemplo()}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={empSelected}
                          onChange={(e) => setEmpSelected(e)}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Duration:</td>
                      <td className="">
                        <Select
                          options={[
                            { label: "All", value: "All" },
                            { label: "Daily", value: "Daily" },
                          ]}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "xls", value: "xls" }]}
                value={{ label: "xls", value: "xls" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportSBSDCSV();
                }}
              >
                {/* <CSVLink
                  data={productData.map((p) => ({
                    Day: new Date(p.date).toDateString(),
                    Staff: p.full_name,
                    Order_Count: p.order_count,
                    Sales_Total: p.paid_price,
                  }))}
                  filename={"report.csv"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Export
                </CSVLink> */}
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="date"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="date"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="date"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {/* {!isDataFetched && <Loader />} */}
                                  {/* {sbsReport?.data?.status === "failure" &&
                                    "Something went Wrong"} */}
                                  {/* {productData?.length === 0 &&
                                    sbsReport.data?.status !== "success" &&
                                    isDataFetched &&
                                    "No data found"} */}
                                  {!productData.length > 0 &&
                                  sbsReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {sbsReport?.data?.status === "failure" &&
                                    !sbsReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>

                            {/* <Row>
                          <SBSDPagination pageNum={Math.ceil(page)} />
                        </Row> */}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SalesByStaffDaily;
