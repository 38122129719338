import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import logo from "../assets/logo.png";
import Header from "./header";
import { FaUsers } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { RiBuilding2Fill } from "react-icons/ri";
import { RiAdminFill } from "react-icons/ri";
import { ImUserTie } from "react-icons/im";
import { FaClipboardList, FaWarehouse } from "react-icons/fa";
import { IoFastFood, IoNotificationsCircle } from "react-icons/io5";
import { HiUserGroup } from "react-icons/hi";
import { SiZomato } from "react-icons/si";
import ondc from "../assets/ondc.png";

import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../css/sidebar.css";
import "../css/sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSideToggle } from "../store/actionCreators/SideBar";
import { MdCreditScore } from "react-icons/md";
const Sidebar = (props) => {
  const location = useLocation();
  const match = useMatch("/reports/*");

  const [widthSideMax, setWidthMax] = useState("240px");
  const [widthOfSideMin, setWidthMin] = useState("80px");
  const [widthSide, setWidthSide] = useState("270px");
  const size = useWindowSize();
  const open = useSelector((state) => state.toggle);
  const dispatch = useDispatch();
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  useEffect(() => {
    if (window.innerWidth <= "768") {
      setWidthMax("0");
      setWidthMin("80px");
    }
    if (window.innerWidth > "768") {
      setWidthMax("240px");
      setWidthMin("80px");
      setWidthSide("270px");
    }
  }, []);

  const clickHandler = () => {
    if (size.width <= "768") {
      if (props.sideToggle === false) {
        props.setSideToggle(true);
      } else {
        props.setSideToggle(false);
      }
    }
    if (size.width > "768") {
      if (props.sideToggle === true) {
        props.setSideToggle(false);
        setWidthSide("270px");
      } else {
        props.setSideToggle(true);
        setWidthSide("100px");
      }
    }
  };

  function openChange(name) {
    for (var i = 1; i < 17; i++) {
      if (name === `open${i}`) {
        dispatch(setSideToggle(`open${i}`, true));
      } else {
        if (open[`open${i}`] === true) {
          dispatch(setSideToggle(`open${i}`, false));
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Header handle={props.handle} widthSide={widthSide} />
      <div
        id="sidebar-main"
        style={{
          display: "flex",
          height: "100vh",
          overflow: "scroll initial",
          zIndex: "1001",
          position: "fixed",
          top: "0",
        }}
        className={props.sideToggle === true ? "widthOfSide" : "widthUntoggled"}
      >
        <ProSidebar
          id="my-sidebar"
          width={widthSideMax}
          collapsedWidth={widthOfSideMin}
          collapsed={props.sideToggle}
        >
          <SidebarHeader>
            <div
              id="mainLeftTrigger"
              className="w-100 "
              style={{ height: "77px" }}
              onClick={clickHandler}
            >
              <div className="h-100 d-flex justify-content-center align-items-center">
                <img src={logo} width="26px" alt="logo" className=""></img>
                <div className="w-75 heebee-logo ">HeeBee</div>
              </div>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="round">
              <SubMenu
                open={open.open1}
                onOpenChange={() => openChange("open1")}
                title="Dashboard"
                icon={<RiDashboardFill />}
              >
                <MenuItem active={location.pathname === "/"}>
                  My Dashboard
                  <NavLink to="/" />
                </MenuItem>
              </SubMenu>

              <SubMenu
                open={open.open2}
                onOpenChange={() => openChange("open2")}
                title="Customer"
                icon={<FaUsers />}
              >
                <MenuItem active={location.pathname === "/customer"}>
                  Customer Dashboard
                  <NavLink exact="true" to="/customer" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/customer/allCustomer"}
                >
                  All Customers
                  <NavLink exact="true" to="/customer/allCustomer" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/customer/addCustomer"}
                >
                  Add New Customers
                  <NavLink exact="true" to="/customer/addCustomer" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/customer/allCustomerRoles"}
                >
                  All Customer Roles
                  <NavLink to="/customer/allCustomerRoles" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={open.open3}
                onOpenChange={() => openChange("open3")}
                title="Employees"
                icon={<ImUserTie />}
              >
                 <MenuItem active={location.pathname === "/employee/addNew"}>
                  Add Employee
                  <NavLink to="/employee/addNew" />
                </MenuItem>
                <MenuItem active={location.pathname === "/employee"}>
                  All Employees
                  <NavLink exact="true" to="/employee" />
                </MenuItem>
               
                <MenuItem active={location.pathname === "/employee/career"}>
                  Career
                  <NavLink exact="true" to="/employee/career" />
                </MenuItem>
                {/* <MenuItem>Add New<NavLink exact="true" to="/employee/addNew" /></MenuItem> */}
              </SubMenu>
              <SubMenu
                open={open.open4}
                onOpenChange={() => openChange("open4")}
                title="Catalog"
                icon={<FaClipboardList />}
              >
                <MenuItem active={location.pathname === "/catalog/"}>
                  All Category
                  <NavLink to="/catalog/" />
                </MenuItem>
                <MenuItem active={location.pathname === "/catalog/AllProducts"}>
                  All Products
                  <NavLink to="/catalog/AllProducts" />
                </MenuItem>
                <MenuItem active={location.pathname === "/catalog/AddCategory"}>
                  Add Category
                  <NavLink to="/catalog/AddCategory" />
                </MenuItem>
                <MenuItem active={location.pathname === "/catalog/AddProduct"}>
                  Add Product
                  <NavLink to="/catalog/AddProduct" />
                </MenuItem>
                <MenuItem active={location.pathname === "/catalog/AddAddons"}>
                  Add Addons
                  <NavLink to="/catalog/AddAddons" />
                </MenuItem>
                <MenuItem active={location.pathname === "/catalog/AllAddons"}>
                  All Addons
                  <NavLink to="/catalog/AllAddons" />
                </MenuItem>
                <MenuItem active={location.pathname === "/catalog/qrcode"}>
                  QR Code
                  <NavLink to="/catalog/qrcode" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/catalog/soldproductreport"}
                >
                  Sold Product Report
                  <NavLink to="/catalog/soldproductreport" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={open.open5}
                onOpenChange={() => openChange("open5")}
                title="Franchise"
                icon={<RiBuilding2Fill />}
              >
                <MenuItem active={location.pathname === "/branch/AllBranch"}>
                  All Region
                  <NavLink to="/branch/AllBranch" />
                </MenuItem>
                <MenuItem active={location.pathname === "/branch/AddBranch"}>
                  Add Branch
                  <NavLink to="/branch/AddBranch" />
                </MenuItem>
                <MenuItem active={location.pathname === "/branch/AddFranchise"}>
                  Add Region
                  <NavLink to="/branch/AddFranchise" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={open.open6}
                onOpenChange={() => openChange("open6")}
                title="Orders"
                icon={<IoFastFood />}
              >
                <MenuItem active={location.pathname === "/orders"}>
                  All Orders
                  <NavLink to="/orders" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/orders/clearallorders"}
                >
                  Clear All Orders
                  <NavLink to="/orders/clearallorders" />
                </MenuItem>
              </SubMenu>
              {/* <SubMenu
                open={open.open7}
                onOpenChange={() => openChange("open7")}
                title="Groups"
                icon={<HiUserGroup />}
              >
                <MenuItem active={location.pathname === "/groups"}>
                  All Groups
                  <NavLink to="/groups" />
                </MenuItem>
              </SubMenu> */}
              <SubMenu
                open={open.open8}
                onOpenChange={() => openChange("open8")}
                title="Coupons"
                icon={<IoFastFood />}
              >
                <MenuItem active={location.pathname === "/coupons"}>
                  All Coupons
                  <NavLink to="/coupons" />
                </MenuItem>
                {/* <MenuItem active={location.pathname === "/coupons/addCoupon"}>
                  Add New Coupon
                  <NavLink to="/coupons/addCoupon" />
                </MenuItem> */}
                <MenuItem
                  active={location.pathname === "/coupons/addNewCoupon"}
                >
                  Add Coupon
                  <NavLink to="/coupons/addNewCoupon" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/coupons/allgiftcards"}
                >
                  All Gift Card
                  <NavLink to="/coupons/allgiftcards" />
                </MenuItem>
                <MenuItem active={location.pathname === "/coupons/addGiftCard"}>
                  Add Gift Card
                  <NavLink to="/coupons/addGiftCard" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/coupons/allpromocodes"}
                >
                  All Promo Code
                  <NavLink to="/coupons/allpromocodes" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/coupons/addPromoCode"}
                >
                  Add Promo Code
                  <NavLink to="/coupons/addPromoCode" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={open.open9}
                onOpenChange={() => openChange("open9")}
                title="User"
                icon={<RiAdminFill />}
              >
                {/* <MenuItem active={location.pathname === "/employee/addNew"}>
                  Add Employee
                  <NavLink to="/employee/addNew" />
                </MenuItem> */}
                <MenuItem active={location.pathname === "/user/alladmins"}>
                  All Admins
                  <NavLink to="/user/alladmins" />
                </MenuItem>
                <MenuItem active={location.pathname === "/user"}>
                  Add Admin
                  <NavLink to="/user" />
                </MenuItem>
                <MenuItem active={location.pathname === "/user/role"}>
                  Role
                  <NavLink to="/user/role" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={open.open10}
                onOpenChange={() => openChange("open10")}
                title="Inventory"
                icon={<FaWarehouse />}
              >
                {/* <MenuItem active={location.pathname === "/inventory"}>
                  Dashboard
                  <NavLink to="/inventory" />
                </MenuItem> */}
                <MenuItem active={location.pathname === "/inventory/allstores"}>
                  All Stores
                  <NavLink to="/inventory/allstores" />
                </MenuItem>
                <MenuItem active={location.pathname === "/inventory/addstore"}>
                  Add Store
                  <NavLink to="/inventory/addstore" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/inventoryemployees"}
                >
                  Store Employees
                  <NavLink to="/inventory/inventoryemployees" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/addemployees"}
                >
                  Add Store Employee
                  <NavLink to="/inventory/addemployees" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/cafe_employees"}
                >
                  Cafe Employees
                  <NavLink to="/inventory/cafe_employees" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/addCafeEmployees"}
                >
                  Add Cafe Employee
                  <NavLink to="/inventory/addCafeEmployees" />
                </MenuItem>

                <MenuItem active={location.pathname === "/inventory/allvendor"}>
                  All Vendors
                  <NavLink to="/inventory/allvendor" />
                </MenuItem>
                <MenuItem active={location.pathname === "/inventory/addvendor"}>
                  Add Vendor
                  <NavLink to="/inventory/addvendor" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/allpurchasedorders"}
                >
                  All Purchased Orders
                  <NavLink to="/inventory/allpurchasedorders" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/all_cafe_requests"}
                >
                  All Cafe Request
                  <NavLink to="/inventory/all_cafe_requests" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/cafe_stock"}
                >
                  Cafe Stock
                  <NavLink to="/inventory/cafe_stock" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/store_stock"}
                >
                  Store Stock
                  <NavLink to="/inventory/store_stock" />
                </MenuItem>
                <MenuItem active={location.pathname === "/inventory/allitems"}>
                  All Items
                  <NavLink to="/inventory/allitems" />
                </MenuItem>
                <MenuItem active={location.pathname === "/inventory/additems"}>
                  Add Item
                  <NavLink to="/inventory/additems" />
                </MenuItem>
                <MenuItem active={location.pathname === "/inventory/allunits"}>
                  Items Units
                  <NavLink to="/inventory/allunits" />
                </MenuItem>
                <MenuItem active={location.pathname === "/inventory/addunits"}>
                  Add Item's Unit
                  <NavLink to="/inventory/addunits" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/soldproductreport"}
                >
                  Sold Product Report
                  <NavLink to="/inventory/soldproductreport" />
                </MenuItem>

                <MenuItem active={location.pathname === "/credits"}>
                  Credits
                  <NavLink to="/credits" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/inventory/store_sent_request"}
                >
                  Store Sent Request
                  <NavLink to="/inventory/store_sent_request" />
                </MenuItem>
                <MenuItem
                  active={
                    location.pathname === "/inventory/store_received_request"
                  }
                >
                  Store Received Request
                  <NavLink to="/inventory/store_received_request" />
                </MenuItem>
                {/* <MenuItem active={location.pathname === "/inventory/items"}>
                  Inventory Items
                  <NavLink to="/inventory/items" />
                </MenuItem> */}
                {/* <MenuItem
                  active={location.pathname === "/inventory/allRequest"}
                >
                  All Requests
                  <NavLink to="/inventory/allRequest" />
                </MenuItem> */}
                {/* <MenuItem active={location.pathname === "/user/role"}>
                  MenuItem
                  <NavLink to="/user/role" />
                </MenuItem>
                <MenuItem>
                  Cookbook
                  <NavLink to="/user/role" />
                </MenuItem>
                <MenuItem>
                  Inventory Counts
                  <NavLink to="/user/role" />
                </MenuItem>
                <MenuItem>
                  Production Events
                  <NavLink to="/user/role" />
                </MenuItem> */}
                {/* <MenuItem>
                  Waste Events
                  <NavLink to="/user/role" />
                </MenuItem> */}
              </SubMenu>
              <SubMenu
                open={open.open11}
                onOpenChange={() => openChange("open11")}
                title="Reports"
                icon={<FaClipboardList />}
              >
                <MenuItem active={match?.pathname || null}>
                  Reports
                  <NavLink exact="true" to="/reports" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={open.open12}
                onOpenChange={() => openChange("open12")}
                title="Investors"
                icon={<HiUserGroup />}
              >
                <MenuItem active={location.pathname === "/investor"}>
                  All Investors
                  <NavLink exact="true" to="/investor" />
                </MenuItem>
                <MenuItem
                  active={location.pathname === "/investor/addinvestors"}
                >
                  Add Investors
                  <NavLink exact="true" to="/investor/addinvestors" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={open.open13}
                onOpenChange={() => openChange("open13")}
                title="Notifications"
                icon={<IoNotificationsCircle />}
              >
                <MenuItem active={location.pathname === "/notifications"}>
                  All Notifications
                  <NavLink exact="true" to="/notifications" />
                </MenuItem>
                <MenuItem
                  active={
                    location.pathname === "/notifications/add_notification"
                  }
                >
                  Add Notification
                  <NavLink exact="true" to="/notifications/add_notification" />
                </MenuItem>
              </SubMenu>
              {/* <SubMenu
                open={open.open14}
                onOpenChange={() => openChange("open14")}
                title="Zomato"
                icon={<SiZomato />}
              >
                <MenuItem active={location.pathname === "/zomato/allOutlets"}>
                  All Outlets
                  <NavLink exact="true" to="/zomato/allOutlets" />
                </MenuItem>
                <MenuItem
                  active={
                    location.pathname === "/zomato/addOutlets"
                  }
                >
                  Add Outlets
                  <NavLink exact="true" to="/zomato/addOutlets" />
                </MenuItem>
              </SubMenu> */}
              {/* <SubMenu
                open={open.open16}
                onOpenChange={() => openChange("open16")}
                title="ONDC"
                icon={<img src={ondc} className="w-75"/>}
              >
                <MenuItem active={location?.pathname === "/ondc/allOndcStores"}>
                  All Stores
                  <NavLink exact="true" to="/ondc/allOndcStores" />
                </MenuItem>
                <MenuItem
                  active={
                    location?.pathname === "/ondc/addOndcStore"
                  }
                >
                  Add Stores
                  <NavLink exact="true" to="/ondc/addOndcStore" />
                </MenuItem>
              </SubMenu> */}
              <SubMenu
                open={open.open15}
                onOpenChange={() => openChange("open15")}
                title="Credit"
                icon={<MdCreditScore />}
              >
                <MenuItem active={location.pathname === "/credits"}>
                  Credits
                  <NavLink exact="true" to="/credits" />
                </MenuItem>
              </SubMenu>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </React.Fragment>
  );
};
export default Sidebar;
