import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
  CalendarIcon,
  ClipboardListIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import Unauthorized from "../../unauthorized";
import SalesByStaffReport from "./SalesByStaffReport";
import SalesByStaffDaily from "./SalesByStaffDaily";
import OrderListByStaff from "./OrderListByStaff";
import StaffLogoutReport from "./StaffLogoutReport";

const StaffReports = (props) => {
  const navigate = useNavigate();
  if (props.viewPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Row>
          <Col>
            <Button className="my-2" onClick={() => navigate("/reports")}>
              Back to All Reports Section
            </Button>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5 className="">STAFF REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="salesbystaff">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Sales by Staff</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="salesbystaffdaily">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Sales by Staff (Daily)</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="stafflogoutreport">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Staff Logout Report</div>
                </div>
              </Card>
            </NavLink>
          </Col>

          <Col>
            <NavLink to="orderlistbystaff">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Order List by Staff</div>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Routes>
            <Route
              path="salesbystaff"
              element={<SalesByStaffReport sideToggle={props.sideToggle} />}
            />
            <Route
              path="salesbystaffdaily"
              element={<SalesByStaffDaily sideToggle={props.sideToggle} />}
            />

            <Route
              path="stafflogoutreport"
              element={<StaffLogoutReport sideToggle={props.sideToggle} />}
            />
            <Route
              path="orderlistbystaff"
              element={<OrderListByStaff sideToggle={props.sideToggle} />}
            />
          </Routes>
        </Row>
      </Container>
    );
  else return <Unauthorized />;
};

export default StaffReports;
