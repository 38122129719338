import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addNewQrCode } from "./../../../store/actionCreators/Catalog/Catalog";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";
import { getBranches } from "./../../../store/actionCreators/Branch/BranchAction";

const AddQrModal = ({ show, handleClose }) => {
  const [qrname, setQrname] = useState("");
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedBranches, setSelected] = useState([]);
  const branch = useSelector((state) => state.branch);

  useEffect(() => {
    dispatch(getBranches());
  }, []);

  useEffect(() => {
    let array = [{ label: "All", value: "*" }];
    if (branch.data) {
      branch.data.data.map((item, index) => {
        array.push({ label: item.branch_name, value: item.branch_id });
      });
    }
    setOptions(array);
  }, [branch]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    return `${placeholderButtonLabel}: ${value.length} selected`;
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayBranch = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  const addqr = (e) => {
    e.preventDefault();
    let branch_id = "";
    selectedBranches.forEach((element) => {
      if (element.value != "*") {
        branch_id += element.value + ",";
      }
    });
    const finalProd = {
      table_name: qrname,
      branch_id: branch_id.slice(0, branch_id.length - 1),
    };
    console.log(finalProd);

    dispatch(addNewQrCode(finalProd));
    handleClose();
    e.preventDefault();
  };

  return (
    <React.Fragment>
      (
      <Modal
        className="remove-border"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className=" bg-light text-dark remove-border" closeButton>
          <Modal.Title>Add New QRCode</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" bg-light  remove-border">
          <div className="mx-auto mt-0">
            <form onSubmit={(e) => addqr(e)}>
              <div>
                <div className="mb-2">
                  <label
                    style={{ fontColor: "black" }}
                    onChange={(e) => setQrname(e.target.value)}
                  >
                    QRCode Name
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={qrname}
                    onChange={(e) => {
                      setQrname(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-2">{displayBranch()}</div>

                <Button className="btn btn-warning" type="submit">
                  Add
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      )
    </React.Fragment>
  );
};

export default AddQrModal;
