import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useCallback } from "react";
import EditAddonModal from "../AddCoupon/EditAddonModal";

const ViewAddonDiscountForm = ({
  branchArray,
  empArray,
  empObj,
  branchObj,
  data,
  step,
  setStep,
  setAddonsArr,
  productFullData,
  setProductFullData,
}) => {
  const dispatch = useDispatch();

  console.log(data?.coupon_products, "productArrau");
  // const [step, setStep] = useState(1);
  const groups = useSelector((state) => state.groups);
  const productData = useSelector((state) => state.products);

  const afterSaveCell = (row, cellName, cellValue) => {
    console.log({ row, cellName, cellValue }, "ccccc");
  };

  useEffect(() => {
    // console.log("SMARTSHIVA - BUG!!!", { productData });
    setProductFullData(productData?.data?.data || []);
  }, [productData]);

  const [showModal, setShowModal] = useState(false);
  const [addonData, setAddonData] = useState({});

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    console.log(row, "productArrau");
    const matchingProduct = () => {
      return (
        (data?.coupon_products || []).findIndex((i) => {
          return i?.product_list_id === row?.product_list_id;
        }) > -1
      );
    };

    return (
      <>
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Form.Check
            type="checkbox"
            checked={matchingProduct()}
            onChange={(e) => {
              if (e.target.checked) {
                setAddonData({ ...row, index: rowIndex, selected: true });
                setShowModal(true);
              }

              setProductFullData((list) => {
                list = [...list];
                list[rowIndex].selected = e.target.checked;

                return list;
              });
            }}
            disabled={true}
          />
        </div>

        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        ></div>
      </>
    );
  }
  const columns = [
    {
      dataField: "product_name",
      text: "Product Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      editable: true,
    },
    {
      dataField: "Select",
      text: "Select",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "category_name",
      order: "asc",
    },
  ];
  const pageOptions = {
    sizePerPage: 50,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <div className="page-content ">
      <div className="mb-3">
        <strong>Addon Discount</strong>{" "}
      </div>
      <Row>
        <Col className="col-12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="product_list_id"
            columns={columns}
            data={productFullData}
            paginationProps={{
              sizePerPageList: [50, 100, 200],
            }}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="product_list_id"
                columns={columns}
                data={productFullData}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="search-box chat-search-box" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="product_list_id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            cellEdit={{
                              mode: "click", // click cell to edit
                              afterSaveCell: afterSaveCell,
                              blurToSave: true,
                              beforeSaveCell: () => true,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone
                            {...paginationProps}
                            sizePerPageList={[50, 100, 200]}
                          />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone
                            {...paginationProps}
                            className="table-pagination"
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
      <EditAddonModal
        show={showModal}
        close={() => setShowModal(false)}
        data={addonData}
        setAddonsArr={setAddonsArr}
        productFullData={productFullData}
        setProductFullData={setProductFullData}
      />
      <Button
        className=" mx-2"
        type="warning"
        onClick={() => {
          setStep(step - 1);
        }}
      >
        Back
      </Button>
      <Button
        className="m-2"
        type="warning"
        onClick={() => {
          setStep(5);
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default ViewAddonDiscountForm;
