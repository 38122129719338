import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleGiftCard } from "../../../store/actionCreators/Coupons/CouponsAction";
import { GoTasklist } from "react-icons/go";

const ViewGiftCard = () => {
  const [showCustomerList, setShowCustomerList] = useState(false);
  const [custArray, setCustArray] = useState([]);
  const gift_card = useSelector((state) => state.gift_card);
  const dispatch = useDispatch();
  const params = useParams();

  const [data, setData] = useState({
    title: "",
    customer_id: "",
    description: "",
    // OTP: "",
    created_by: "Heebee",
    amount: 0,
    status: "",
    createdAt: "",
  });

  useEffect(() => {
    dispatch(getSingleGiftCard(params.gift_card_id));
  }, [params.gift_card_id]);

  console.log(data, "mein data hun");

  useEffect(() => {
    setData(gift_card?.data);
    setCustArray(gift_card?.customers);
  }, [gift_card]);

  console.log(custArray, "checkaar");

  return (
    <div className="page-content ">
      {gift_card?.data ? (
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <Card.Title className="h4 mb-4 my-auto">
                    View Gift Card Data
                  </Card.Title>
                </div>
                <Row className="mb-2">
                  <Col>
                    <label>Gift Card</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="title"
                      value={data?.title}
                    />
                  </Col>
                </Row>
                <Row className="m-auto ">
                  <label style={{ marginLeft: "-8px" }}>Customers</label>

                  <Button onClick={() => setShowCustomerList(true)}>
                    View Selected Customers List <GoTasklist />
                  </Button>
                  <Modal
                    size="sm"
                    centered
                    show={showCustomerList}
                    onHide={() => setShowCustomerList(false)}
                    aria-labelledby="example-modal-sizes-title-sm"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-sm">
                        Selected Customers
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      style={{ maxHeight: "64vh", overflowY: "scroll" }}
                    >
                      {custArray &&
                        custArray.map((c, i) => {
                          return (
                            <tr key={i}>
                              {" "}
                              <div className="border-bottom border-dark m-2">
                                <span> {c?.first_name.toUpperCase()}</span>,
                                <span>({c?.mobile_no})</span>
                              </div>
                            </tr>
                          );
                        })}
                    </Modal.Body>
                  </Modal>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <label>Description</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="description"
                      value={data?.description}
                    />
                  </Col>
                  <Col>
                    <label>Created By</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      id="created_by"
                      value={data?.created_by}
                    />
                  </Col>
                  {/* <Col>
                  <label>OTP</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    id="OTP"
                    value={data?.OTP}
                  />
                </Col> */}
                </Row>
                <Row className="mb-2">
                  <Col>
                    <label>Amount</label>
                    <input
                      disabled
                      type="number"
                      className="form-control"
                      id="amount"
                      value={data?.amount}
                    />
                  </Col>

                  <Col>
                    <label>Status:</label>
                    <Select
                      value={{
                        label: String(data?.status),
                        value: data?.status,
                      }}
                      options={[
                        { label: "True", value: true },
                        { label: "False", value: false },
                      ]}
                      isDisabled={true}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <div>Please wait while fetching details...</div>
      )}
    </div>
  );
};

export default ViewGiftCard;
