import React, { useState } from "react";
import {
  getAllBranchesInventory,
  getSoldProductReport,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import SoldProductReportTable from "./SoldProductReportTable";
import { toast } from "react-toastify";
import Unauthorized from "../../unauthorized";
import Loader from "../../Loader/LoaderTable";
import axios from "axios";

const SoldProductReport = (props) => {
  const soldProductReportdata = useSelector(
    (state) => state.sold_product_report
  );
  const AllBranchList = useSelector((state) => state.get_all_branches);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [branchSelected, setBranchSelected] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [branchArr, setBranchArr] = useState([{}]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllBranchesInventory());
  }, []);

  useEffect(() => {
    const array =
      AllBranchList?.data?.data?.map(({ branch_name, branch_id }) => ({
        label: branch_name,
        value: branch_id,
      })) ?? [];
    setBranchArr(array);
  }, [AllBranchList]);

  useEffect(() => {
    setDefaultData();
  }, [soldProductReportdata]);

  const setDefaultData = () => {
    if (AllBranchList.data && AllBranchList.data?.data) {
      const branchArrClone = AllBranchList?.data?.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));

      setBranchArr(branchArrClone);
    }
  };

  const onShowReport = () => {
    const today = new Date();
    const start_date = new Date(startDate)
    const end_date = new Date(endDate)
    if(start_date > end_date || start_date > today){
      toast.error("please select valid date");
    }
    else if (!!startDate && !!endDate && !!branchSelected.value) {
      // console.log(branchSelected.value);
      setShowReport(true);
      setLoading(true);
      dispatch(getSoldProductReport(startDate, endDate, branchSelected.value)).finally(()=>{setLoading(false)})
    } else {
      toast.error("please select branch & valid date");
    }
  };

  const onRefresh = async() =>{
    setLoading(true)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/create_sold_product_report`
      );

      if (res.data.status === 'success') {
        // console.log("refresed");
        setLoading(false)
        onShowReport()
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false)
    }
  }

  const onExport = async() => {
    setLoading(true);
    if (!!startDate && !!endDate && !!branchSelected.value) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/export_sold_products_report`,
          {
            start_date:startDate,
            end_date:endDate,
            branch_id:branchSelected.value
          }
        );
        if (response && response.data?.status !== "failure") {
          window.open(response?.data?.url);
          toast.success(`FILE EXPORTED SUCCESSFULLY`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false)
        }
        if (response?.data?.status === "failure") {
          toast.error(`EXPORT DATA NOT FOUND`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false)
        }
        return response.data.data;
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      setLoading(false)
      toast.error("please select branch & valid date");
    }
  };

  if (props.viewPermission) {
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div>
          {" "}
          <Row className="my-2 mb-5">
            <Col>
              <Card.Header>
                {" "}
                <strong>Report On: </strong> Stock detail by Product Options of
                orders
              </Card.Header>
            </Col>
            <Col xl="4" className="d-flex">
              <Button
                className="btn btn-warning my-auto"
                style={{ marginLeft: "auto" }}
                onClick={onShowReport}
              >
                Show Report
              </Button>
              <Button
                className="btn btn-warning my-auto"
                style={{ marginLeft: "auto" }}
                onClick={onExport}
              >
                Export
              </Button>
              <Button
                className="btn btn-warning my-auto"
                style={{ marginLeft: "auto" }}
                onClick={onRefresh}
              >
                Refresh
              </Button>
            </Col>
          </Row>
          <Row className="mt-4  mb-5">
            <Col>
              <Card>
                <Card.Header>Select Filters</Card.Header>
                <Card.Body>
                  <Form>
                    <Table style={{ width: "50%" }}>
                      <tbody>
                        <tr>
                          <td>Select Date from:</td>
                          <td className="py-2">
                            <Form.Control
                              type="date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Select Date to:</td>
                          <td className="py-2">
                            <Form.Control
                              type="date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>Branch:</td>
                          <td className="">
                            <Select
                              value={branchSelected}
                              onChange={(e) => setBranchSelected(e)}
                              options={[{label: 'ALL', value: 'All'}, ...branchArr]}
                              placeholder="Select an option..."
                              styles={{
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: "gray",
                                  fontStyle: "italic",
                                }),
                                menuList: (provided) => ({
                                  ...provided,
                                  zIndex: 999,
                                }),
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        {showReport && (
          <div>
            {loading ? 
              <Loader />  // Display loader when loading is true
             :
            soldProductReportdata?.data?.status === "failure" ? (
              <strong>No Data Found</strong>
            ) : (
              <div>
                {startDate === endDate ? <div className="text-[19px]"><b>{startDate}</b></div> : <div className="text-[240px]"><b>{`${startDate} TO ${endDate}`}</b></div>}
                {(soldProductReportdata?.data?.arr || []).map((item, i) => {
                  return (
                    <>
                    <SoldProductReportTable
                      key={i}
                      productReportdata={item}
                      addOnList={
                        item.addon || []
                      }
                    />
                    </>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default SoldProductReport;
