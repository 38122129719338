import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteBranchInvestor } from "../../store/actionCreators/Investors/InvestorsAction";
import AddBranchInvestor from "./AddBranchInvestor";
import EditBranchInvestor from "./EditBranchInvestor";
// import AddcategoryModal from "../../Branch/IndividualFranchise/AddCategoryModal";

const UpdateInvestorBranchModal = ({ show, data, close }) => {
  //   const [showProductModal, setShowPrModal] = useState(false);
  const dispatch = useDispatch();

  const [showAddProductsModal, setShowAddProductModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleClose = () => {
    setShowAddProductModal(false);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const onDelete = (id) => {
    dispatch(deleteBranchInvestor(id));
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Investor Branches </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column ">
            <table>
              <tr>
                <th>Investor Branch Id</th>
                <th>Branch Name</th>
                <th>Percentage Cut</th>
              </tr>
              {!data?.investors_branches ? (
                <div>No data available</div>
              ) : (
                !!data &&
                data?.investors_branches.map((item, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td key={i} className="m-2">
                          {item?.investors_branch_id}
                        </td>
                        <td className="m-2">{item?.branch.branch_name}</td>
                        <td className="m-2">{item?.percentage_cut}</td>

                        <button
                          type="button"
                          onClick={() => setShowEditModal(true)}
                          className="btn btn-warning m-0 p-0 ms-3"
                          style={{
                            width: "81px",
                            height: "30px",
                          }}
                        >
                          <span className="font-bold text-2xl">Edit</span>
                        </button>
                        <EditBranchInvestor
                          data={item}
                          show={showEditModal}
                          close={handleEditClose}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            // console.log(item)
                            dispatch(
                              deleteBranchInvestor(item?.investors_branch_id)
                            );
                          }}
                          className="btn btn-danger m-0 p-0 ms-3"
                          style={{
                            width: "81px",
                            height: "30px",
                            // borderRadius: "50%",
                          }}
                        >
                          <span className="font-bold text-2xl">Delete</span>
                        </button>
                      </tr>
                    </>
                  );
                })
              )}
            </table>
          </div>

          <button
            className="btn btn-warning mt-2 mb-2"
            onClick={() => setShowAddProductModal(true)}
          >
            Add+ more Branches
          </button>
          <AddBranchInvestor
            data={data}
            show={showAddProductsModal}
            close={handleClose}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateInvestorBranchModal;
