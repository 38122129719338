import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import HISPagination from "./HISPagination";
import { CSVLink } from "react-csv";
import axios from "axios";
import { getSalesByLocation } from "../../../store/actionCreators/Reports/LocationReportsAction";

const HighestItemSell = (props) => {
  const dispatch = useDispatch();
  const sblReport = useSelector((state) => state.sales_by_location);
  const branch = useSelector((state) => state.branch);
  const page = useSelector((state) => state.sbl_page);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });

  const [query, setQuery] = useState({
    employee_id: "All",
    branch_id: "All",
    status: "All",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });
  const [empSelected, setEmpSelected] = useState({
    label: "All",
    value: "All",
  });
  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    dispatch(getSalesByLocation(query));
  }, [page]);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  useEffect(() => {
    if (sblReport.data && sblReport.data.status === "success") {
      const productDataClone = sblReport.data.data.map((r) => {
        return {
          ...r,
          branch_name: r.branches.branch_name,
        };
      });
      setProductData(productDataClone);
    } else {
      setProductData([]);
    }
  }, [sblReport]);

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }
  };

  const onShowReport = () => {
    const queryClone = { ...query };
    queryClone.branch_id = branchSelected.value;
    queryClone.employee_id = empSelected.value;
    dispatch(getSalesByLocation(queryClone));
  };

  const columns = [
    {
      dataField: "branch_name",
      text: "Branch",
      sort: true,
    },
    {
      dataField: "category",
      text: "Category",
      sort: true,
    },
    {
      dataField: "item",
      text: "Item",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "full_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  const orderStatusOptions = [
    { label: "All", value: "All" },
    { label: "Preparing", value: "Preparing" },
    { label: "Hold", value: "Hold" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const categoryOptions = [{ label: "All", value: "All" }];
  return (
    // <Container
    //   fluid
    //   className={props.sideToggle === true ? "closeDash" : "openDash"}
    // style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    // >
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5
            style={{ textTransform: "capitalize" }}
            className="my-auto text-capitalize"
          >
            Highest Item selling summary
          </h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Category:</td>
                      <td className="">
                        <Select
                          options={categoryOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Item:</td>
                      <td className="">
                        <Select
                          options={categoryOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Order status:</td>
                      <td className="">
                        <Select
                          options={orderStatusOptions}
                          value={{ label: query.status, value: query.status }}
                          onChange={(e) =>
                            setQuery({ ...query, status: e.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Branch:</td>
                      <td className="">
                        <Select
                          options={branchArr}
                          value={branchSelected}
                          onChange={(e) => setBranchSelected(e)}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Col className="d-flex">
          <label
            className="my-auto "
            style={{ marginLeft: "auto", marginRight: "0.5rem" }}
          >
            Export to:
          </label>
          <Select
            options={[{ label: "xls", value: "xls" }]}
            value={{ label: "xls", value: "xls" }}
          />
          <Button className="btn btn-warning" style={{ marginLeft: "1rem" }}>
            <CSVLink
              data={productData.map((p) => {
                return {
                  Branch: p.branch_name,
                  paid_price: p.paid_price,
                  "Total orders": p.order_count,
                };
              })}
              filename={"report.csv"}
              style={{ textDecoration: "none", color: "black" }}
            >
              Export
            </CSVLink>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="branch_id"
                columns={columns}
                data={productData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="branch_id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  srText=""
                                  {...toolkitProps.searchProps}
                                  onChange={(e) => {
                                    console.log(e);
                                  }}
                                />
                                <i className="search-box chat-search-box" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="branch_id"
                                responsive
                                bordered={false}
                                striped={false}
                                // defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <HISPagination pageNum={1} />
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
    // </Container>
  );
};

export default HighestItemSell;
