import React, { useState, useEffect } from "react";
import { Accordion, Col, Container, Row, Card } from "react-bootstrap";
import Unauthorized from "../../unauthorized";
import { useSelector } from "react-redux";

const AddAddons = (props) => {
  const { singleAddon: currentAddon } = props;
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="row d-flex justify-content-center">
        <div className="form-container">
          <div className="form-head">Add Addons</div>
          <div className="form-body">
            <form>
              <Row>
                <Col>
                  {" "}
                  <div className="mb-2 p-2">
                    <label className="form-label">Select Addons</label>
                    {props.displayCategory()}
                  </div>
                </Col>
                <Col>
                  <Card
                    className="remove-border"
                    style={{ display: props.displayAddon }}
                  >
                    <Card.Header
                      className=" bg-light text-dark remove-border"
                      closeButton
                    >
                      <Card.Title>
                        {currentAddon?.title?.toUpperCase()}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className=" bg-light text-light remove-border">
                      <div className="d-flex text-dark">
                        <p className="fw-bold me-2">Addon ID :</p>
                        <p>{currentAddon?.add_ons_id}</p>
                      </div>
                      <div className="d-flex text-dark">
                        <p className="fw-bold me-2">Addon Type :</p>
                        <p>{currentAddon?.add_on_type}</p>
                      </div>
                      <div className="d-flex text-dark">
                        <p className="fw-bold me-2">CreatedAt :</p>
                        <p>{currentAddon?.createdAt}</p>
                      </div>
                      <table className="table table-borderless indi-table mb-0">
                        <tbody>
                          <tr style={{ paddingTop: "3rem" }}>
                            <th style={{ width: "8vw" }}>Addon Options</th>
                            <th style={{ textAlign: "center" }}>TITLE</th>
                            <th>PRICE</th>
                            <th>SKU</th>
                          </tr>
                          {currentAddon?.add_on_options
                            ? currentAddon?.add_on_options.map(
                                (item, index) => {
                                  return (
                                    <tr>
                                      <th>{index + 1}</th>{" "}
                                      <td style={{ textAlign: "center" }}>
                                        {item.title}
                                      </td>{" "}
                                      <td>{item.price}</td> <td>{item.sku}</td>
                                    </tr>
                                  );
                                }
                              )
                            : ""}
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  {" "}
                  <div className="mb-2 p-2">
                    <button
                      className="btn btn-primary me-2  "
                      onClick={props.handleBackSecondForm}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary me-2  "
                      onClick={props.handleNextSecondForm}
                    >
                      Next
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AddAddons;
