export const SalesByPaymentMethodReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_PM":
      return state;
    case "SET_SALES_BY_PM":
      return action.res;
    default:
      return state;
  }
};

export const walletCreditHistoryReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_WALLET_TRANSACTION_REPORT":
      return state;
    case "SET_WALLET_TRANSACTION_REPORT":
      return action.res;
    default:
      return state;
  }
};

export const SBPMPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_SBPM_PAGE":
      return state;
    case "SET_SBPM_PAGE":
      return action.page;
    default:
      return state;
  }
};

export const SalesByPaymentMethodDailyReducer = (state = "", action) => {
  switch (action.type) {
    case "GET_SALES_BY_PMD":
      return state;
    case "SET_SALES_BY_PMD":
      return action.res;
    default:
      return state;
  }
};

export const SBPMDPagination = (state = 1, action) => {
  switch (action.type) {
    case "GET_SBPMD_PAGE":
      return state;
    case "SET_SBPMD_PAGE":
      return action.page;
    default:
      return state;
  }
};
