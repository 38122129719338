import React, { useState, useEffect, createRef } from "react";
import {
  CurrencyRupeeIcon,
  DocumentTextIcon,
  CashIcon,
  PresentationChartLineIcon,
  ViewGridAddIcon,
  PresentationChartBarIcon,
  ClipboardCopyIcon,
  ClipboardListIcon,
  ChartSquareBarIcon,
  ChartBarIcon,
  ClipboardCheckIcon,
  FastForwardIcon,
  HandIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { Row, Col, Container, Button, NavLink } from "react-bootstrap";
import { useScreenshot } from "use-react-screenshot";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import Loader from "./../../Loader/Loader";
import { useNavigate } from "react-router-dom";

const RenderReport = (props) => {
  const logout_report_data = useSelector((state) => state.logout_report_data);
  const navigate = useNavigate();
  const [report, setReport] = useState({});
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [loading, setLoading] = useState(false);
  const getImage = () => takeScreenshot(ref.current);

  useEffect(() => {
    setReport({});
  }, []);

  const downloadImage = () => {
    saveAs(image, "image.jpg"); // Put your image url here.
  };

  useEffect(() => {
    if (image) downloadImage();
  }, [image]);

  useEffect(() => {
    setLoading(false);
    if (logout_report_data && logout_report_data.data) {
      setReport(logout_report_data.data);
    }
  }, [logout_report_data]);

  if (!loading)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div></div>
        <div className=" m-[5px] px-3" ref={ref}>
          <Row className="row justify-content-around flex-wrap gap-3 mb-3">
            <Col className="d-flex">
              <Button class="btn btn-primary mr-2" onClick={() => navigate(-1)}>
                Back
              </Button>

              <Button
                type="button"
                class="btn btn-warning my-auto"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  console.log("Screenshot taken");
                  getImage();
                }}
              >
                Print
              </Button>
            </Col>
          </Row>
          <Row className="row justify-content-around flex-wrap gap-3">
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Final cash
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.final_cash * 100) / 100 || 0 : ""}
              </div>
              <div>
                <CurrencyRupeeIcon style={{ height: "60px", width: "60px" }} />
              </div>
            </Col>

            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div className="text-black font-weight-bold">
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Final Sales
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.final_sales * 100) / 100 || 0 : ""}
              </div>
              <div>
                <PresentationChartLineIcon
                  style={{ height: "60px", width: "60px" }}
                />
              </div>
            </Col>
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Petty Cash
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.init_cash * 100) / 100 || 0 : ""}
              </div>
              <div>
                <CashIcon style={{ height: "60px", width: "60px" }} />
              </div>
            </Col>
          </Row>

          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Wallet
                  <br />
                  Credits
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report
                  ? Math.round(report.wallet_credits * 100) / 100 || 0
                  : ""}
              </div>
              <div>
                <ViewGridAddIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>

            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Wallet
                  <br />
                  Sales
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>

                {report ? Math.round(report.wallet_sales * 100) / 100 || 0 : ""}
              </div>
              <div>
                <PresentationChartBarIcon
                  style={{ height: "55px", width: "55px" }}
                />
              </div>
            </Col>
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Wallet
                  <br />
                  Cash
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.wallet_cash * 100) / 100 || 0 : ""}
              </div>
              <div>
                <CashIcon
                  className="bg-white"
                  style={{ height: "60px", width: "60px" }}
                />
              </div>
            </Col>
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Online
                  <br />
                  Delivery
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>

                {report
                  ? Math.round(report.online_delivery * 100) / 100 || 0
                  : ""}
              </div>
              <div>
                <ClipboardCopyIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>
          </Row>

          <Row className="d-flex justify-content-around flex-wrap my-5 gap-4">
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Order
                  <br />
                  Revenue
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report
                  ? Math.round(report.order_revenue * 100) / 100 || 0
                  : ""}
              </div>
              <div>
                <ClipboardListIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Digital
                  <br />
                  Sales
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>

                {report
                  ? Math.round(report.digital_sales * 100) / 100 || 0
                  : ""}
              </div>
              <div>
                <ChartSquareBarIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Cash
                  <br />
                  Sales
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.cash_sales * 100) / 100 || 0 : ""}
              </div>
              <div>
                <ChartBarIcon style={{ height: "50px", width: "50px" }} />
              </div>
            </Col>
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Delivery
                  <br />
                  Charges
                </h3>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report
                  ? Math.round(report.delivery_charges * 100) / 100 || 0
                  : ""}
              </div>
              <div>
                <CurrencyRupeeIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>
          </Row>

          <Row className="d-flex justify-content-around flex-wrap gap-3">
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h4 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Total
                  <br />
                  Orders
                </h4>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.total_orders * 100) / 100 || 0 : ""}
              </div>
              <div>
                <DocumentTextIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>
            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h4 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Preparing
                  <br />
                  Orders
                </h4>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.total_preparing_orders * 100) / 100 || 0 : ""}
           
              </div>
              <div>
                <FastForwardIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>

            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h4 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Onhold
                  <br />
                  Orders
                </h4>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report ? Math.round(report.total_on_hold_orders * 100) / 100 || 0 : ""}
             
              </div>
              <div>
                <HandIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>

            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h4 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Completed
                  <br />
                  Orders
                </h4>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report.total_completed_orders}
              </div>
              <div>
                <ClipboardCheckIcon style={{ height: "55px", width: "55px" }} />
              </div>
            </Col>

            <Col className="rounded bg-white px-4 py-3 rounded-xl d-flex justify-content-between align-items-center border-l-4 border-white relative overflow-hidden shadow shadow-info hover:shadow-lg hover:shadow-info hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h4 className="bg-white text-info font-weight-bold text-2xl uppercase leading-none mb-2">
                  Cancelled
                  <br />
                  Orders
                </h4>
                <span className="text-black font-weight-bold text-2xl">
                  &#8377;
                </span>
                {report.total_cancelled_orders}
              </div>
              <div>
                <XCircleIcon style={{ height: "60px", width: "60px" }} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  return <Loader />;
};

export default RenderReport;
