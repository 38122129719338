import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import CLBGPagination from "./CLBGPagination";
import { CSVLink } from "react-csv";
import Async from "react-select/async";
import axios from "axios";
import {
  getCustomerGroupChangeLogs,
  getCustomerListByGroupReport,
  setCLBGPagination,
} from "./../../../store/actionCreators/Reports/CustomerReportAction";
import Loader from "../../Loader/LoaderTable";
import ScrollButton from "../../scrolltotop/ScrollButton";
import { toast } from "react-toastify";

const CustomerGroupChangeLogs = (props) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.clbg_page);
  const branch = useSelector((state) => state.branch);
  const cgclReport = useSelector((state) => state.customer_group_changelogs);
  const [RecordPage, setRecordPage] = useState(1);
  const [isDataFetched, setisDataFetched] = useState(false);
  const [showReportClicked, setShowReportClicked] = useState(false);
  const [productData, setProductData] = useState([]);
  const [records, setRecords] = useState(100);
  const [branchArr, setBranchArr] = useState({
    label: "All",
    value: "All",
  });
  const [custSelected, setCustSelected] = useState({
    label: "All",
    value: "All",
  });

  const [prevCatgorySelected, setPrevCategorySelected] = useState({
    label: "All",
    value: "All",
  });

  const [currCatgorySelected, setCurrCategorySelected] = useState({
    label: "All",
    value: "All",
  });

  const [customerCategoryOptions, setCustomerCategoryOptions] = useState([]);

  const [query, setQuery] = useState({
    customer_id: "All",
    prev_catg: "All",
    current_catg: "All",
    start: new Date().toISOString().split("T")[0],
    end: new Date().toISOString().split("T")[0],
  });

  const [branchSelected, setBranchSelected] = useState({
    label: "All",
    value: "All",
  });

  const sendGetRequest = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/fetch_customer_count_roles`
      );
      setCustomerCategoryOptions(resp?.data?.data || []);
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  useEffect(() => {
    sendGetRequest();
  }, []);

  useEffect(() => {
    const queryClone = { ...query };
    queryClone.customer_id = custSelected.value;

    if (prevCatgorySelected.value !== "All") {
      queryClone.prev_catg = prevCatgorySelected.value;
    } else {
      delete queryClone.prev_catg;
    }

    if (currCatgorySelected.value !== "All") {
      queryClone.current_catg = currCatgorySelected.value;
    } else {
      delete queryClone.current_catg;
    }

    console.log("queryClone", queryClone);

    dispatch(getCustomerGroupChangeLogs(queryClone, page, records));
  }, [page]);

  useEffect(() => {
    setDefaultData();
  }, [branch]);

  useEffect(() => {
    if (cgclReport.data && cgclReport.data.status === "success") {
      setisDataFetched(true);

      const productDataClone = cgclReport.data.data.map((e) => {
        return {
          ...e,
          customer_name: e.customer?.first_name,
          createdAt: new Date(e.createdAt).toLocaleString(),
        };
      });
      setProductData(productDataClone);
    } else if (cgclReport?.data?.status === "failure") {
      setProductData([]);
      setisDataFetched(true);
    }
  }, [cgclReport]);

  const setDefaultData = () => {
    if (branch.data && branch.data.data) {
      const branchArrClone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      branchArrClone.unshift({ label: "All", value: "All" });
      setBranchArr(branchArrClone);
    }
  };

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/search_cust?search=${emp}`
      );
      if (res.data && res.data?.data) {
        const data = res.data?.data.map((e) => ({
          label: `${e.mobile_no}`,
          value: e.customer_id,
        }));
        data.unshift({ label: "All", value: "All" });

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const onShowReport = () => {
    setProductData([]);
    setRecordPage(1);
    console.log(query, "QUERY");
    const queryClone = { ...query };
    queryClone.customer_id = custSelected.value;

    if (prevCatgorySelected.value !== "All") {
      queryClone.prev_catg = prevCatgorySelected.value;
    } else {
      delete queryClone.prev_catg;
    }

    if (currCatgorySelected.value !== "All") {
      queryClone.current_catg = currCatgorySelected.value;
    } else {
      delete queryClone.current_catg;
    }

    dispatch(getCustomerGroupChangeLogs(queryClone, 1, records));
    setShowReportClicked(true);
  };

  const queryClone = { ...query };
  queryClone.customer_id = custSelected.value;

  const exportCGCLCSV = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/employee/export_customer_group_change_logs`,
        queryClone
      );

      if (response && response.data?.status !== "failure") {
        window.open(response?.data?.url);
      }
      if (response?.data?.status === "failure") {
        toast.error(`EXPORT DATA NOT FOUND`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      return response.data.data;
    } catch (error) {}
  };

  const columns = [
    {
      dataField: "customer_name",
      text: "Customer Name",
      sort: true,
    },
    {
      dataField: "prev_catg",
      text: "Previous Category",
      sort: true,
    },

    {
      dataField: "current_catg",
      text: "Current Category",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    // {
    //   dataField: "total_spend",
    //   text: "Total Spend",
    //   sort: true,
    // },
    {
      dataField: "customer.mobile_no",
      text: "Mobile No.",
      sort: true,
    },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    // },
    // {
    //   dataField: "date_of_birth",
    //   text: "Date of Birth",
    //   sort: true,
    // },
    // {
    //   dataField: "gender",
    //   text: "gender",
    //   sort: true,
    // },
    // {
    //   dataField: "branch",
    //   text: "Branch",
    //   sort: true,
    // },
    // {
    //   dataField: "customer_type",
    //   text: "Customer Type",
    //   sort: true,
    // },
    // {
    //   dataField: "wallet_balance",
    //   text: "Wallet Balance",
    //   sort: true,
    // },
  ];

  const pageOptions = {
    sizePerPage: productData.length,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <Row className="d-flex mb-2 mt-4">
        <Col xl="10 my-auto">
          <h5 className="my-auto">CUSTOMER GROUP CHANGE LOGS</h5>
        </Col>
        <Col xl="2" className="d-flex">
          <Button
            className="btn btn-warning my-auto"
            style={{ marginLeft: "auto" }}
            onClick={onShowReport}
          >
            Show Report
          </Button>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Filter</Card.Header>
            <Card.Body>
              <Form>
                <Table style={{ width: "50%" }}>
                  <tbody>
                    <tr>
                      <td>From:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.start}
                          onChange={(e) =>
                            setQuery({ ...query, start: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>To:</td>
                      <td className="py-2">
                        <Form.Control
                          type="date"
                          value={query.end}
                          onChange={(e) =>
                            setQuery({ ...query, end: e.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Customer No:</td>
                      <td className="">
                        <Async
                          defaultOptions={[{ label: "", value: "" }]}
                          cacheOptions={[{ label: "All", value: "All" }]}
                          isLoading={false}
                          loadOptions={(e) => loadData(e)}
                          value={custSelected}
                          onChange={(e) => setCustSelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Previous Category:</td>
                      <td className="">
                        <Select
                          options={[
                            { label: "All", value: "All" },
                            ...(customerCategoryOptions || []).map(
                              (customerCategory) => ({
                                label: customerCategory.customer_type,
                                value: customerCategory.customer_type,
                              })
                            ),
                          ]}
                          value={prevCatgorySelected}
                          onChange={(e) => setPrevCategorySelected(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Current Category:</td>
                      <td className="">
                        <Select
                          options={[
                            { label: "All", value: "All" },
                            ...(customerCategoryOptions || []).map(
                              (customerCategory) => ({
                                label: customerCategory.customer_type,
                                value: customerCategory.customer_type,
                              })
                            ),
                          ]}
                          value={currCatgorySelected}
                          onChange={(e) => setCurrCategorySelected(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showReportClicked ? (
        <React.Fragment>
          <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Col className="d-flex">
              <label
                className="my-auto "
                style={{ marginLeft: "auto", marginRight: "0.5rem" }}
              >
                Export to:
              </label>
              <Select
                options={[{ label: "xls", value: "xls" }]}
                value={{ label: "xls", value: "xls" }}
              />
              <Button
                className="btn btn-warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  exportCGCLCSV();
                }}
              >
                Export
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="employee_id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="employee_id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      srText=""
                                      {...toolkitProps.searchProps}
                                      onChange={(e) => {
                                        console.log(e);
                                      }}
                                    />
                                    <i className="search-box chat-search-box" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="employee_id"
                                    responsive
                                    bordered={true}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {!productData.length > 0 &&
                                  cgclReport?.data?.status !== "failure" ? (
                                    <Loader />
                                  ) : (
                                    ""
                                  )}
                                  {cgclReport?.data?.status === "failure" &&
                                    !cgclReport?.data?.data &&
                                    !!isDataFetched &&
                                    "No data found"}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <CLBGPagination
                                  pageNum={Math.ceil(
                                    cgclReport.data
                                      ? cgclReport.data.total_count
                                        ? cgclReport.data.total_count / records
                                        : 1
                                      : 1
                                  )}
                                  page={RecordPage}
                                  setRecordPage={setRecordPage}
                                />
                              </Col>
                              <Col className="d-flex">
                                <div style={{ marginLeft: "auto" }}>
                                  <Select
                                    options={[
                                      { label: "100", value: "100" },
                                      { label: "500", value: "500" },
                                      { label: "1000", value: "1000" },
                                    ]}
                                    onChange={(e) => {
                                      dispatch(setCLBGPagination(1));
                                      dispatch(
                                        getCustomerGroupChangeLogs(
                                          query,
                                          1,
                                          e.value
                                        )
                                      );
                                      setRecords(e.value);
                                    }}
                                    className="ml-auto"
                                    value={{ label: records, value: records }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ScrollButton />
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
    // </Container>
  );
};

export default CustomerGroupChangeLogs;
