import React, { useState, useEffect } from "react";
import { Card, Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import "../../css/miniWidgets.css";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { FetchRevenueAnalytics } from "./../../store/actionCreators/dashboard/dasboardActions";
import MonthPicker from "./MonthPicker";

import "react-month-picker/css/month-picker.css";
const RevenueAnalytics = () => {
  const dispatch = useDispatch();
  const revenue_analytics = useSelector((state) => state.dashboard_revenue);
  const branch = useSelector((state) => state.branch);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [branches, setBranches] = useState([]);
  const [isVisible, setVisibility] = useState(false);
  const [monthYear, setMonthYear] = useState({ year: "2022", month: "3" });

  const handleOnDismiss = () => {
    setVisibility(false);
  };

  const handleOnChange = (year, month) => {
    setMonthYear({ year, month });
    setVisibility(false);
  };

  const getMonthValue = () => {
    const month = monthYear && monthYear.month ? monthYear.month : 0;
    const year = monthYear && monthYear.year ? monthYear.year : 0;

    return month && year ? `${month}-${year}` : "Select Month";
  };

  useEffect(() => {
    if (branches) {
      setSelectedBranch(branches[0]);
    }
  }, []);
  useEffect(() => {
    if (branch.data && branch.data.status === "success") {
      const clone = branch.data.data.map((b) => ({
        label: b.branch_name,
        value: b.branch_id,
      }));
      setBranches(clone);
      setSelectedBranch(clone[0]);
    }
  }, [branch]);

  useEffect(() => {
    if (selectedBranch.value) {
      dispatch(
        FetchRevenueAnalytics({
          ...filter,
          branch_id: selectedBranch.value,
        })
      );
      setDisplayMonthYear(
        `${dic[new Date().getMonth() + 1]}, ${new Date().getFullYear()}`
      );
    }
  }, [selectedBranch]);

  useEffect(() => {
    console.log(revenue_analytics);
    if (revenue_analytics.data && revenue_analytics.data.status === "success") {
      setOptions({
        ...options,
        labels: revenue_analytics.data.data.x.map((i) => i + ""),
      });
      setSeries([
        {
          name: displayMonthYear,
          type: "column",
          data: revenue_analytics.data.data.data,
        },
      ]);
    }
  }, [revenue_analytics]);
  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 3],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80px",
      },
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
    },
    colors: ["#1CBB8C"],
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    "yaxis": [{
      "labels": {
          "formatter": function (val) {
            return val.toFixed(2)
          }
      }
    }]
  });
  const [series, setSeries] = useState([
    {
      name: "2021",
      type: "column",
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
    },
  ]);

  const callAPI = (OB) => {
    console.log(OB);
    dispatch(FetchRevenueAnalytics({ ...OB, branch_id: selectedBranch.value }));
  };

  const lang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    from: "From",
    to: "To",
  };

  const initialMonthValue = new Date().getMonth() + 1;
  // console.log(month, "ccc");
  const initialYearValue = new Date().getFullYear();

  const [value1, setValue1] = React.useState({
    year: initialYearValue,
    month: Number(initialMonthValue),
  });
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const monthPickerRef1 = React.useRef(null);
  const [modal, setModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("TODAY");
  const [year, setYear] = useState(new Date().getFullYear());
  const [filter, setFilter] = useState({ type: "TODAY" });
  const [displayMonthYear, setDisplayMonthYear] = useState(
    new Date().getFullYear()
  );

  const years = function (startYear) {
    var currentYear = new Date().getFullYear();
    var years = [];
    var startYear = 2021;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
  }

  const customOnClick = () => {
    if (value1 === "" || value2 === "") {
      toast.error(`Please select a date range`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const val1 = new Date(value2);
    const val2 = new Date(value3);
    const months = monthDiff(val1, val2);
    if (Math.abs(months) > 3) {
      toast.error(`Range Should be within 3 months`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setFilter({ start: value2, end: value3, type: "CUSTOM" });
    callAPI({ start: value2, end: value3, type: "CUSTOM" });
    setDisplayMonthYear(`${value2} - ${value3}`);
    setModal(false);
  };

  const modalBody = {
    MONTH: (
      <div>
        <div className="d-flex mx-auto">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Month{" "}
          </label>
          <MonthPicker
            className="my-auto "
            lang={lang.months}
            ref={monthPickerRef1}
            value1={value1}
            setMonth={(e) => setValue1({ year: e.year, month: e.month })}
          ></MonthPicker>
        </div>
        <div className="d-flex mt-3">
          <Button
            className="mx-auto"
            onClick={() => {
              setFilter({
                type: "MONTH",
                year: value1.year,
                month: value1.month,
              });
              callAPI({
                type: "MONTH",
                year: value1.year,
                month: value1.month,
              });
              setDisplayMonthYear(`${dic[value1.month]}, ${value1.year}`);
              setModal(false);
            }}
          >
            Show
          </Button>
        </div>
      </div>
    ),

    YEAR: (
      <div className="mx-auto">
        <div className="d-flex">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Year{" "}
          </label>
          <Select
            value={{ label: year, value: year }}
            onChange={(e) => setYear(e.value)}
            options={[...years().map((y) => ({ label: y, value: y }))]}
          />
        </div>
        <div className="d-flex mt-3">
          <Button
            className="mx-auto"
            onClick={() => {
              setFilter({ type: "YEAR", year });
              callAPI({ type: "YEAR", year });
              setDisplayMonthYear(year);
              setModal(false);
            }}
          >
            Show
          </Button>
        </div>
      </div>
    ),

    CUSTOM: (
      <div>
        <div style={{ display: "flex" }} className="mx-auto">
          <label className="my-auto " style={{ marginRight: "1rem" }}>
            Select Range{" "}
          </label>
          <input
            type="date"
            value={value2}
            onChange={(e) => setValue2(e.target.value)}
          />
          -
          <input
            type="date"
            value={value3}
            onChange={(e) => setValue3(e.target.value)}
          />
        </div>
        <div className="d-flex mt-3">
          <Button className="mx-auto" onClick={() => customOnClick()}>
            Show
          </Button>
        </div>
      </div>
    ),
  };

  const dic = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const filterObj = {
    TODAY: "Today",
    L7D: "Last 7 days",
    MONTH: "Month",
    YEAR: "Year",
    ALLYEARS: "All Years",
    CUSTOM: "Custom",
  };

  return (
    <React.Fragment>
      <Card className="px-0">
        <Card.Header>
          <div className="d-flex justify-content-center align-items-center">
            <h4 className="m-0">Branch Revenue Analytics</h4>
            <Modal onHide={() => setModal(false)} show={modal}>
              <Modal.Header closeButton>
                <Modal.Title>Select Filter</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex">{modalBody[selectedFilter]}</div>
              </Modal.Body>
            </Modal>
            <div style={{ marginLeft: "auto", display: "flex" }}>
              <div className="my-auto" style={{ marginRight: "1rem" }}>
                {displayMonthYear}
              </div>
              <div className="mr-2">
                <Select
                  options={[
                    { label: "Today", value: "TODAY" },
                    { label: "Last 7 days", value: "L7D" },
                    { label: "Month", value: "MONTH" },
                    { label: "Year", value: "YEAR" },
                    { label: "All Years", value: "ALLYEARS" },
                    { label: "Custom", value: "CUSTOM" },
                  ]}
                  value={{
                    value: selectedFilter,
                    label: filterObj[selectedFilter],
                  }}
                  onChange={(e) => {
                    if (
                      e.value === "MONTH" ||
                      e.value === "YEAR" ||
                      e.value === "CUSTOM"
                    ) {
                      setModal(true);
                    } else {
                      if (e.value != "ALLYEARS")
                        setDisplayMonthYear(
                          `${
                            dic[new Date().getMonth() + 1]
                          }, ${new Date().getFullYear()}`
                        );
                      else {
                        setDisplayMonthYear(`All`);
                      }
                      setFilter({ type: e.value });
                      callAPI({ type: e.value });
                    }
                    setSelectedFilter(e.value);
                  }}
                />
              </div>
              <div>
                <Select
                  options={branches}
                  value={selectedBranch}
                  onChange={(e) => {
                    setSelectedBranch(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <div id="line-column-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="280"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card
        className="mb-4 text-muted d-flex  justify-content-center"
        style={{
          width: "100%",
          // height: "150px",
          boxShadow: " 0 2px 4px rgb(0 0 0 / 8%)",
        }}
      >
        <Card.Body
          className="pt-3"
          style={{ overflowX: "auto", paddingRight: "10px" }}
        >
          <table className="table table-borderless indi-table mb-0">
            <tbody>
              <tr>
                <th scope="row">Average Bill Amount</th>
                <td>
                  ₹ {Number.parseFloat(
                    Math.round(revenue_analytics?.data?.data?.Average)
                  ).toLocaleString()}
                </td>
              </tr>
              <tr>
                <th scope="row">Total Revenue</th>
                <td>
                  ₹ {Number.parseFloat(
                    Math.round(revenue_analytics?.data?.data?.total_revenue)
                  ).toLocaleString()}
                </td>
              </tr>

              <tr>
                <th scope="row">Current Branch</th>
                <td>{selectedBranch?.label}</td>
              </tr>
              <tr>
                <th scope="row">Current Year </th>
                <td>{displayMonthYear}</td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default RevenueAnalytics;
