import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// change the pagination into their own
import { setCafeRequestListPagination } from "../../../store/actionCreators/Inventory/InventoryAction";

const AllCafeRequestListPagination = ({ pageNum }) => {
  const [pageCount, setPageCount] = useState(pageNum);
  const page = useSelector((state) => state.cafe_request_pagination);
  const dispatch = useDispatch();
  useEffect(() => {
    setPageCount(pageNum);
  }, [pageNum]);

  const handlePageClick = (event) => {
    dispatch(setCafeRequestListPagination(event.selected + 1)); // const newOffset = (event.selected * itemsPerPage) % items.length;
  };

  return (
    <div className="container ">
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={page - 1}
      />
    </div>
  );
};

export default AllCafeRequestListPagination;
