import React, { useEffect, useState } from "react";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBranches } from "../../../store/actionCreators/Branch/BranchAction";
import { addNewFoodItem } from "../../../store/actionCreators/Catalog/Catalog";
import Unauthorized from "../../unauthorized";
import AddAddons from "./AddAddons";
import AddNewProduct from "./AddNewProduct";
import AddProductToCategories from "./AddProductToCategories";
import OrderAddons from "./OrderAddons";
import { useNavigate } from "react-router-dom";

const AddNewFoodMain = (props) => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const branch = useSelector((state) => state.branch);
  const [selectedBranches, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [uploadedImage, setUploadedIM] = useState(null);
  const [preview, setPreview] = useState();
  const [displayAddon, setDisplayAddon] = useState("none");
  const navigate=useNavigate();
  const [Newproduct, setNewProduct] = useState({
    product_name: "",
    sku: 0,
    items_available: 0,
    description: "",
    product_type: "Kitchen",
    price: 0.0,
    image: uploadedImage,
    status: "Active",
    show_customer: "True",
    food_type: "Veg",
    prepare_time: "",
  });

  useEffect(() => {
    dispatch(getBranches());
  }, []);

  useEffect(() => {
    let array = [];
    if (branch.data) {
      array = branch.data.data.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [branch]);

  // Fucntionality for upload and preview of image
  useEffect(() => {
    if (!uploadedImage) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(uploadedImage);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [uploadedImage]);

  useEffect(() => {
    // update Newproduct.image when uploadedImage changes
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      image: uploadedImage,
    }));
  }, [uploadedImage]);

  const compressImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          if (width <= 600 && height <= 600) {
            width = 400;
            height = 400;
          } else if (width <= 800 && height <= 800) {
            width = 600;
            height = 600;
          } else if (width > maxWidth || height > maxHeight) {
            // Check if the image is already smaller than or equal to the desired dimensions
            const ratio = Math.max(width / maxWidth, height / maxHeight);
            width /= ratio;
            height /= ratio;
          }
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              console.log(`Original size: ${file.size} bytes`);
              console.log(`Compressed size: ${blob.size} bytes`);
              console.log(`Original dimensions: ${img.width} x ${img.height}`);
              console.log(`Compressed dimensions: ${width} x ${height}`);
              resolve(blob);
            },
            "image/png",
            0.6
          );
        };
        img.onerror = (err) => reject(err);
      };
    });
  };

  // const compressImage = (file, width, height) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const img = new Image();
  //       img.src = reader.result;
  //       img.onload = () => {
  //         const canvas = document.createElement('canvas');
  //         const ctx = canvas.getContext('2d');
  //         canvas.width = width;
  //         canvas.height = height;
  //         ctx.drawImage(img, 0, 0, width, height);
  //         canvas.toBlob((blob) => {
  //           // console.log(`Original size: ${file.size} bytes`);
  //           // console.log(`Compressed size: ${blob.size} bytes`);
  //           // console.log(`Original dimensions: ${img.width} x ${img.height}`);
  //           // console.log(`Compressed dimensions: ${canvas.width} x ${canvas.height}`);
  //           resolve(blob);
  //         }, 'image/jpeg', 0.6); // 0.6 is the quality level (between 0 and 1)
  //       };
  //       img.onerror = (err) => reject(err);
  //     };
  //   });
  // };

  const imageUpload = async (event) => {
    const file = event.target.files[0];
    const compressedBlob = await compressImage(file, 800, 800);
    setUploadedIM(compressedBlob);
    // upload the compressed blob to your server or database
  };
  //   const file = e.target.files[0];
  //   if (file) {
  //     setUploadedIM(file);
  //     setNewProduct({ ...Newproduct, image: file });
  //   } else {
  //     setUploadedIM(null);
  //   }
  // };

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }
  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayBranch = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };
  const handleChange = (entry) => (e) => {
    setNewProduct({ ...Newproduct, [entry]: e.target.value });
  };

  //   STEP 2 FORM STARTS
  const products = useSelector((state) => state.categories);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [optionsP, setOptionsp] = useState([]);

  useEffect(() => {
    let array = [];
    if (products.data) {
      array = products.data.data.map((item, index) => {
        return {
          label: item.category_name,
          value: item.category_list_id,
        };
      });
    }
    setOptionsp(array);
  }, [products]);

  function getDropdownButtonLabel1({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }
  function onChange1(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  const displayCategory = () => {
    if (optionsP) {
      return (
        <div
          id="catDropdown"
          className="categorySelect"
          style={{ paddingBottom: "400px" }}
        >
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...optionsP]}
            placeholderButtonLabel="Products"
            getDropdownButtonLabel={getDropdownButtonLabel1}
            value={selectedProducts}
            onChange={onChange1}
            setState={setSelectedProducts}
            onMouseEnter={() => console.log("Mouse")}
            menuIsOpen={true}
            styles={{ dropdownButton: (base) => ({ ...base, zIndex: 0 }) }}
            required
          />
        </div>
      );
    }
  };
  // Add adons functions start
  const [addon, setAddon] = useState({
    title: "",
    sku: "",
    order: null,
    price: null,
  });

  const handleChangeAddon = (value) => (event) => {
    setAddon({ ...addon, [value]: event.target.value });
  };
  const addons = useSelector((state) => state.addons);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [optionsAddons, setOptionsAddons] = useState([]);
  const [singleAddon, setSingleAddon] = useState({});

  useEffect(() => {
    let array = [];
    if (addons.data) {
      array = addons.data.data.map((item, index) => {
        return {
          label: item.title,
          itemData: item,
          value: item.add_ons_id,
          required: false,
          customLabel: item.title,
        };
      });
      array.unshift({
        label: null,
        itemData: null,
        value: "*",
        required: false,
        customLabel: "All",
      });
    }
    setOptionsAddons(array);
  }, [addons]);

  function orderAddons(addon_id, order, required) {
    const selectedAddonsClone = selectedAddons.map((ao) =>
      ao.value === addon_id ? { ...ao, order, required } : ao
    );
    setSelectedAddons(selectedAddonsClone);
  }

  function getDropdownButtonLabel2({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }
  function onChange2(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  const displayAddons = () => {
    if (optionsAddons) {
      return (
        <div
          id="catDropdown"
          className="categorySelect"
          style={{ paddingBottom: "400px" }}
        >
          <ReactMultiselectCheckboxes
            options={[...optionsAddons]}
            placeholderButtonLabel="Add Ons"
            getDropdownButtonLabel={getDropdownButtonLabel2}
            value={selectedAddons}
            onChange={onChange2}
            onMouseEnter={() => console.log("Mouse")}
            setState={setSelectedAddons}
            menuIsOpen={true}
            styles={{ dropdownButton: (base) => ({ ...base, zIndex: 0 }) }}
            formatOptionLabel={(option) =>
              option.customLabel !== "All" ? (
                <span
                  onMouseEnter={() => {
                    setSingleAddon(option.itemData);
                    setDisplayAddon("block");
                  }}
                  onMouseLeave={() => setDisplayAddon("none")}
                >
                  {option.customLabel}
                </span>
              ) : (
                <span>{option.customLabel}</span>
              )
            }
            required
          />
        </div>
      );
    }
  };
  //   First next
  const nextStep = (e) => {
    if (selectedBranches.length > 0) {
      let SelectedB = selectedBranches.map((item, index) => {
        return item.value;
      });
      setStep(step + 1);
      // dispatch(addNewCategory(catName.name,catName.description,preview,SelectedB))
    } else {
      toast.error(`please add branches`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    e.preventDefault();
  };
  //   sECOND FORM bACK
  const handleBackSecondForm = (e) => {
    setStep(1);
    e.preventDefault();
  };
  //   sECOND FORM next
  const handleNextSecondForm = (e) => {
    setStep(3);
    e.preventDefault();
  };

  const handleFinalSubmit = (e) => {
    e.preventDefault();
    if (selectedBranches.length > 0) {
      let branchString = "";
      let addonString = "";
      let order = "";
      let productString = "";
      let required = "";
      for (let i = 0; i < selectedBranches.length; i++) {
        if (selectedBranches[i].label === "All") {
          branchString = "All";
          break;
        } else {
          if (i === selectedBranches.length - 1) {
            branchString = branchString + selectedBranches[i].value;
          } else {
            branchString = branchString + selectedBranches[i].value + ",";
          }
        }
      }
      selectedAddons.sort(function (a, b) {
        return parseInt(a.order) - parseInt(b.order);
      });
      for (let i = 0; i < selectedAddons.length; i++) {
        if (selectedAddons[i].label === "All") {
          addonString = "All";
          break;
        } else {
          if (i === selectedAddons.length - 1) {
            addonString = addonString + selectedAddons[i].value;
            order = order + (i + 1).toString();
            required += selectedAddons[i].required;
          } else {
            addonString = addonString + selectedAddons[i].value + ",";
            order = order + (i + 1).toString() + ",";
            required += selectedAddons[i].required + ",";
          }
        }
      }
      for (let i = 0; i < selectedProducts.length; i++) {
        if (selectedProducts[i].label === "All") {
          productString = "All";
          break;
        } else {
          if (i === selectedProducts.length - 1) {
            productString = productString + selectedProducts[i].value;
          } else {
            productString = productString + selectedProducts[i].value + ",";
          }
        }
      }
      const object = {
        product_name: Newproduct.product_name,
        description: Newproduct.description,
        image: Newproduct.image,
        branch_id: branchString,
        items_available: Newproduct.items_available,
        ...(addonString !== "" && { add_ons_id: addonString }),
        sku: Newproduct.sku,
        prepare_time: Newproduct.prepare_time,
        product_type: Newproduct.product_type,
        food_type: Newproduct.food_type,
        price: Newproduct.price,
        status: Newproduct.status,
        show_customer: Newproduct.show_customer,
        category_id: productString,
        order,
        required,
      };
      dispatch(addNewFoodItem(object))
    } else {
      toast.error(`please add branches`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // Add adons functions end
  const main = () => {
    if (props.editPermission) {
      if (step === 1) {
        return (
          <>
            <AddNewProduct
              sideToggle={props.sideToggle}
              displayBranch={displayBranch}
              setStep={setStep}
              imageUpload={imageUpload}
              uploadedImage={uploadedImage}
              preview={preview}
              Newproduct={Newproduct}
              handleChange={handleChange}
              nextStep={nextStep}
            />
          </>
        );
      } else if (step === 2) {
        return (
          <>
            <AddAddons
              sideToggle={props.sideToggle}
              setStep={setStep}
              displayCategory={displayAddons}
              addon={addon}
              singleAddon={singleAddon}
              displayAddon={displayAddon}
              handleChangeAddon={handleChangeAddon}
              handleBackSecondForm={handleBackSecondForm}
              handleNextSecondForm={handleNextSecondForm}
            />
          </>
        );
      } else if (step === 3) {
        return (
          <OrderAddons
            selectedAddons={selectedAddons}
            orderAddons={orderAddons}
            nextStep={() => setStep(step + 1)}
            back={() => setStep(step - 1)}
          />
        );
      } else {
        return (
          <>
            <AddProductToCategories
              sideToggle={props.sideToggle}
              setStep={setStep}
              displayCategory={displayCategory}
              handleFinalSubmit={handleFinalSubmit}
            />
          </>
        );
      }
    }
    return <Unauthorized />;
  };
  return <div>{main()}</div>;
};

export default AddNewFoodMain;
