import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { getAddonProduct } from "../../../store/actionCreators/Catalog/Catalog";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteAddonProduct } from "../../../store/actionCreators/Catalog/Catalog";
import AddMoreProduct from "./AddMoreProduct";
// import AddcategoryModal from "../../Branch/IndividualFranchise/AddCategoryModal";

const ShowAddonProducts = ({ show, data, close }) => {
  //   const [showProductModal, setShowPrModal] = useState(false);
  const dispatch = useDispatch();

  const products = useSelector((state) => state.addon_products);

  const [showAddProductsModal, setShowAddProductModal] = useState(false);

  const handleClose = () => {
    setShowAddProductModal(false);
  };

  useEffect(() => {
    dispatch(getAddonProduct(data?.add_ons_id));
  }, [data]);

  const onDelete = (id) => {
    dispatch(deleteAddonProduct(id));
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Addon Products List</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column ">
            {!products?.data?.data ? (
              <div>No data available</div>
            ) : (
              !!products.data &&
              products?.data?.data.map((item, i) => {
                return (
                  <>
                    <div className="d-flex">
                      <div key={i} className="m-2">{item.product_list?.product_name}</div>;
                      <button
                        type="button"
                        onClick={() => onDelete(item?.per_product_add_ons_id)}
                        className="btn btn-danger m-0 p-0 ms-3"
                        style={{
                          width: "81px",
                          height: "30px",
                          // borderRadius: "50%",
                        }}
                      >
                        <span className="font-bold text-2xl">Delete</span>
                      </button>
                    </div>
                  </>
                );
              })
            )}
          </div>

          <button
            className="btn btn-warning mt-2 mb-2"
            onClick={() => setShowAddProductModal(true)}
          >
            Add+ more products to addon
          </button>
          <AddMoreProduct
            data={data}
            show={showAddProductsModal}
            close={handleClose}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ShowAddonProducts;
