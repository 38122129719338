import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllStoreList,
  getSingleVendor,
} from "../../../store/actionCreators/Inventory/InventoryAction";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const EditVendor = ({ sideToggle }) => {
  const vendor = useSelector((state) => state.get_single_vendor);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedStores, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const allStoreList = useSelector((state) => state.get_all_stores);

  const [data, setData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    store_id: "",
    status: "",
  });

  useEffect(() => {
    let array = [];
    if (allStoreList.data) {
      array = allStoreList.data.data.map((item, index) => {
        return { label: item.store_name, value: item.store_id };
      });
    } else array = [];
    setOptions(array);
  }, [allStoreList]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  useEffect(() => {
    dispatch(getSingleVendor(params.vendor_lists_id));
    dispatch(getAllStoreList());
  }, []);

  useEffect(() => {
    setData(vendor);

    if (vendor) {
      setSelected(
        (vendor?.vendor_stores || []).map((e) => ({
          // label: e?.store.store_name,
          value: e?.store_id,
        }))
      );
    }
  }, [vendor]);

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    console.log(this.setState);
  }

  const displayStores = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Stores"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedStores}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  const storeString = selectedStores
    .filter(({ label }) => label !== "All")
    .map(({ value }) => value)
    .join(",");

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, contact, address, store_id, status } = data;

    const dataClone = {
      name,
      email,
      contact,
      address,
      store_id,
      status,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_vendors?vendor_lists_id=${params.vendor_lists_id}`,
        { ...dataClone, store_id: storeString }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/allvendor");
        } else {
          toast.error(`${res.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  console.log(data.status);
  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {vendor ? (
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Edit Vendor Details
                    </Card.Title>
                  </div>

                  <form onSubmit={onSubmit}>
                    <Row className="mb-2">
                      <Col>
                        <label>Vendor Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.name}
                          onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Address:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={data.address}
                          onChange={(e) =>
                            setData({ ...data, address: e.target.value })
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2 ">
                          <label className="form-label">Select Stores</label>
                          <div className="d-flex">
                            {displayStores()}
                            <span className="ms-3 p-2">{data.store}</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Email:</label>
                        <input
                          required
                          type="email"
                          className="form-control"
                          value={data.email}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <label>Contact:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          value={data.contact}
                          onChange={(e) =>
                            setData({ ...data, contact: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <label>Status:</label>
                        <Select
                          value={{
                            label: String(data.status),
                            value: data.status,
                          }}
                          options={[
                            { label: "True", value: true },
                            { label: "False", value: false },
                          ]}
                          onChange={(e) =>
                            setData({ ...data, status: e.value })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      {" "}
                      <Col>
                        <Button type="submit" className="btn btn-warning">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default EditVendor;
