import React, { useState } from "react";
import { Row, Col, Card, Button, Container } from "react-bootstrap";
import Unauthorized from "../../unauthorized";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddNewUnit = ({ editPermission, sideToggle }) => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const { name } = data;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/add_items_unit`,
        { name }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully added`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/allunits");
        } else {
          toast.error(
            `${res.data.msg ? res.data.msg : "something went wrong"} `,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  if (editPermission) {
    return (
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="page-content" style={{ marginBotton: "60px" }}>
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="">
                    <Card.Title className="h4 mb-4 my-auto">
                      Add New Item's Unit
                    </Card.Title>
                    <form onSubmit={onSubmit}>
                      <Row className="mb-2">
                        <Col>
                          <label>Name:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.name}
                            onChange={(e) =>
                              setData({ ...data, name: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <Button type="submit" className="btn btn-warning">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddNewUnit;
