import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Container } from "react-bootstrap";
import Unauthorized from "../../unauthorized";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getItemsUnits } from "../../../store/actionCreators/Inventory/InventoryAction";
import { useDispatch } from "react-redux";

const AddNewItem = ({ editPermission, sideToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allItemsUnit = useSelector((state) => state.get_items_units_list);

  const [data, setData] = useState({
    name: "",
    price: 0,
    unit: "",
    danger_zone: "",
    save_zone: "",
    cgst: "",
  });

  useEffect(() => {
    dispatch(getItemsUnits());
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, price, unit, danger_zone, save_zone, cgst } = data;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/add_item_list`,
        {
          name,
          price: +price,
          unit,
          danger_zone: +danger_zone,
          save_zone: +save_zone,
          cgst: +cgst,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully added`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/allitems");
        } else {
          toast.error(
            `${res.data.msg ? res.data.msg : "something went wrong"} `,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const itemsUnitsArray = (allItemsUnit?.data?.data || []).map((item) => ({
    label: `${item.name}`,
    value: `${item.name}`,
  }));

  if (editPermission) {
    return (
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="page-content" style={{ marginBotton: "60px" }}>
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body>
                  <div className="">
                    <Card.Title className="h4 mb-4 my-auto">
                      Add New Item
                    </Card.Title>
                    <form onSubmit={onSubmit}>
                      <Row className="mb-2">
                        <Col>
                          <label>Name:</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={data.name}
                            onChange={(e) =>
                              setData({ ...data, name: e.target.value })
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Price:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            placeholder="0"
                            min={0}
                            step="any" 
                            value={data.price}
                            onChange={(e) =>
                              setData({ ...data, price: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Danger Zone:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            placeholder="0"
                            min={0}
                            step="any"
                            value={data.danger_zone}
                            onChange={(e) =>
                              setData({ ...data, danger_zone: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Safe Zone:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            placeholder="0"
                            min={0}
                            value={data.save_zone}
                            step="any"
                            onChange={(e) =>
                              setData({ ...data, save_zone: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <label>Tax:</label>
                          <input
                            required
                            type="number"
                            className="form-control"
                            placeholder="0"
                            min={0}
                            step="any"
                            value={data.cgst}
                            onChange={(e) =>
                              setData({ ...data, cgst: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <label>Item Unit:</label>
                          <Select
                            value={{ label: data.unit, value: data.unit }}
                            options={itemsUnitsArray}
                            onChange={(e) =>
                              setData({ ...data, unit: e.value })
                            }
                            placeholder="select unit"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        {" "}
                        <Col>
                          <Button type="submit" className="btn btn-warning">
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddNewItem;
