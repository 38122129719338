import React from "react";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

const OrderAddons = (props) => {
  return (
    <Container
      fluid
      className={props.sideToggle === true ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <div className="row d-flex justify-content-center">
        <div className="form-container">
          <div className="form-head">Add New Food Item</div>
          <div className="form-body">
            <form onSubmit={props.nextStep}>
              <Row>
                <Col lg={8}>
                  <div className="d-flex align-items-center">
                    <h1 className="mb-2">Selected Products</h1>
                  </div>
                  <Table bordered hover className="mt-3">
                    <thead>
                      <tr>
                        <th>ADD ON</th>
                        <th>ORDER</th>
                        <th>REQUIRED</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.selectedAddons.map((item, index) => (
                        <tr>
                          <td>{item.label}</td>
                          <td>
                            <Form.Control
                              onChange={(e) =>
                                props.orderAddons(
                                  item.value,
                                  e.target.value,
                                  item.required
                                )
                              }
                              value={item.order || ""}
                              type="number"
                              required
                              className="w-50"
                            />
                          </td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={item.required}
                              onChange={() =>
                                props.orderAddons(
                                  item.value,
                                  item.order,
                                  !item.required
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <div className="p-2">
                <Button
                  className="btn btn-primary"
                  onClick={() => props.back()}
                >
                  Back
                </Button>
                <Button
                  className="btn btn-primary"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    // console.log(props.selectedAddons);
                    props.nextStep();
                  }}
                >
                  Next
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OrderAddons;
