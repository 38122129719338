import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerVars } from "../../store/actionCreators/Customers/CustomerAction";
import { clearDashBoard } from "../../store/actionCreators/dashboard/dasboardActions";
import AddInvestors from "./AddInvestors";
import Investors from "./Investors";
import ViewInvestor from "./ViewInvestor";
import AddNewInvestorMain from "./AddNewInvestorMain";
import InvestorMailPreview from "./InvestorMailPreview";

const InvestorsDashboard = (props) => {
  const branch = useSelector((state) => state.branch);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [branchObj, setBranchObj] = useState({});
  const [branchArray, setBranchArray] = useState([]);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const convertArrayToObj = () => {
    if (branch.data && branch.data.status === "success") {
      const branches = {};
      branch.data.data.forEach((e) => {
        branches[e.branch_id] = e.branch_name;
      });
      setBranchObj(branches);
      setBranchArray(branch.data.data);
    }
  };

  useEffect(() => {
    editPermissions();
    convertArrayToObj();
  }, [branch]);

  useEffect(() => {
    dispatch(setCustomerVars());
    dispatch(clearDashBoard());
  }, [viewPermission, editPermission]);

  const editPermissions = () => {
    if (login && login.login.status === "success") {
      const { admin_permissions } = login.login.data;
      admin_permissions.forEach((item) => {
        if (item.module === "Investors") {
          if (item.read === true) setViewPermission(true);
          if (item.write === true) setEditPermission(true);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            <Investors
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/investorReports/mail"
          element={
            <InvestorMailPreview
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/investorReport/:investor_id"
          element={
            <ViewInvestor
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>

      <Routes>
        <Route
          path="/addinvestors/*"
          element={
            <AddNewInvestorMain
              sideToggle={props.sideToggle}
              viewPermission={viewPermission}
              editPermission={editPermission}
            />
          }
        />
      </Routes>
    </React.Fragment>
  );
};

export default InvestorsDashboard;
