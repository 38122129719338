import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { identifiers } from "./data";
import { useEffect } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";

const AddProductModal = ({ closeModal, showModal, fetchData }) => {
  const params = useParams();

  const [tags, setTags] = useState(identifiers[0] || "");
  const [additionalPrice, setAdditionalPrice] = useState("");
  const [productDisplayName, setProductDisplayName] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(
    products.length > 0
      ? {
          label: products[0].product_name,
          value: products[0].product_list_id,
        }
      : null
  );

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/getcategories`
        );
        const data = await response.json();
        setCategories(data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/getproductlist`
        );
        const data = await response.json();
        setProducts(data.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}api/v1/admin/zomato/add_product`;
    const data = {
      zomato_outlets_id: params.zomato_outlets_id,
      product_list_id: selectedProduct?.value, // Use the selected product ID
      category_list_id: selectedCategory?.value, // Use the selected category ID
      tags: tags,
      additional_price: parseInt(additionalPrice),
      product_display_name: productDisplayName,
    };

    try {
      const response = await axios.post(url, data);
      console.log(response, "RESPONSE");
      if (response && response?.data?.status === "Success") {
        toast.success(`${response?.data?.msg}`);
        closeModal();
        fetchData();
        // Clear form fields
        setTags("");
        setAdditionalPrice("");
        setProductDisplayName("");
      } else {
        toast.error(`${response?.data?.msg}`);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleProductChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
    setProductDisplayName(selectedOption ? selectedOption.label : "");
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          {/* <Form.Group controlId="zomatoOutletsId">
            <Form.Label>Zomato Outlets ID</Form.Label>
            <Form.Control
              type="text"
              value={zomatoOutletsId}
              onChange={(e) => setZomatoOutletsId(e.target.value)}
            />
          </Form.Group> */}
          {/* <Form.Group controlId="productListId">
            <Form.Label>Product List ID</Form.Label>
            <Form.Control
              type="text"
              value={productListId}
              onChange={(e) => setProductListId(e.target.value)}
            />
          </Form.Group> */}

          <Form.Group controlId="product">
            <Form.Label>Product</Form.Label>
            <Select
              required
              options={products.map((product) => ({
                label: product.product_name,
                value: product.product_list_id,
              }))}
              onChange={handleProductChange}
              value={selectedProduct}
            />
          </Form.Group>

          <Form.Group controlId="category">
            <Form.Label>Category</Form.Label>
            <Select
              required
              options={(categories || []).map((category) => ({
                label: category.category_name,
                value: category.category_list_id,
              }))}
              onChange={setSelectedCategory}
              value={selectedCategory}
            />
          </Form.Group>

          <Form.Group controlId="tags">
            <Form.Label>Tags</Form.Label>
            <Form.Select
              name="tags"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            >
              {/* <option value="">All</option> */}
              {identifiers.map((identifier) => (
                <option key={identifier} value={identifier}>
                  {" "}
                  {identifier}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="additionalPrice">
            <Form.Label>Additional Price</Form.Label>
            <Form.Control
              type="number"
              min={0}
              value={additionalPrice}
              onChange={(e) => setAdditionalPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="productDisplayName">
            <Form.Label>Product Display Name</Form.Label>
            <Form.Control
              type="text"
              value={productDisplayName}
              onChange={(e) => setProductDisplayName(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" className="m-2" type="submit">
            Add
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddProductModal;
