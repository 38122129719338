export const CustomerRolesReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_ALL_CUSTOMER_ROLES":
      return action.roles;
    case "UPDATE_CUSTOMER_ROLES":
      return action.customerRole;
      
    default:
      return state;
  }
};
