import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BiArrowBack } from "react-icons/bi";
import { getSingleStoreReqSender } from "../../../store/actionCreators/Inventory/InventoryAction";

const ViewStoreSenderReq = ({ sideToggle }) => {
  const senderReq = useSelector((state) => state.get_single_sender_req);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState({

  });

  const [file, setFile] = useState(null);

  const [storeExchangeItem, setStoreExchangeItem] = useState([]);
  const [storeRequestItem, setStoreRequestItem] = useState([]);

  useEffect(() => {
    dispatch(getSingleStoreReqSender(params.store_request_id));
  }, []);

  useEffect(() => {
    setData(senderReq);
    setStoreExchangeItem(senderReq?.store_exchange_items || []);
    setStoreRequestItem(senderReq?.store_request_items || []);
  }, [senderReq]);

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <button
          className="btn btn-primary mb-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          {" "}
          <BiArrowBack className="mr-2" />
          Back
        </button>
        {senderReq ? (
          <Row>
            <Col className="col-12 mb-5">
              <Card>
                <Card.Body style={{ padding: "40px" }}>
                  <div className="d-flex">
                    <Card.Title className="h4 mb-4 my-auto">
                      Store Sender Request
                    </Card.Title>
                  </div>

                  <Row className="mb-5">
                    <Card
                      style={{
                        background: "transparent",
                        width: "100%",
                        color: "#8d8d8d",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Card.Body>
                        <Row>
                          <Col>
                            {" "}
                            <strong>Sender Store Name:</strong>
                            <span className="text-capitalize">
                              {data.sender_store_name}
                            </span>
                          </Col>
                          <Col>
                            {" "}
                            <strong>Receiver Store Name:</strong>
                            <span className="text-capitalize">
                              {data.receiver_store_name}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {" "}
                            <strong>Store Request ID:</strong>
                            <span className="text-capitalize">
                              {data.store_request_id}
                            </span>
                          </Col>
                          <Col>
                            {" "}
                            <strong>Total Price :</strong>
                            &#8377;{data.total_price}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {" "}
                            <strong> Subtotal:</strong> &#8377;
                            {data.sub_total}
                          </Col>

                          <Col>
                            <strong>Discount:</strong>
                            &#8377;{data.discount}
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            {" "}
                            <strong>Tax: </strong>
                            &#8377;{data.tax}
                          </Col>

                          <Col>
                            {" "}
                            <strong>Status:</strong>
                            <div className="d-flex ">
                              <Select
                                value={{
                                  label: String(data.status),
                                  value: data.status,
                                }}
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <div className="inputWrapper">
                            <div>
                              <strong>Bill:</strong>{" "}
                              {!!file
                                ? `${file?.name} - ${file?.type}`
                                : `${data?.bill ? String(data.bill) : ""}`}
                            </div>
                            {!!data.bill && (
                              <button
                                className="btn-sm btn btn-primary"
                                onClick={() => window.open(data.bill)}
                              >
                                View
                              </button>
                            )}
                          </div>
                          <div>
                          <strong>Date:</strong>{" "}
                            {new Date(data.createdAt).toLocaleDateString()}
                          </div>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-start gap-2 mt-3">
                            {" "}
                            <strong>Receiver Store Msg: </strong>
                            <input
                              type="text"
                              className="form-control "
                              style={{ width: "400px" }}
                              name="receiver_store_msg"
                              value={data.receiver_store_msg}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  receiver_store_msg: e.target.value,
                                })
                              }
                              disabled
                            />
                          </Col>
                          <Col className="d-flex justify-content-start gap-2 mt-3">
                            {" "}
                            <strong>Sender Store Msg: </strong>
                            <input
                              type="text"
                              className="form-control "
                              style={{ width: "400px" }}
                              name="sender_store_msg"
                              value={data.sender_store_msg}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  sender_store_msg: e.target.value,
                                })
                              }
                              disabled
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>

                  <div>
                    <b className="mt-5 p-2 border border-top border-dark">
                      Store Request Items:
                    </b>
                    {storeRequestItem.length === 0 ? (
                      <strong className="mx-3">EMPTY</strong>
                    ) : (
                      storeRequestItem.map((item, i) => {
                        return (
                          <Card.Body key={i}>
                            <b className="fw-bold">
                              Order Id: {item.store_request_id}
                            </b>
                            <hr className="my-1" />
                            <div className="d-flex flex-column">
                              <table>
                                <tr></tr>
                                <th>Name</th>
                                <th>Unit</th>
                                <th>Tax</th>
                                <th>Total Price</th>

                                <th>Sender Store msg</th>
                                <th>Receiver Store msg</th>

                                <tr>
                                  <td>{item.name}</td>

                                  <td>{item.unit}</td>

                                  <td>{item.tax}</td>

                                  <td>{item.total_price}</td>

                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      name="sender_store_msg"
                                      value={item.sender_store_msg}
                                      onChange={(e) =>
                                        setStoreExchangeItem((list) => {
                                          const updatedList = [...list];
                                          updatedList[i] = {
                                            ...updatedList[i],
                                            sender_store_msg: e.target.value,
                                          };

                                          return updatedList;
                                        })
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      name="receiver_store_msg"
                                      value={item.receiver_store_msg}
                                      onChange={(e) =>
                                        setStoreExchangeItem((list) => {
                                          const updatedList = [...list];
                                          updatedList[i] = {
                                            ...updatedList[i],
                                            receiver_store_msg: e.target.value,
                                          };

                                          return updatedList;
                                        })
                                      }
                                      disabled
                                    />
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </Card.Body>
                        );
                      })
                    )}

                    <br />
                    <b className="mt-5 p-2 border border-top border-dark">
                      Store Exchange Items:
                    </b>
                    {storeExchangeItem.length === 0 ? (
                      <strong className="mx-3">EMPTY</strong>
                    ) : (
                      storeExchangeItem.map((item, i) => {
                        return (
                          <Card.Body key={i}>
                            <b className="fw-bold">
                              Order Id: {item.store_request_id}
                            </b>
                            <hr className="my-1" />
                            <div className="d-flex flex-column">
                              <table>
                                <tr></tr>
                                <th>Name</th>
                                <th>Unit</th>
                                <th>Tax</th>
                                <th>Total Price</th>

                         

                                <tr>
                                  <td>{item.name}</td>

                                  <td>{item.unit}</td>

                                  <td>{item.tax}</td>

                                  <td>{item.total_price}</td>

                              
                                </tr>
                              </table>
                            </div>
                          </Card.Body>
                        );
                      })
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <div>PLease Wait..</div>
        )}
      </Container>
    </div>
  );
};

export default ViewStoreSenderReq;
