import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import OndcProductPagination from "./OndcProductPagination";
import axios from "axios";
import { toast } from "react-toastify";
// import SingleProductOptions from "./singleProductOptions/SingleProductOptions";
import { useNavigate } from "react-router-dom";

const OndcProductTable = (props) => {
  const { products, per_page, total_items, data } = props.data;
  const [productData, setData] = useState(products);

  const navigate = useNavigate();

  useEffect(() => {
    setData(products);
  }, [products]);

  const columns = [
    {
      dataField: "ondc_catg_names",
      text: "Category",
      editable: false,
    },
    {
      dataField: "product_name",
      text: "Product Name",
      editable: true,
    },
    {
      dataField: "product_list.sku",
      text: "SKU",
      editable: false,
    },
    {
      dataField: "product_list.description",
      text: "Description",
      editable: false,
    },
    {
      dataField: "price",
      text: "Price",
    },
    // {
    //   dataField: "product_list.card_img",
    //   text: "Card Image",
    //   formatter: (cell) => (
    //     <img src={cell} alt="Card" className="card-image" />
    //   ),
    // },
    {
      dataField: "product_list.status",
      text: "Status",
      formatter: (cell) => (cell ? "Active" : "Inactive"),
      editable: false,
    },
    {
      dataField: "product_list.show_customer",
      text: "Show to Customer",
      formatter: (cell) => (cell ? "Yes" : "No"),
      editable: false,
    },
    {
      dataField: "product_list.prepare_time",
      text: "Prepare Time",
      editable: false,
    },
    {
      dataField: "product_list.product_type",
      text: "Product Type",
      editable: false,
    },
    {
      dataField: "product_list.food_type",
      text: "Food Type",
      editable: false,
    },
    {
      dataField: "product_list.no_of_order",
      text: "No. of Orders",
      editable: false,
    },
    {
      dataField: "inventory_quantity",
      text: "Inventory Quantity",
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: (cell) => new Date(cell).toLocaleString(),
      editable: false,
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
      formatter: (cell) => new Date(cell).toLocaleString(),
      editable: false,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      headerAlign: "center",
      csvExport: false,
      formatter: rankFormatter,
      editable: false,
      headerStyle: { width: "200px" },
      style: { width: "200px" },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const handleUpdate = (row) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/ondc_store/edit_product_ondc_store/${row.ondc_store_products_id}`;
    const updatedProduct = {
      product_name: row.product_name,
      price: parseInt(row.price),
      inventory_quantity: row.inventory_quantity,
    };

    axios
      .post(apiUrl, updatedProduct)
      .then((response) => {
        // API call successful, update the row data if required
        toast.success("Update successful!");
        console.log("API call successful:", response.data);
      })
      .catch((error) => {
        console.error("API call error:", error);
        toast.error("Update failed!"); // Show error toast
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    console.log({ cell, row, rowIndex, formatExtraData }, "diptanshu");

    const handleUpdateClick = () => {
      handleUpdate(row);
    };
    const handleViewOptions = (row) => {
      navigate(
        `/ondc/allOndcStores/view_ondc_store/${row.ondc_store_id}/${row.product_list_id}`,
        { state: { row } }
      );
    };

    return (
      <div
        style={{
          textAlign: "start",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Button
          className="btn btn-sm btn-warning"
          style={{ marginLeft: "0.6rem" }}
          onClick={handleUpdateClick}
        >
          Update
        </Button>
        <Button
          className="btn btn-sm btn-danger"
          style={{ marginLeft: "0.6rem" }}
          // onClick={handleDelete}
        >
          Delete
        </Button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => handleViewOptions(row)}
          style={{ marginLeft: "0.6rem" }}
        >
          Options/Variants
        </button>
      </div>
    );
  }

  return (
    <Row>
      <Col className="col-12 mb-5">
        <Card>
          <Card.Body style={{ padding: "40px" }}>
            <Card.Title className="h4 mb-2 ">Products</Card.Title>
            {(productData || []).length > 0 ? (
              <Row>
                <Col xl="12">
                  <div className="table-responsive overflow-auto">
                    <BootstrapTable
                      keyField="ondc_store_products_id"
                      data={productData}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      condensed
                      responsive
                      classes={"table align-middle table-nowrap"}
                      cellEdit={cellEditFactory({
                        mode: "click",
                        blurToSave: true,
                        autoSelectText: true,
                        // afterSaveCell: handleSaveCell,
                      })}
                      noDataIndication={() => (
                        <div className="text-center">No data found</div>
                      )}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="text-center">No data found</div>
            )}
            <Row>
              <OndcProductPagination
                setPageNumber={props.setPageNumber}
                page={props.page}
                pageNum={Math.ceil(!!data ? total_items / per_page : 1)}
              />
            </Row>
          </Card.Body>
        </Card>
      </Col>
      {/* <SingleProductOptions /> */}
    </Row>
  );
};

export default OndcProductTable;
