import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../Loader/LoaderTable";
import { getStoreList } from "../../store/actionCreators/Inventory/InventoryAction";
import axios from "axios";
import { toast } from "react-toastify";
import StoreListPagination from "./StoreListPagination";
import Unauthorized from "../unauthorized";

const AllStores = (props) => {
  const dispatch = useDispatch();
  const allStoreList = useSelector((state) => state.inventory_store_list);
  const page = useSelector((state) => state.store_list_pagination);

  console.log(props, "hoket");
  const [loading, setLoading] = useState(false);
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getStoreList(page));
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getStoreList(page));
  }, [page]);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [allStoreList]);

  const setDefaultData = () => {
    let storeList = [];
    if (allStoreList.data && allStoreList.data.data) {
      storeList = allStoreList.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
      }));
    }
    setStoreList(storeList);
  };

  const deleteFunction = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/del_store?store_id=${id}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          toast.success(`deleted successFully `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(getStoreList(1));
        } else {
          toast.error(`${response.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Link
            exact="true"
            to={`viewStore/${row.store_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
          <Link
            exact="true"
            to={`editStore/${row.store_id}`}
            onClick={(e) => (!props.editPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </Link>

          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row.store_id)}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
           <Link
            exact="true"
            to={`viewStore/${row.store_id}`}
            onClick={(e) => (!props.viewPermission ? e.preventDefault() : "")}
            className="btn btn-sm btn-warning"
          >
            View
          </Link>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "store_name",
      text: "Store Name",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    {
      dataField: "region",
      text: "Region",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },

    {
      dataField: "latitude",
      text: "Latitude",
      sort: true,
    },
    {
      dataField: "longitude",
      text: "Longitude",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Contact",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "timing",
      text: "Timing",
      sort: true,
    },
    {
      dataField: "OTP",
      text: "OTP",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allStoreList.data?.per_page,
    totalSize: allStoreList.data
      ? allStoreList.data.total_items
        ? allStoreList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const AllStoreList = [...storeList];

    return AllStoreList;
  };

  if (props.viewPermission) {
    if (!loading) {
      return (
        <React.Fragment>
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
              Inventory Stores{" "}
            </Col>
            <Col lg={4} sm={8} xs={12}></Col>
            <div className="page-content ">
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between">
                        <Card.Title className="h4 mb-4 ml-auto">
                          All Stores
                        </Card.Title>

                        <Link
                          className="btn btn-warning mb-3 "
                          style={{ marginLeft: "1rem", marginRight: "1rem" }}
                          to={"/inventory/addstore"}
                        >
                          Add New Store
                        </Link>
                      </div>

                      {
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="store_id"
                          columns={columns}
                          data={getAllRows()}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="store_id"
                              columns={columns}
                              data={getAllRows()}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="store_id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <StoreListPagination
                                      pageNum={Math.ceil(
                                        allStoreList?.data
                                          ? allStoreList?.data?.total_items /
                                              allStoreList.data?.per_page
                                          : 1
                                      )}
                                    />
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </React.Fragment>
      );
    } else return <Loader />;
  } else {
    return <Unauthorized />;
  }
};

export default AllStores;
