import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  CalendarIcon,
  ClipboardListIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { NavLink, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Unauthorized from "./../unauthorized";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import SalesByStaffReport from "./SalesByStaffReport";
// import SalesByStaffDaily from "./SalesByStaffDaily";
// import OrderListByStaff from "./OrderListByStaff";

const AllReports = (props) => {
  if (props.viewPermission)
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        {/* <Row className="m-3 d-flex justify-content-center text-lg text-capitalize ">{}REPORTS</Row> */}

        <Row className="mb-3">
          <Col>
            <h5>STAFF REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/staff/salesbystaff">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Consolidation of sales by employee
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Sales by Staff</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/staff/salesbystaffdaily">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Daily sales by employee
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Sales by Staff (Daily)</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>

          <Col>
            <NavLink to="/reports/staff/stafflogoutreport">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Session Logout reports of employees
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Staff Logout Reports</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>

          <Col>
            <NavLink to="/reports/staff/orderlistbystaff">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Orders collection listed by employee
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Order List by Staff</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5 className="">SALES REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/sales/salesbybranch">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Sales by Branch</div>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/sales/salesbybranchdaily">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Sales by Branch (Daily)</div>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>

        <Row className="mb-3 mt-3">
          <Col>
            <h5>ORDERS REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/orders/itemwisesales">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Consolidated summary per item sales
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">
                      Itemwise Sales Summary (Consolidated)
                    </div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
          {/* <Col>
            <NavLink to="/reports/orders/collectionreportbymedium">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Collection Report By Medium</div>
                </div>
              </Card>
            </NavLink>
          </Col> */}
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5>ITEM INVENTORY</h5>
          </Col>
        </Row>
        <Row>
          {/* <Col>
            <NavLink to="/reports/item/highestitemsellingsummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Highest Item selling summary</div>
                </div>
              </Card>
            </NavLink>
          </Col> */}
          {/* <Col>
            <NavLink to="/reports/item/addonitemwisesummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Addon Itemwise summary</div>
                </div>
              </Card>
            </NavLink>
          </Col> */}
          {/* <Col>
            <NavLink to="/reports/item/employeewiseitemsummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Employee wise Item summary</div>
                </div>
              </Card>
            </NavLink>
          </Col> */}
          <Col>
            <NavLink to="/reports/item/itemwiseinventoryreport">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Total quantity available per product
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Itemwise Inventory Report</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5>CUSTOMER REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/customer/customersalessummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Get total customer expenditure
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Customer Sales Summary</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/customer/customersaleorderlist">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Customer Sales Order List
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Customer Sales Order List</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/customer/customergroupchangelogs">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Get customer group change per customer
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Customer Group Change Logs</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
          <Col>
            <NavLink to="/reports/customer/customerwalletbalancesummary">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Get Wallet Balance summary of customers
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">
                      Customer Wallet Balance Summary
                    </div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5>WALLET REPORT</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/wallet/walletcredithistory">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        A summary of wallet credits based on time
                      </Tooltip>
                    }
                  >
                    <div className="my-auto">Wallet Credit History</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>

          {/* <Col>
            <NavLink to="/reports/customer/percustomeranalysis">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />
                  <div className="my-auto">Per Customer Analysis</div>
                </div>
              </Card>
            </NavLink>
          </Col> */}
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <h5>EXPORTS</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink to="/reports/exports/exportcustomers">
              <Card>
                <div className="p-3 py-4" style={{ display: "flex" }}>
                  <ClipboardListIcon
                    style={{
                      height: "40px",
                      width: "40px",
                      marginRight: "0.5rem",
                    }}
                  />

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">Customers Export</Tooltip>
                    }
                  >
                    <div className="my-auto">CUSTOMER</div>
                  </OverlayTrigger>
                </div>
              </Card>
            </NavLink>
          </Col>
        </Row>
      </Container>
    );
  else return <Unauthorized />;
};

export default AllReports;
