import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";

const ViewUnit = ({ sideToggle }) => {
  const unitData = useLocation();
  const unit = unitData?.state?.data;

  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState({
    name: "",
  });

  useEffect(() => {
    setData(unit);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const { name } = data;

    const dataClone = {
      name,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/inventory/update_items_unit?name=${params.name}`,
        dataClone
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(`successfully updated`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/inventory/allunits");
        } else {
          toast.error(`${res.data.msg} `, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <div className="page-content ">
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="d-flex">
                  <Card.Title className="h4 mb-4 my-auto">
                    View Item's Unit
                  </Card.Title>
                </div>

                <form onSubmit={onSubmit}>
                  <Row className="mb-2">
                    <Col>
                      <label>Name:</label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        value={data?.name}
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    {" "}
                    <Col>
                      <Button
                        disabled
                        type="submit"
                        className="btn btn-warning"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ViewUnit;
