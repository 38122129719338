import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Card, Button } from "react-bootstrap";
import { updatePromo } from "../../../store/actionCreators/Coupons/CouponsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSinglePromo } from "../../../store/actionCreators/Coupons/CouponsAction";

const EditPromo = () => {
  const promo = useSelector((state) => state.promo);

  console.log(promo);
  const dispatch = useDispatch();
  const params = useParams();
  console.log(params);

  const [data, setData] = useState({
    coupon_code: "",
    title: "",
    description: "",
    min_amount: "",
    extra_amount: "",
    expiry_time: null,
    start_time: null,
    status: false,
  });

  useEffect(() => {
    dispatch(getSinglePromo(params.promo_id));
  }, [params.promo_id]);

  useEffect(() => {
    setData(promo);
  }, [promo]);

  const onChangeFields = (field_name, value) => {
    const dataClone = { ...data };

    dataClone[field_name] = value;
    setData(dataClone);
  };
  const handleUpdatePromo = () => {
    const {
      coupon_code,
      title,
      description,
      min_amount,
      extra_amount,
      start_time,
      expiry_time,
      status,
      promo_code_id,
    } = data;
    const dataClone = {
      coupon_code,
      title,
      description,
      min_amount: parseInt(min_amount),
      extra_amount: parseInt(extra_amount),
      start_time,
      expiry_time,
      status: status,
      promo_code_id,
    };
    dispatch(updatePromo(dataClone));
  };

  return (
    <div className="page-content ">
      <Row>
        <Col className="col-12">
          <Card>
            <Card.Body>
              <div className="d-flex">
                <Card.Title className="h4 mb-4 my-auto">
                  Update PromoCode Data
                </Card.Title>
              </div>
              <form onSubmit={handleUpdatePromo}>
                <Row className="mb-2">
                  <Col>
                    <label>Promo Code</label>
                    <input
                      onChange={(e) =>
                        onChangeFields("coupon_code", e.target.value)
                      }
                      type="text"
                      className="form-control"
                      id="coupon_code"
                      value={data.coupon_code ? data.coupon_code : ""}
                      required
                    />
                  </Col>
                  <Col>
                    <label>Title</label>
                    <input
                      onChange={(e) => onChangeFields("title", e.target.value)}
                      type="text"
                      className="form-control"
                      id="title"
                      value={data.title ? data.title : ""}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <label>Start Time</label>
                    <input
                      type="date"
                      onChange={(e) =>
                        onChangeFields("start_time", e.target.value)
                      }
                      className="form-control"
                      id="start_time"
                      value={data.start_time}
                    />
                  </Col>
                  <Col>
                    <label>Expiry Time</label>
                    <input
                      type="date"
                      onChange={(e) =>
                        onChangeFields("expiry_time", e.target.value)
                      }
                      className="form-control"
                      id="expiry_time"
                      value={data.expiry_time}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <label>Description</label>
                    <input
                      onChange={(e) =>
                        onChangeFields("description", e.target.value)
                      }
                      type="text"
                      className="form-control"
                      id="description"
                      value={data.description ? data.description : ""}
                      required
                    />
                  </Col>
                  <Col>
                    <div className="mb-3 p-2">
                      <Col>
                        <label>Status:</label>
                        <Select
                          value={{
                            label: String(data?.status),
                            value: data?.status,
                          }}
                          options={[
                            { label: "True", value: true },
                            { label: "False", value: false },
                          ]}
                          onChange={(e) => {
                            setData({ ...data, status: e.value });
                          }}
                        />
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <label>Min Amount</label>
                    <input
                      type="number"
                      placeholder="0"
                      onChange={(e) =>
                        onChangeFields("min_amount", e.target.value)
                      }
                      className="form-control"
                      id="min_amount"
                      value={data.min_amount}
                    />
                  </Col>
                  <Col>
                    <label>Extra Amount</label>
                    <input
                      type="number"
                      placeholder="0"
                      onChange={(e) =>
                        onChangeFields("extra_amount", e.target.value)
                      }
                      className="form-control"
                      id="extra_amount"
                      value={data.extra_amount}
                    />
                  </Col>
                </Row>

                <Row className="d-flex">
                  <div className="d-flex">
                    <Button
                      className="btn btn-warning ml-auto"
                      style={{ marginLeft: "auto" }}
                      onClick={handleUpdatePromo}
                    >
                      Update Promocode
                    </Button>
                  </div>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditPromo;
