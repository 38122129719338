import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteInvestor,
  getAllInvestors,
} from "../../store/actionCreators/Investors/InvestorsAction";
import Loader from "../Loader/Loader";
import Unauthorized from "../unauthorized";
import EditInvestor from "./EditInvestor";

const Investors = (props) => {
  let navigate = useNavigate();

  // const Branches = useSelector((state) => state.getBranchInCat);

  const productData = useSelector((state) => state.allInvestors);
  const [currentCategory, setCurrentCategory] = useState({});
  const dispatch = useDispatch();

  const deleteFunction = (row) => {
    dispatch(deleteInvestor(row.investor_id));
  };
  const routeChange = (id) => {
    let path = `/investor/investorReport/${id?.investor_id}`;
    navigate(path, { state: { data: id } });
  };

  useEffect(() => {
    dispatch(getAllInvestors(1));
  }, []);

  const onClickFunction = (row) => {
    routeChange(row);
  };

  const [showModal, setShowModal] = useState(false);

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            onClick={() => {
              setCurrentCategory({ ...row });
              setShowModal(true);
            }}
            className="btn btn-sm btn-warning me-2"
          >
            Edit
          </Button>

          <Button
            onClick={() => onClickFunction(row)}
            className="btn btn-sm btn-warning"
          >
            View
          </Button>
          <Button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => deleteFunction(row)}
          >
            Delete
          </Button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "start",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            onClick={() => onClickFunction(row)}
            className="btn btn-sm btn-warning"
          >
            View
          </Button>
        </div>
      );
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
  }

  const columns = [
    {
      dataField: "name",
      text: "Investor Name",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Contact",
      sort: false,
    },
    {
      dataField: "gst_no",
      text: "GST Number",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "bank",
      text: "Bank",
      sort: true,
    },
    {
      dataField: "ifsc",
      text: "IFSC",
      sort: true,
    },
    {
      dataField: "account_no",
      text: "Account No",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "category_name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  const { SearchBar } = Search;
  const main = () => {
    if (props.viewPermission) {
      if (productData.data) {
        if (productData.data.data) {
          return (
            <Container
              fluid
              className={props.sideToggle === true ? "closeDash" : "openDash"}
              style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
            >
              <Row>
                <div className="page-content ">
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <Card.Body>
                            <Card.Title className="h4 mb-2 ">
                              Investors Details
                            </Card.Title>

                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              keyField="category_list_id"
                              columns={columns}
                              data={productData.data.data}
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="category_list_id"
                                  columns={columns}
                                  data={productData.data.data}
                                  search
                                >
                                  {(toolkitProps) => (
                                    <React.Fragment>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="position-relative">
                                              <SearchBar
                                                {...toolkitProps.searchProps}
                                              />
                                              <i className="search-box chat-search-box" />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField="category_list_id"
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              defaultSorted={defaultSorted}
                                              classes={
                                                "table align-middle table-nowrap"
                                              }
                                              headerWrapperClasses={
                                                "thead-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                              noDataIndication={() => (
                                                <div className="text-center">No data found</div>
                                              )}
                                            />
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                          <div className="d-inline">
                                            <SizePerPageDropdownStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                          <div className="text-md-right ms-auto">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                              className="table-pagination"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Row>
              <EditInvestor
                show={showModal}
                close={() => setShowModal(false)}
                data={currentCategory}
              />
            </Container>
          );
        } else return <Loader />;
      }
    }
    return <Unauthorized />;
  };
  return <React.Fragment>{main()}</React.Fragment>;
};

export default Investors;
