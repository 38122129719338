import React, { useState, useEffect } from "react";
import { Modal, Button, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { addBranchInvestor } from "../../store/actionCreators/Investors/InvestorsAction";

const AddBranchInvestor = ({ show, close, data }) => {
  const dispatch = useDispatch();
  const branch = useSelector((state) => state.branch);
  const [product, setProduct] = useState({});

  useEffect(() => {
    setProduct(data);
  }, [data]);

  const closeModal = () => {
    close();
  };
  const onChangeFields = (field_name, value) => {
    const feild = { ...product };
    feild[field_name] = value;
    setProduct(feild);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    dispatch(addBranchInvestor(product));

    close();
  };

  const branchIdDrop = () => {
    return branch?.data?.data.map((item, index) => {
      return (
        <option key={index} value={item.branch_id}>
          {item.branch_name}
        </option>
      );
    });
  };
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Investor Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmitForm}>
          <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
            <div className="mb-2">
              <label>Investor Branch </label>
              <select
                className="form-control form-select form-select-sm"
                value={product.branch_id}
                onChange={(e) => {
                  onChangeFields("branch_id", e.target.value);
                }}
              >
                <option value="">branch id's</option>
                {branchIdDrop()}
              </select>
              {/* <input
                required
                type="text"
                className="form-control"
                value={product.investors_branch_id}
                onChange={(e) => onChangeFields("branch_id", e.target.value)}
              /> */}
            </div>
            <div className="mb-2">
              <label>Percentage Cut</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.percentage_cut}
                onChange={(e) =>
                  onChangeFields("percentage_cut", e.target.value)
                }
              />
            </div>
            {/* <div className="mb-2">
              <label>Investor Id</label>
              <input
                required
                type="text"
                className="form-control"
                value={product.investor_id}
                onChange={(e) => onChangeFields("investor_id", e.target.value)}
              />
            </div> */}

            <Button className="btn btn-warning" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddBranchInvestor;
