import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
//Import Charts
import ReactApexChart from "react-apexcharts";
import { BsFillCircleFill } from "react-icons/bs";
import "../../css/miniWidgets.css";
import { Accordion} from "react-bootstrap";

const DoughnutGraph = ({ options, series, avg }) => {
  console.log(options, "options");
  // const state = Doughnutstate;

  const optionsLabels = options?.labels || [];
  const labels = () => {
    return options?.labels?.map((item, index) => {
      return (
        <Col key={index}>
          <div className="text-center mt-4">
            <p className="dougnhut-text d-flex justify-content-center align-items-center">
              <BsFillCircleFill
                style={{ color: `${options?.colors[index]}`, marginRight: '5px' }}
              />{" "}
              {item != null && item.toUpperCase()}
            </p>
            Revenue : ₹ 
            {series
              ? Number.parseFloat(
                  Math.round(series[index] * 100) / 100
                ).toLocaleString()
              : ""}
            <p>
              Average : ₹ 
              {avg
                ? Number.parseFloat(
                    Math.round(avg[index] * 100) / 100
                  ).toLocaleString()
                : ""}
            </p>
          </div>
        </Col>
      );
    });
  };
  return (
    <React.Fragment>
      {/* <div className="float-end">
          <select className="form-select form-select-sm">
              <option defaultValue>Apr</option>
              <option value="1">Mar</option>
              <option value="2">Feb</option>
              <option value="3">Jan</option>
          </select>
      </div> */}
      <h4
        className="card-title mb-4"
        style={{ fontSize: "15px", color: "#343a40" }}
      >
        Sales Analytics
      </h4>

      <div id="donut-chart" className="apex-charts">
        <ReactApexChart
          options={options ? options : []}
          series={series ? series : []}
          type="donut"
          height="250"
        />
      </div>

      <Row>
        {/* {labels()} */}
        <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header>More Details</Accordion.Header>
            <Accordion.Body>
              {labels()}
            </Accordion.Body>
          </Accordion.Item>
          
        </Accordion>
      </Row>
    </React.Fragment>
  );
};

export default DoughnutGraph;
