import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Loader/LoaderTable";
import { Badge } from "react-bootstrap";
import AddOptionsAndValueVariants from "./AddOptionsAndValueVariants";

const SingleProductOptions = ({ sideToggle }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const optionsData = useLocation();
  const rowData = optionsData?.state?.row;

  useEffect(() => {
    if (!rowData) return;

    const fetchOptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/admin/ondc_store/single_product_options/${rowData.ondc_store_id}?product_list_id=${rowData.product_list_id}`
        );
        const { options } = response.data;
        setOptions(options?.options);
        setIsLoading(false);
      } catch (error) {
        console.error("API call error:", error);
        // Handle error state or retry logic
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [rowData]);

  const formatOptionValues = (cell) => {
    return (
      <>
        {cell.map((value) => (
          <Badge key={value} pill variant="secondary" className="m-1">
            {value}
          </Badge>
        ))}
      </>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Option Name",
    },
    {
      dataField: "values",
      text: "Option Values",
      formatter: formatOptionValues,
    },
  ];

  if (isLoading) {
    return (
      <Container
        fluid
        className={sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <Loader />
      </Container>
    );
  }

  if (!rowData) {
    return null; // Add error state or return an error component
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container
      fluid
      className={sideToggle ? "closeDash" : "openDash"}
      style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
    >
      <button className="btn btn-primary my-2" onClick={goBack}>
        Back
      </button>
      <Row>
        <Col className="col-12 mb-5">
          <Card>
            <Card.Body style={{ padding: "40px" }}>
              <Card.Title className="h4 mb-2">
                Product Options for {rowData.product_name}
              </Card.Title>
              {(options|| []).length > 0 ? (
                <BootstrapTable
                  keyField="ondc_products_options_id"
                  data={options}
                  columns={columns}
                  bordered={false}
                />
              ) : (
                <div className="text-center">No options found</div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddOptionsAndValueVariants />
    </Container>
  );
};

export default SingleProductOptions;
